import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { HybridHref } from '../directives/hybridHref.component';
import { TrustHtmlPipe } from '../pipes/trust-html.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { CardPromoItemComponent } from './card-promo-item/card-promo-item.component';
import { FormTextAreaComponent } from './form-text-area/form-text-area.component';
import { FormTextInputComponent } from './form-text-input/form-text-input.component';
import { FormSearchComponent } from './form-search/form-search.component';
import { FormDropDownComponent } from './form-drop-down/form-drop-down.component';
import { FormRadioComponent } from './form-radio/form-radio.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormDatePickerComponent } from './form-date-picker/form-date-picker.component';
import { AssetPipe } from '../../../src/app/pipes/asset.pipe';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { IMaskModule } from 'angular-imask';
import { FormRangeComponent } from './form-range/form-range.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FormFileComponent } from './form-file/form-file.component';
import { CalculatorLoanMiniComponent } from './calculator-loan-mini/calculator-loan-mini.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BranchCardItemComponent } from './branch-card-item/branch-card-item.component';
import { HeroInternalComponent } from './hero-internal/hero-internal.component';
/*
  This module is imported by the generated app-components.module.ts.
  You can use this module to provide shared Angular components that are not
  JSS components, etc to the generated module.

  Don't want code generation? See ./.gitignore for instructions to turn it off.
*/
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    IMaskModule,
    NgxFileDropModule,
    LazyLoadImageModule
  ],
  exports: [
    AssetPipe,
    CommonModule,
    BrowserModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    HybridHref,
    HeroInternalComponent,
    TrustHtmlPipe,
    CardPromoItemComponent,
    FormTextAreaComponent,
    FormTextInputComponent,
    FormSearchComponent,
    FormDropDownComponent,
    FormFileComponent,
    FormRadioComponent,
    FormRangeComponent,
    FormCheckboxComponent,
    FormDatePickerComponent,
    BreadcrumbComponent,
    IMaskModule,
    NgxFileDropModule,
    CalculatorLoanMiniComponent,
    LazyLoadImageModule,
    BranchCardItemComponent

  ],
  declarations: [
    HybridHref,
    HeroInternalComponent,
    BreadcrumbComponent,
    TrustHtmlPipe,
    CardPromoItemComponent,
    FormTextAreaComponent,
    FormTextInputComponent,
    FormSearchComponent,
    FormDropDownComponent,
    FormFileComponent,
    FormRadioComponent,
    FormRangeComponent,
    FormCheckboxComponent,
    FormDatePickerComponent,
    AssetPipe,
    CalculatorLoanMiniComponent,
    BranchCardItemComponent

  ],
})
export class AppComponentsSharedModule { }
