import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { CustomDeviceDetectorService } from "./custom-device-detector.service";
import { isPlatformServer } from "@angular/common";
import { ThemeService } from "./theme.service";
import { DOCUMENT } from '@angular/common';
@Injectable()
export class ImageService {
    public isMobile: boolean = false;
    public energySave: boolean = false;
    _isPlatformServer: boolean = false;
    htmlElement: any;
    energySaveValue: any;
    constructor(
        private deviceDetector: CustomDeviceDetectorService,
        @Inject(PLATFORM_ID) public readonly platformId: string,
        @Inject(DOCUMENT) public document: Document,
        private themeService: ThemeService,
    ) {
        platformId;
    }

    public getImage(fields: any): { src: string; alt: string } {
        this._isPlatformServer = isPlatformServer(this.platformId);
        
        // if (!this._isPlatformServer) {
            this.energySave = this.themeService.getEnergySaveModeValue();
            this.isMobile = this.deviceDetector.isMobile();
        // }
        let image: { src: string; alt: string } = { src: "", alt: "" };
        if (!this.isMobile && !this.energySave) {
            image.src = fields?.Image?.value?.src;
            image.alt = fields?.Image?.value?.alt;
        } else if (this.isMobile && !this.energySave) {
            if (fields?.ImageMobile?.value?.src) {
                image.src = fields?.ImageMobile?.value?.src;
                image.alt = fields?.ImageMobile?.value?.alt;
            } else {
                image.src = fields?.Image?.value?.src;
                image.alt = fields?.Image?.value?.alt;
            }
        } else if (!this.isMobile && this.energySave) {
            if (fields?.EnergySavingImage?.value?.src) {
                image.src = fields?.EnergySavingImage?.value?.src;
                image.alt = fields?.EnergySavingImage?.value?.alt;
            }
            //  else {
            //     image.src = fields?.Image?.value?.src;
            //     image.alt = fields?.Image?.value?.alt;
            // }
        } else if (this.isMobile && this.energySave) {
            if (fields?.EnergySavingImageMobile?.value?.src) {
                image.src = fields?.EnergySavingImageMobile?.value?.src;
                image.alt = fields?.EnergySavingImageMobile?.value?.alt;
            } else if (fields?.EnergySavingImage?.value?.src) {
                image.src = fields?.EnergySavingImage?.value?.src;
                image.alt = fields?.EnergySavingImage?.value?.alt;
            } /* else if (fields?.ImageMobile?.value?.src) {
                image.src = fields?.ImageMobile?.value?.src;
                image.alt = fields?.ImageMobile?.value?.alt;
            } else {
                image.src = fields?.Image?.value?.src;
                image.alt = fields?.Image?.value?.alt;
            } */
        }
        return image;
    }
    
    public getImageFromResolver(fields: any): { src: string; alt: string } {
        this._isPlatformServer = isPlatformServer(this.platformId);
        if (!this._isPlatformServer) {
            this.isMobile = this.deviceDetector.isMobile();
            const htmlElement = this.document.querySelector("html");
            const energySaveValue = htmlElement.getAttribute("data-energy-save");
            this.energySave = energySaveValue == 'false'? false : true;
        }
        let image: { src: string; alt: string } = { src: "", alt: "" };
        if (!this.isMobile && !this.energySave) {
            image.src = fields?.Image?.Href;
            image.alt = fields?.Image?.Alt;
        } else if (this.isMobile && !this.energySave) {
            if (fields?.ImageMobile?.value?.src) {
                image.src = fields?.ImageMobile?.Href;
                image.alt = fields?.ImageMobile?.Alt;
            } else {
                image.src = fields?.Image?.Href;
                image.alt = fields?.Image?.Alt;
            }
        } else if (!this.isMobile && this.energySave) {
            if (fields?.EnergySavingImage?.Href) {
                image.src = fields?.EnergySavingImage?.Href;
                image.alt = fields?.EnergySavingImage?.Alt;
            } /* else {
                image.src = fields?.Image?.Href;
                image.alt = fields?.Image?.Alt;
            } */
        } else if (this.isMobile && this.energySave) {
            if (fields?.EnergySavingImageMobile?.Href) {
                image.src = fields?.EnergySavingImageMobile?.Href;
                image.alt = fields?.EnergySavingImageMobile?.Alt;
            } else if (fields?.EnergySavingImage?.Href) {
                image.src = fields?.EnergySavingImage?.Href;
                image.alt = fields?.EnergySavingImage?.Alt;
            } /* else if (fields?.ImageMobile?.Href) {
                image.src = fields?.ImageMobile?.Href;
                image.alt = fields?.ImageMobile?.Alt;
            } else {
                image.src = fields?.Image?.Href;
                image.alt = fields?.Image?.Alt;
            } */
        }
        return image;
    }

    public getSvg(fields: any): string {
        this._isPlatformServer = isPlatformServer(this.platformId);
        if (!this._isPlatformServer) {
            this.isMobile = this.deviceDetector.isMobile();
            const htmlElement = this.document.querySelector("html");
            const energySaveValue = htmlElement.getAttribute("data-energy-save");
            this.energySave = energySaveValue == 'false'? false :true;
        }
        let svgCode = "";
        if (!this.isMobile && !this.energySave) {
            svgCode = fields?.Image?.value?.svgCode;
        } else if (this.isMobile && !this.energySave) {
            if (fields?.ImageMobile?.value?.svgCode) {
                svgCode = fields?.ImageMobile?.value?.svgCode;
            } else {
                svgCode = fields?.Image?.value?.svgCode;
            }
        } else if (!this.isMobile && this.energySave) {
            if (fields?.EnergySavingImage?.value?.svgCode) {
                svgCode = fields?.EnergySavingImage?.value?.svgCode;
            }/*  else {
                svgCode = fields?.Image?.value?.svgCode;
            } */
        } else if (this.isMobile && this.energySave) {
            if (fields?.EnergySavingImageMobile?.value?.svgCode) {
                svgCode = fields?.EnergySavingImageMobile?.value?.svgCode;
            } else if (fields?.EnergySavingImage?.value?.svgCode) {
                svgCode = fields?.EnergySavingImage?.value?.svgCode;
            } /* else if(fields?.ImageMobile?.value?.svgCode) {
                svgCode = fields?.ImageMobile?.value?.svgCode;
            } else {
                svgCode = fields?.Image?.value?.svgCode;
            } */
        }
        return svgCode;
    }
}

