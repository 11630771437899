<div *ngIf="!hideHeroArticle && dataSector != 'holdings'" class="pb-relative pb-overflow-clip" >
  <ng-container *ngIf="imageOnly else withoutImg">
    <div class="pb-c-hero pb-c-hero-article-image pb-relative pb-flex pb-items-end pb-max-h-[564px]">
        <img *ngIf="img?.src" offset="100" src="{{img?.src}}" alt="{{img?.alt}}" class="pb-w-full pb-h-full pb-object-cover pb-object-top pb-aspect-[1/1] md:pb-aspect-[2.54/1]">
    </div>
  </ng-container>
</div>

<ng-template #withoutImg>
  <div class="pb-c-hero pb-c-hero-article-text pb-overflow-x-clip pb-relative pb-flex pb-items-end">
      <div class="pb-card-hero-article pb-text-center pb-pt-15 pb-pb-9 lg:pb-pb-12 pb-flex pb-flex-col pb-items-center pb-gap-4 pb-w-full lg:pb-gap-5 pb-container">
        <div class="pb-max-w-[846px]">
          <h1 class="pb-text-d-xl-m pb-text-color-brand pb-font-serif lg:pb-text-d-xl pb-whitespace-pre-line lg:pb-mb-4" data-title="true" *scText="rendering.fields?.Title"></h1>
          <div class="pb-text-md pb-hidden md:pb-block pb-whitespace-pre-line" *scText="rendering.fields?.Subtitle"></div>
        </div>
      </div>
  </div>
</ng-template>


<div *ngIf="dataSector == 'holdings'">

  <ng-container *ngIf="imageOnly else HoldingwithoutImg">
    <div *ngIf="img?.src" class="pb-c-hero pb-c-hero-article-image pb-relative pb-flex pb-items-end pb-max-h-[564px] before:!pb-content-none">
        <img offset="100" src="{{img?.src}}" alt="{{img?.alt}}" class="pb-w-full pb-h-full pb-object-cover pb-object-top pb-aspect-[1/1] md:pb-aspect-[2.54/1]">
    </div>
  </ng-container>

  <ng-template #HoldingwithoutImg>
    <div class="pb-c-hero pb-c-hero-article-text pb-overflow-x-clip pb-relative pb-flex pb-items-end pb-c-hero-article-text-{{theme}} before:!pb-content-none">
      <div class="pb-card-hero-article pb-text-center pb-pt-15 pb-pb-5 lg:pb-pb-12 pb-flex pb-flex-col pb-items-center pb-gap-4 pb-w-full lg:pb-gap-5 pb-container">
        <div class="pb-max-w-[846px]">
          <h1 class="pb-text-d-xl-m pb-text-color-brand pb-font-serif lg:pb-text-d-xl pb-whitespace-pre-line lg:pb-mb-4" data-title="true" *scText="rendering.fields?.Title"></h1>
          <div class="pb-text-md pb-hidden md:pb-block pb-whitespace-pre-line" *scText="rendering.fields?.Subtitle"></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>