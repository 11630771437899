<section [ngClass]="dynamicClass" class="pb-container" *ngIf="rendering?.fields?.Title">
    <div class="pb-flex pb-flex-col lg:pb-flex-row pb-gap-6">
        <div class="pb-w-full lg:pb-w-1/3" >
            <h2 class="pb-text-d-xl-m lg:pb-text-d-xl pb-text-color-brand pb-font-serif" data-title="true" *scText="rendering.fields.Title"></h2>
        </div>
        <div class="pb-w-full lg:pb-w-2/3">
            <ng-container *ngIf="simpleAccordion;else withCategory">
                <app-accordion-item [data]="item" *ngFor="let item of cards"></app-accordion-item>
            </ng-container>
            <a *ngIf="link?.href" hybridHref hreflang="{{language}}" href="{{link.href}}" target="{{link.target}}" class="pb-mt-6 pb-flex pb-underline pb-font-bold">{{link.text}}</a>
            <ng-container *ngIf="rendering?.fields?.LinkDescription?.value">
                <div class="pb-mt-3" *scText="rendering.fields.LinkDescription"></div>
            </ng-container>
        </div>
    </div>
</section>

<ng-template #withCategory>
    <div #FaqTabs class="swiper pb-swiper-banner-tabs pb-c-tabs">
        <ul class="swiper-wrapper pb-nav-tabs pb-flex pb-list-none">
            <!-- <ng-container > -->
                <li *ngFor="let category of categoryCards; let i=index; let last = last" class="swiper-slide !pb-w-fit pb-py-3 pb-cursor-pointer pb-text-lg-m lg:pb-text-lg" [ngClass]="{'!pb-mr-4':last}" (click)="slideTo(i); ">
                    <div class="pb-border-b-2 pb-pb-1 pb-border-transparent">{{category.categoryName}}</div>
                </li>
            <!-- </ng-container> -->
        </ul>
    </div>
    <div #FaqPane class="swiper pb-swiper-banner-tabs-pane lg:pb-row-span-3 lg:pb-col-span-2 pb-w-full pb-mt-6">
        <div class="swiper-wrapper">
            <ng-container *ngFor="let category of categoryCards">
                <div class="swiper-slide pb-h-fit pb-self-center">
                    <app-accordion-item [data]="item" *ngFor="let item of category.cards" (click)="swiper2.update();"></app-accordion-item>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>