import { BrowserModule, TransferState } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RoutingModule } from './routing/routing.module';
import { JssLayoutService } from './layout/jss-layout.service';
import { JssContextService } from './jss-context.service';
import { AppComponentsModule } from './components/app-components.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { JssTranslationClientLoaderService } from './i18n/jss-translation-client-loader.service';
import { JssTranslationLoaderService } from './i18n/jss-translation-loader.service';
import { GraphQLModule } from './jss-graphql.module';
import { JssDataFetcherService } from './jss-data-fetcher.service';
import { JssMetaService } from './jss-meta.service';
import { ModelService } from './services/model.service';
import { CustomDeviceDetectorService } from './services/custom-device-detector.service';
import { SSRDeviceDetectorService } from './services/ssr-device-detector.service';
import { ApiService } from './services/api.service';
import { ImageService } from './services/image.service';
import { JssStylingService } from './jss-styling.service';
import { PbJssLayoutService } from './layout/pb-jss-layout.service';
import { CommonModule } from '@angular/common';
import { AnimateService } from './services/animate.service';
import { SearchService } from './services/search.service';
import { UuidService } from './services/uuid.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { environment } from '../environments/environment';
import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';


@NgModule({
  imports: [
    // withServerTransition is needed to enable universal rendering
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    HttpClientModule,
    GraphQLModule,
    RoutingModule,
    CommonModule,
    RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: () => new JssTranslationClientLoaderService(new JssTranslationLoaderService()),
        deps: [HttpClient, TransferState]
      }
    }),
    AppComponentsModule
  ],
  providers: [
    JssContextService,
    JssDataFetcherService,
    JssLayoutService,
    PbJssLayoutService,
    JssStylingService,
    JssMetaService,
    ModelService,
    ImageService,
    ApiService,
    SearchService,
    UuidService,
    CustomDeviceDetectorService,
    SSRDeviceDetectorService,
    AnimateService,
    DynamicScriptLoaderService,
    // IMPORTANT: you must set the base href with this token, not a <base> tag in the HTML.
    // the Sitecore Experience Editor will not work correctly when a base tag is used.
    { provide: APP_BASE_HREF, useValue: '/' },
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6Le4xTEqAAAAAFfx1PhMNUhp6CEhY7v6NZhBxO5C" } //WEBTST
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LeEQDYqAAAAAAbuSpuhTjIG7m07fxecIICcyAe7" } // localhost
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey } // localhost
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
