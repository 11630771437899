import { Component, OnInit, Input, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { Subscription } from "rxjs";
import { isPlatformServer } from "@angular/common";
import { ImageService } from "../../services/image.service";
import { ThemeService } from "../../services/theme.service";
import { JssStylingService } from '../../jss-styling.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  public dataSector: string;
  public energySave: Subscription;
  dynamicClass: string;

  energySaveMode : boolean;
  public image: { src: string; alt: string };
    _isPlatformServer: boolean = false;

  constructor(private imageService: ImageService,private themeService: ThemeService,
              @Inject(PLATFORM_ID) public readonly platformId: string, 
              public jssStylingService: JssStylingService)
  {platformId;}

  ngOnInit() {
    this.dynamicClass = this.jssStylingService.getComponentClass(this.rendering);
    this._isPlatformServer = isPlatformServer(this.platformId);
    if (!this._isPlatformServer) {
      this.dataSector = document.querySelector("html").getAttribute("data-sector");
      this.energySave = this.themeService.isEnegySaveModeEnabled().subscribe(energySave => {
          this.energySaveMode = energySave;
          if ((this.rendering?.fields?.Image as any)?.value?.src) {
            this.image = this.imageService.getImage(this.rendering?.fields);
          }
          
      });
    }
  }

  ngOnDestroy(): void {
    if (!this._isPlatformServer) {
        if(this.energySave){
            this.energySave.unsubscribe();
        }
    }
}
}
