<div class="pb-c-hero pb-c-hero-landing-illustration pb-relative pb-flex pb-flex-col-reverse sm:pb-flex-col pb-h-full sm:pb-h-[700px] lg:pb-h-[840px] 4xl:pb-h-[1200px]">
  <!-- <img *ngIf="image.src" [src]="image.src" [alt]="image.alt" [ngClass]="{'!pb-block': energySave}" class="pb-w-full pb-h-full pb-aspect-[390/650] md:pb-aspect-auto pb-max-h-[700px] pb-object-cover pb-object-top md:pb-max-h-[940px]"> -->
  <img  *ngIf="!isMobile && illustration" src="{{illustration}}" alt="{{fields?.IllustrationDesktop?.value?.alt}}" [ngClass]="{'md:!pb-block': energySave}" class="pb-absolute pb-right-0 3xl:pb-right-[15%] 4xl:pb-right-[5%] pb-bottom-0 pb-self-center pb-w-1/2 pb-hidden md:pb-block lg:pb-max-h-[811px] lg:pb-max-w-[811px] 4xl:pb-max-h-[1200px]">
  <div class="pb-flex pb-h-full pb-w-full md:pb-absolute pb-top-0 pb-items-end 4xl:pb-items-center">
      <div class="pb-container pb-pb-10 sm:pb-pb-12 lg:pb-pb-16 pb-w-full pb-h-full md:pb-h-auto">
          <div class="pb-pt-15 pb-h-full pb-relative pb-flex pb-justify-between pb-flex-col md:pb-gap-5 pb-w-full sm:pb-w-3/5 md:pb-w-1/2 lg:pb-max-w-[626px]">
              <div [ngClass]="{'!pb-text-white':dataSector=='holdings'}">
                  <ng-container *ngIf="data?.fields?.HeaderTag?.value==='Heading1'; else heading2">
                    <h1 class="pb-text-d-xl-m pb-font-serif pb-line-clamp-3 lg:pb-text-d-2xl pb-whitespace-pre-wrap" [ngClass]="{'pb-text-color-brand' : dataSector != 'holdings'}" data-title="true" *scText="data?.fields?.Title"></h1>
                  </ng-container>
                  <ng-template #heading2>
                    <h2 class="pb-text-d-xl-m pb-font-serif pb-line-clamp-3 lg:pb-text-d-2xl pb-whitespace-pre-wrap" [ngClass]="{'pb-text-color-brand' : dataSector != 'holdings'}" data-title="true" *scText="data?.fields?.Title"></h2>
                  </ng-template>
                  <div *ngIf="data?.fields?.Subtitle?.value" class="pb-hidden pb-pt-4 pb-text-h-xs-m md:pb-block lg:pb-text-h-xs">{{data?.fields?.Subtitle?.value}}</div>
              </div>
              <img *ngIf="isMobile && illustration" src="{{illustration}}" alt="{{fields?.IllustrationEnergyMobile?.value?.alt}}" [ngClass]="{'!pb-block md:!pb-hidden': energySave}" class="pb-self-center md:pb-hidden pb-block">
              <div class="pb-flex sm:pb-flex-row pb-flex-col pb-gap-4 sm:pb-gap-5">
                <ng-container *ngIf="dataSector!='holdings'">
                  <a *ngIf="data?.fields?.Link?.value?.href" hybridHref hreflang="{{language}}" [href]="data?.fields?.Link?.value?.href" [attr.aria-label]="data?.fields?.Link?.value?.title" [target]="data?.fields?.Link?.value?.target" class="pb-btn pb-btn-primary pb-w-full sm:pb-w-fit">
                    {{data?.fields?.Link?.value?.text}}
                    <!-- <i class="pb-icon pb-icon-arrow-right"></i> -->
                  </a>
                  <a *ngIf="data?.fields?.SecondaryLink?.value?.href" hybridHref hreflang="{{language}}" [href]="data?.fields?.SecondaryLink?.value?.href" [attr.aria-label]="data?.fields?.SecondaryLink?.value?.title" [target]="data?.fields?.SecondaryLink?.value?.target" class="pb-btn pb-btn-secondary pb-w-full sm:pb-w-fit">
                    {{data?.fields?.SecondaryLink?.value?.text}}
                    <!-- <i class="pb-icon pb-icon-arrow-right"></i> -->
                  </a>
                </ng-container>
                <ng-container *ngIf="dataSector=='holdings'">
                  <a *ngIf="data?.fields?.Link?.value?.href" hybridHref hreflang="{{language}}" [href]="data?.fields?.Link?.value?.href" [attr.aria-label]="data?.fields?.Link?.value?.title" [target]="data?.fields?.Link?.value?.target" class="pb-btn pb-btn-primary-brand pb-w-full sm:pb-w-fit">
                    {{data?.fields?.Link?.value?.text}}
                    <!-- <i class="pb-icon pb-icon-arrow-right"></i> -->
                  </a>
                </ng-container>
              </div>
          </div>
      </div>
  </div>
</div>