import { IncomingMessage, ServerResponse } from "http";
import {
    LayoutServiceContextData,
    LayoutServiceData,
    PlaceholderData,
    RestLayoutService,
    RestLayoutServiceConfig,
} from "@sitecore-jss/sitecore-jss/layout";
import { fetchData } from "@sitecore-jss/sitecore-jss";
import {
    from as fromPromise,
    BehaviorSubject,
    Observable,
    throwError as observableThrow,
} from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";

export class LayoutServiceError {
    status: number;
    statusText: string;
    data?: { sitecore?: LayoutServiceContextData };
}

@Injectable()
export class PbJssLayoutService extends RestLayoutService {
    public $customParams: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
        []
    );
    private _customParams: any;
    constructor() {
        let serviceConfig: RestLayoutServiceConfig = {
            apiHost: environment.sitecoreApiHost,
            apiKey: environment.sitecoreApiKey,
            siteName: environment.jssAppName,
            configurationName: environment.layoutServiceConfigurationName,
            dataFetcherResolver: () => this.getDefaultFetcher(),
        };
        super(serviceConfig);

        this.$customParams.subscribe((x) => {
            this._customParams = x;
        });
    }



    public encodeCustomParams(params?: any[]): string {
        if (!params) {
            params = this._customParams;
        }

        if (typeof btoa === "undefined") {
            //In Node js does not exist btoa
            return Buffer.from(
                unescape(encodeURIComponent(JSON.stringify(params)))
            ).toString("base64");
        }
        return btoa(unescape(encodeURIComponent(JSON.stringify(params))));
    }

    public customParamsToQueryStringObj(params?: any[]): any {
        if (!params) {
            params = this._customParams;
        }
        return { sc_params: this.encodeCustomParams(params) };
    }

    public decodeCustomParams(params: string): any[] {
        try {
            params = params.replace(/ /g, "+");
            if (typeof atob === "undefined") {
                //In Node js does not exist atob
                return JSON.parse(
                    decodeURIComponent(
                        unescape(Buffer.from(params, "base64").toString())
                    )
                );
            }
            return JSON.parse(decodeURIComponent(escape(atob(params))));
        } catch (err) {
            return null;
        }
    }

    fetchLayoutData(
        itemPath: string,
        language?: string,
        req?: IncomingMessage,
        res?: ServerResponse
    ): Promise<LayoutServiceData> {
        const querystringParams = this.getFetchParams(language);
        const fetcher = this.getDefaultFetcher<PlaceholderData>(req, res);
        const fetchUrl = this.resolveLayoutServiceUrl("render");
        querystringParams["sc_params"] = this.encodeCustomParams(
            this._customParams
        );
        return fetchData(fetchUrl, fetcher, {
            item: itemPath,
            ...querystringParams,
        }).catch((error) => {
            if (error.response?.status === 404) {
                return error.response.data;
            }
            throw error;
        });
    }

    getRouteData(
        route: string,
        language: string
    ): Observable<LayoutServiceData | LayoutServiceError> {
        return fromPromise(this.fetchLayoutData(route, language)).pipe(
            map((routeData) => {
                if (!routeData.sitecore.route) {
                    // A missing route value signifies an invalid path, so simulate Not Found error
                    const error = new LayoutServiceError();
                    error.status = 404;
                    error.statusText = "Not Found";
                    error.data = routeData;
                    throw error;
                }
                return routeData;
            }),
            catchError(this.getLayoutServiceError)
        );
    }

    private getLayoutServiceError(error: {
        [key: string]: unknown;
    }): Observable<LayoutServiceError> {
        if (error instanceof LayoutServiceError) {
            return observableThrow(() => error);
        }
        const layoutServiceError = new LayoutServiceError();
        const response = error.response as {
            status: number;
            statusText: string;
            data: unknown;
        };
        if (response) {
            layoutServiceError.status = response.status;
            layoutServiceError.statusText = response.statusText;
            layoutServiceError.data = response.data;
        }

        return observableThrow(() => layoutServiceError);
    }
}
