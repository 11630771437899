<!-- UploadFile-->
<div class="center pb-upload-file" [ngClass]="{'pb-upload-file--error': showFileError || showSizeError || error}">
    <label *ngIf="labelText" class="pb-mb-2">{{ labelText }}<!-- <span *ngIf="isRequired">*</span> --></label>
    <div class="upload-table" *ngIf="fileArray.length > 0">
        <table class="table pb-mb-2">
            <tbody class="upload-name-style">
                <tr *ngFor="let item of fileArray; let i=index">
                    <td class="pb-flex pb-justify-between pb-items-center">
                        <div class="pb-flex pb-items-center">
                            <i class="pb-mr-3 pb-icon pb-text-i-32 pb-icon-attach-clippy"></i>
                            <strong>{{ item.Name }}</strong>
                        </div>
                        <i class="pb-ml-3 pb-icon pb-text-i-32 pb-icon-trashcan pb-cursor-pointer hover:pb-font-bold" (click)="removeFile(item)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" accept="{{allowedFileExtensions}}">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="pb-flex pb-flex-col pb-gap-3 pb-items-center pb-px-4">
                <i class="pb-icon pb-icon-upload pb-text-i-24"></i>
                <div>
                    <span>{{'drop-here-to-attach-or' | translate}} </span>
                    <button type="button" class="pb-link" (click)="openFileSelector()">{{'upload' | translate}}</button>
                </div>
                <div *ngIf="fileSize" class="pb-text-center">{{'acceptable-file-formats' | translate}}: 
                    <ng-container *ngFor="let ext of getFileExtensions(); let last=last;">
                        {{ ext }}<span *ngIf="!last">,</span>
                    </ng-container> 
                    ({{'max-size' | translate}} {{getFriendlyFileSize()}})</div>
            </div>
        </ng-template>
    </ngx-file-drop>
    <p *ngIf="showFileError" class="pb-text-red-10 pb-text-sm">{{'wrong-file-format' | translate}}</p>
    <p *ngIf="showSizeError" class="pb-text-red-10 pb-text-sm">{{'file-is-too-big' | translate}}</p>
    <p *ngIf="showFilesNumberError" class="pb-text-red-10 pb-text-sm">{{'max' | translate}} {{filesNumber}}</p>
    <p *ngIf="error" class="pb-text-red-10 pb-text-sm">{{helperText}}</p>

</div>