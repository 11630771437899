import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[app-form-radio]',
  templateUrl: './form-radio.component.html',
})
export class FormRadioComponent implements OnInit {
  @Input() name: string;
  @Input() id: string;
  @Input() labelTextTop: string;
  @Input() labelTextRight: string;
  @Input() helperText: string;
  @Input() value: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() error: boolean = false;
  @Output() valueChange = new EventEmitter<string>();

  
  constructor() {}

  ngOnInit() {
   
  }

  
  onChange(value: any) {
    this.valueChange.emit(value.target.value);
  }
}
