import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() data: any;
  @Output() close = new EventEmitter<void>(); 
  isClosing = false;

  closeAlert() {
    this.isClosing = true;
    setTimeout(() => {
      this.close.emit();
    }, 500);
  }
  
}
