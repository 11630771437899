<section class="pb-container pb-py-5 lg:pb-py-10" [ngClass]="dynamicClass">
  <div class="pb-flex pb-flex-col pb-items-center pb-gap-y-5 lg:pb-gap-y-10">
    <h2 class="pb-text-d-xl-m lg:pb-text-d-xl pb-font-serif pb-text-color-brand" data-title="true">{{ rendering.fields?.Title?.value }}</h2>
    <a hybridHref href="{{rendering.fields?.ButtonUrl?.value?.href}}" attr.aria-label="{{rendering?.fields?.ButtonText?.value}}" class="pb-cursor-pointer pb-btn pb-btn-primary pb-w-full md:pb-w-fit pb-flex pb-items-center">
      {{ rendering.fields?.ButtonText?.value }}
      <i class="pb-icon pb-icon-arrow-right pb-text-i-24 pb-icon-color-default pb-hidden md:pb-block"></i>
    </a>
    <div class="pb-max-w-[342px] pb-max-h-[342px]">
      <img src="{{image?.src}}" alt="{{image?.alt}}" class="!pb-inline-block">
    </div>
  </div>
</section>
