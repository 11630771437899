import { isPlatformServer } from "@angular/common";
import {
    Component,
    Input,
    AfterViewInit,
    PLATFORM_ID,
    Inject,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy, ElementRef, ViewChild 
} from "@angular/core";
import { ComponentRendering } from "@sitecore-jss/sitecore-jss-angular";
import Swiper from "swiper";
import { JssContextService } from '../../jss-context.service';
import { Subscription } from 'rxjs';
import { JssStylingService } from '../../jss-styling.service';

@Component({
    selector: "app-quick-link",
    templateUrl: "./quick-link.component.html",
    styleUrls: ["./quick-link.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinkComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() rendering: ComponentRendering;
    @ViewChild('FilterSlider', { static: false }) swiperFilter!: ElementRef;
    swiper: any;
    _isPlatformServer: boolean;
    cards: any;
    allCards: any;
    index: number = 6;
    totalLength: number;
    language:string;
    dynamicClass: string;
    public contextSubscription: Subscription;

    constructor(@Inject(PLATFORM_ID) public readonly platformId: string, public jssContext: JssContextService, public jssStylingService: JssStylingService) {
        platformId;
    }

    ngOnInit(): void {
        this._isPlatformServer = isPlatformServer(this.platformId);
        this.dynamicClass = this.jssStylingService.getComponentClass(this.rendering);
        this.contextSubscription = this.jssContext.state.subscribe((newState) => {
            this.language = newState.language
          });
          if(this.rendering){
            this.cards =
                (this.rendering.fields?.Filters as any).length > 0
                    ? (this.rendering.fields.Filters as any)[0].fields?.Cards
                    : this.rendering.fields?.Cards;
            this.allCards = this.cards;

            this.cards = this.cards.slice(0, this.index);
          }
    }

    ngOnDestroy(): void {
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
      }

    ngAfterViewInit(): void {
        if (!this._isPlatformServer) {
            this.swiper = new Swiper(this.swiperFilter?.nativeElement, {
                slidesPerView: "auto",
                freeMode: true,
                centeredSlides: false,
                spaceBetween: 24,
                grabCursor: true,
                breakpoints: {
                    1024: {
                        enabled: false,
                        spaceBetween: 0,
                    }
                },
            });
        }
    }

    filterSelected(event: any, filter: any) {
        if (!this._isPlatformServer) {
            this.allCards = filter.fields?.Cards;
            this.cards = this.allCards;
            this.cards = this.cards.slice(0, this.index);
            Array.from(event.target.parentElement.children).forEach(
                (element: HTMLElement) => {
                    element.classList.remove("pb-filter--active");
                }
            );
            event.target.classList.add("pb-filter--active");
            this.swiper.slideTo(this.swiper.slides.findIndex( (x:any) => x.classList.contains("pb-filter--active")));
        }
    }

    showMore(): void{
      let loopLength = this.cards.length + this.index;
      for(let i=this.cards.length; i< loopLength; i++){
        if(this.allCards[i]){
          this.cards.push(this.allCards[i]);
        }else {
          break;
        }
      } 
    }

    showLess(): void{
      this.cards = this.cards.slice(0,this.index);
    }
}
