<label *ngIf="labelText" [htmlFor]="id">
    {{ labelText }}
    <!-- <span>*</span> -->
</label>
<div class="pb-input-group">
    <input
        type="search"
        [id]="id"
        [name]="name"
        [disabled]="isDisabled"
        class="pb-form-input"
        [ngClass]="{ 'pb-form--large': isLarge, 'pb-disabled': isDisabled }"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        (ngModelChange)="onValueChange($event)"
    />
    <a href="#">
        <i
            [ngClass]="isDisabled ? 'pb-disabled' : ''"
            class="pb-icon pb-icon-search"
        ></i>
    </a>
</div>
