<div #HeroLandingHoldingsSwiper class="swiper pb-swiper-slider">
  <div class="swiper-wrapper">
    <div [ngClass]="slide?.fields?.PaginationColor?.value==='White' ? 'pb-pagination-inverse-color' : '' " class="swiper-slide !pb-h-auto" *ngFor="let slide of rendering.fields?.Slides">
      <ng-container *ngIf="!slide?.fields?.FullImage?.value">
        <div class="pb-bg-green-70">
          <div class="pb-container-left pb-flex pb-flex-col lg:pb-flex-row lg:pb-h-[840px]" [ngClass]="rendering.fields?.Slides?.length>1 ?'pb-h-[600px] sm:pb-h-[800px]' :'pb-h-full'">
            <div class="pb-mt-auto pb-pt-[120px] lg:pb-pt-16 pb-pb-7 lg:pb-pb-12 lg:pb-w-5/12">
              <ng-container *ngIf="slide?.fields?.HeaderTag?.value==='Heading1'; else heading2">
                <h1 class="pb-text-d-2xl-m pb-font-serif pb-line-clamp-3 lg:pb-text-d-2xl pb-whitespace-pre-wrap !pb-text-white" slide-title="true" *scText="slide?.fields?.Title"></h1>
              </ng-container>
              <ng-template #heading2>
                <h2 class="pb-text-d-2xl-m pb-font-serif pb-line-clamp-3 lg:pb-text-d-2xl pb-whitespace-pre-wrap !pb-text-white" slide-title="true" *scText="slide?.fields?.Title"></h2>
              </ng-template>
              <img class="pb-block lg:pb-hidden pb-object-contain pb-max-h-56 pb-mx-auto sm:pb-max-h-[29rem]" [src]="slide?.fields?.ImageMobile?.value?.src" [alt]="slide?.fields?.ImageMobile?.value?.alt"/>
              <p class="pb-text-lg-m lg:pb-text-lg pb-text-white pb-mt-4" [ngClass]="{'pb-hidden lg:pb-block':rendering.fields?.Slides?.length>1}" *scRichText="slide?.fields?.Content"></p>
              <a *ngIf="slide?.fields?.Link?.value?.href" hybridHref hreflang="{{language}}" [href]="slide?.fields?.Link?.value?.href" [attr.aria-label]="slide?.fields?.Link?.value?.title" [target]="slide?.fields?.Link?.value?.target"
                class="pb-btn pb-btn-primary-brand pb-w-full lg:pb-w-fit pb-mb-5 pb-mt-2 lg:pb-mt-6">
                  {{slide?.fields?.Link?.value?.text}}
                  <i class="pb-icon pb-icon-arrow-right pb-text-i-24"></i>
              </a>
            </div>
            <img class="pb-hidden lg:pb-block lg:pb-w-7/12 lg:pb-object-contain lg:pb-object-left-bottom lg:pb-grow " [src]="slide?.fields?.Image?.value?.src" [alt]="slide?.fields?.Image?.value?.alt"/>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="slide?.fields?.FullImage?.value">
        <div class="pb-c-hero pb-c-hero-big-image pb-relative pb-flex pb-flex-col-reverse lg:pb-flex-col lg:pb-h-[840px] pb-h-[600px] sm:pb-h-[800px]">
          <img *ngIf="slide?.fields?.Image?.value?.src" [src]="slide?.fields?.Image?.value?.src" [alt]="slide?.fields?.Image?.value?.alt" class="pb-hidden lg:pb-block pb-w-full pb-h-full pb-object-cover pb-object-top pb-min-h-60">
          <img *ngIf="slide?.fields?.Image?.value?.src" [src]="slide?.fields?.ImageMobile?.value?.src" [alt]="slide?.fields?.ImageMobile?.value?.alt" class="lg:pb-hidden pb-w-full pb-h-full pb-object-cover pb-object-top pb-min-h-60">
          <div class="pb-flex pb-w-full lg:pb-top-0 lg:pb-items-end lg:pb-h-full lg:pb-absolute">
            <div class="lg:pb-container pb-h-full lg:pb-flex lg:pb-flex-col lg:pb-justify-center pb-w-full">
              <div>
                <div *ngIf="slide?.fields?.Title" class="pb-relative pb-px-4 pb-pb-5 pb-flex pb-flex-col pb-gap-5 lg:pb-pl-6 lg:pb-pr-12 pb-pt-15 lg:pb-py-6 pb-w-full lg:pb-w-1/2 lg:pb-max-w-[626px] after:!pb-content-none !pb-bg-green-70">  
                    <div class="!pb-text-white">
                      <ng-container *ngIf="slide?.fields?.HeaderTag?.value==='Heading1'; else heading2">
                        <h1 class="pb-text-d-xl-m pb-font-serif pb-line-clamp-4 lg:pb-text-d-xl pb-whitespace-pre-wrap" slide-title="true" *scText="slide?.fields?.Title"></h1>
                      </ng-container>
                      <ng-template #heading2>
                        <h2 class="pb-text-d-xl-m pb-font-serif pb-line-clamp-4 lg:pb-text-d-xl pb-whitespace-pre-wrap" slide-title="true" *scText="slide?.fields?.Title"></h2>
                      </ng-template>
                      
                      <div *ngIf="slide?.fields?.Subtitle?.value" class="pb-hidden pb-pt-4 pb-text-h-xs-m md:pb-block lg:pb-text-h-xs lg:pb-line-clamp-4 " >{{slide?.fields?.Subtitle?.value}}</div>
                    </div>
                    <div class="pb-flex lg:pb-flex-row pb-flex-col pb-gap-4 lg:pb-gap-5 pb-z-10">
                      <a *ngIf="slide?.fields?.Link?.value?.href" hybridHref hreflang="{{language}}" [href]="slide?.fields?.Link?.value?.href" [attr.aria-label]="slide?.fields?.Link?.value?.title" [target]="slide?.fields?.Link?.value?.target" 
                        class="pb-btn pb-btn-primary-brand pb-w-full sm:pb-w-fit pb-mb-5">
                          {{slide?.fields?.Link?.value?.text}}
                          <i class="pb-icon pb-icon-arrow-right pb-text-i-24"></i>
                      </a>
                    </div>
                </div>
        
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="swiper-pagination pb-swiper-pagination-left !pb-bottom-[16px] lg:!pb-bottom-[48px] !pb-text-center md:!pb-text-start"></div>
</div>