<section class="pb-container" [ngClass]="dynamicClass">
  <div *ngIf="isVisible" class="pb-c-notification">
    <div class="pb-flex pb-flex-col pb-gap-y-4 pb-w-11/12 lg:pb-flex-row lg:pb-items-center lg:pb-gap-x-7">
        <div *ngIf="!rendering.fields?.Icon?.value?.name && notificationDate != '0001-01-01T00:00:00Z'" class="pb-text-h-sm-m lg:pb-text-h-sm pb-font-bold">{{notificationDate| date:'dd/MM' }}</div>
        <i *ngIf="rendering.fields?.Icon?.value?.name && notificationDate === '0001-01-01T00:00:00Z'" class="pb-icon {{rendering.fields?.Icon?.value?.name}} pb-text-i-56"></i>
        <div class="pb-flex pb-flex-col pb-gap-y-4 pb-text-md-m lg:pb-flex-row lg:pb-gap-x-3 lg:pb-grow lg:pb-justify-between lg:pb-text-md">
          <ng-container *ngIf="rendering?.fields?.Title?.value">
            <div data-title="true" *scText="rendering?.fields?.Title" ></div>
          </ng-container>  
          <ng-container *ngIf="!rendering?.fields?.Title.value && rendering?.fields?.Content?.value">
            <div data-title="true" *scRichText="rendering?.fields?.Content"></div>
          </ng-container>
            <a  [href]="rendering?.fields?.Link?.value?.href" class="pb-font-bold pb-underline" [attr.aria-label]="rendering?.fields?.Link?.value?.text">{{rendering?.fields?.Link?.value?.text}}</a>
        </div>
    </div>
    <button class="pb-flex pb-w-1/12 pb-justify-end" (click)="hideNotif()"><i class="pb-icon pb-icon-close pb-text-i-32 pb-icon-color-default"></i></button>
  </div>
</section>