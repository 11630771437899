<section [ngClass]="dynamicClass" class="pb-m-quick-link pb--animate pb-container" *ngIf="rendering">
  <div class="pb-flex pb-flex-col pb-gap-4 md:pb-gap-5">
      <h2 class="pb-text-d-xl-m lg:pb-text-d-xl pb-text-color-brand pb-font-serif" data-title="true" *scText="rendering.fields?.Title"></h2>
      <h3 *ngIf="rendering.fields?.Subtitle?.value" class="pb-text-h-sm-m lg:pb-text-h-sm pb-text-color-brand pb-mt-7 pb-mb-4">{{rendering.fields?.Subtitle?.value}}</h3>

      <ng-container *ngIf="rendering.fields?.Categories.length > 0 ; else WithFilter">

        <ng-container *ngFor="let category of rendering.fields?.Categories">
          <h3 class="pb-text-h-sm-m lg:pb-text-h-sm pb-text-color-brand pb-pb-2 lg:pb-pb-4 pb-mt-9 pb-font-bold">{{category?.fields?.Title?.value}}</h3>
          <div class="pb-grid pb-justify-items-center"  [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? 'pb-grid-cols-2 sm:pb-grid-cols-3 xl:pb-grid-cols-6 pb-gap-2 md:pb-gap-6' : 'pb-grid-cols-1 lg:pb-grid-cols-2 xl:pb-grid-cols-3 pb-gap-4 md:pb-gap-6'">
            <ng-container *ngFor="let item of category?.fields?.Cards">
                <a hybridHref hreflang="{{language}}" href="{{item.fields?.Link?.value.href}}" target="{{item.fields?.Link?.value.target}}" attr.aria-label="{{item.displayName}}" class="pb-c-quick-link pb-animateReveal" [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? '' : 'pb-c-quick-link-horizontal'">
                  <i class="pb-icon {{item.fields?.Icon?.value?.name}}" [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? 'pb-text-i-48 md:pb-text-i-64' : ' pb-text-i-32'" ></i>
                  <h3 class=" pb-font-bold pb-text-md"  [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? '' : 'pb-self-center' " *scText="item.fields.Name"></h3> 
                </a>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      
      <ng-template #WithFilter>
        <ng-container *ngIf="rendering.fields?.Filters.length > 0 ; else Cards">
          <div #FilterSlider class="pb-m-filter swiper pb-swiper-filter pb-w-full">
            <div class="swiper-wrapper !pb-w-fit lg:!pb-gap-4 lg:!pb-flex-wrap">
              <ng-container *ngFor="let filter of this.rendering.fields?.Filters; let first = first">
                <button (click)="filterSelected($event, filter)" [ngClass]=" first ? 'pb-filter--active' : ''" class="swiper-slide pb-c-filter-item" >{{filter.fields?.text?.value}}</button>
              </ng-container>
            </div>
          </div>
          <ng-container *ngTemplateOutlet="Cards"></ng-container>
        </ng-container>
      </ng-template>


      <ng-template #Cards>
        <div class="pb-grid pb-justify-items-center"  [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? 'pb-grid-cols-2 sm:pb-grid-cols-3 xl:pb-grid-cols-6 pb-gap-2 md:pb-gap-6' : 'pb-grid-cols-1 lg:pb-grid-cols-2 xl:pb-grid-cols-3 pb-gap-4 md:pb-gap-6'">
           <ng-container *ngFor="let item of cards">
            <ng-container *ngIf="item.fields?.Link?.value.href; else fileCard">
              <a hybridHref href="{{item.fields?.Link?.value.href}}" target="{{item.fields?.Link?.value.target}}" attr.aria-label="{{item.displayName}}" class="pb-animateReveal pb-w-full pb-h-full">
                <div class="pb-c-quick-link" [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? 'pb-px-3' : 'pb-c-quick-link-horizontal'">
                  <i class="pb-icon {{item.fields?.Icon?.value?.name}}" [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? 'pb-text-i-48 md:pb-text-i-64' : ' pb-text-i-32'" ></i>
                  <h3 class=" pb-font-bold pb-text-md"  [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? 'pb-text-center' : 'pb-self-center' " *scText="item.fields.Name"></h3> 
                </div>
              </a>
            </ng-container>
            <ng-template #fileCard>
              <ng-container *ngIf="item.fields?.File?.value?.src">
                <a hybridHref href="{{item.fields?.File?.value?.src}}" target="_blank" attr.aria-label="{{item.displayName}}" class="pb-animateReveal pb-w-full pb-h-full">
                  <div class="pb-c-quick-link" [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? 'pb-px-3' : 'pb-c-quick-link-horizontal'">
                    <i class="pb-icon pb-icon-attach-clippy" [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? 'pb-text-i-48 md:pb-text-i-64' : ' pb-text-i-32'" ></i>
                    <h3 class=" pb-font-bold pb-text-md"  [ngClass]="rendering.fields?.Variation?.value == 'vertical' ? 'pb-text-center' : 'pb-self-center' " *scText="item.fields.Name"></h3> 
                  </div>
                </a>
              </ng-container>
            </ng-template>
            </ng-container>
        </div>
      </ng-template>
      
      <button type="button"  attr.aria-label="Less choices" class="pb-self-center pb-text-md pb-underline" *ngIf="cards.length === allCards.length && allCards.length > index" (click)="showLess()">{{ 'show-less' | translate }}</button>
      <button type="button"  attr.aria-label="More choices" class="pb-self-center pb-text-md pb-underline" *ngIf="allCards.length > cards.length " (click)="showMore()">{{ 'show-more' | translate }}</button>
      <a *ngIf="rendering.fields?.Link?.value?.href" hybridHref hreflang="{{language}}" href="{{rendering.fields?.Link?.value?.href}}" target="{{rendering.fields?.Link?.value?.target}}" attr.aria-label="{{rendering.fields?.Link?.value?.text}}" class="pb-self-center pb-text-md pb-underline">{{rendering.fields?.Link?.value?.text}}</a>
  </div>
</section>