import { Component, OnInit, OnDestroy, Input, PLATFORM_ID, Inject } from "@angular/core";
import { ComponentRendering } from "@sitecore-jss/sitecore-jss-angular";
import { JssStylingService } from "../../jss-styling.service";
import { isPlatformServer } from "@angular/common";
import { JssContextService } from '../../jss-context.service';
import { Subscription } from 'rxjs';

@Component({
    selector: "app-breadcrumb",
    templateUrl: "./breadcrumb.component.html",
    styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
    @Input() rendering: ComponentRendering = null;
    @Input() data: BreadcrumbData;
    dynamicClass: string;
    _isPlatformServer: boolean = false;
    mailtoUrl: string = "";
    public contextSubscription: Subscription;
    language:string;
    metaTitle: string = "";
    metaDescription: string = "";
    metaUrl: string = "";

    constructor(
        public jssStylingService: JssStylingService,
        @Inject(PLATFORM_ID) public readonly platformId: string,
        public jssContext: JssContextService
    ) {
        platformId;
    }

    ngOnInit() {
        this.contextSubscription = this.jssContext.state.subscribe((newState) => {
            this.language = newState.language
            this.metaTitle = (newState?.sitecore?.route?.fields?.MetaTitle as any)?.value;
            this.metaDescription = (newState?.sitecore?.route?.fields?.MetaDescription as any)?.value;
            this.metaUrl = (newState?.sitecore?.context as any)?.itemPath;
        });
        
        this._isPlatformServer = isPlatformServer(this.platformId);
        if (
            this.rendering &&
            this.rendering.fields &&
            "breadcrumbData" in this.rendering.fields
        ) {
            this.dynamicClass = this.jssStylingService.getComponentClass(
                this.rendering
            );
            const breadcrumbData = this.rendering.fields.breadcrumbData as any; // You can replace 'any' with the correct type if known
            if (
                breadcrumbData &&
                "Items" in breadcrumbData &&
                Array.isArray(breadcrumbData.Items)
            ) {
                // Assuming breadcrumbData.Items is the array you want, and it has structure { id, href, text, uid }[]
                this.data = { items: breadcrumbData.Items };
            }
        }
        if (!this._isPlatformServer) {
          let data = encodeURIComponent(`\r\n\r\n${window.location.href}\r\n`);
          this.mailtoUrl = `?subject=${document.title}&body=${data}`;
        }
    }

    navigatorShare() {
        if (!this._isPlatformServer) {
            import("ng-navigator-share").then((navigatorShare) => {
                let ngNavigatorShareService = new navigatorShare.NgNavigatorShareService();
                if (!ngNavigatorShareService.canShare()) {
                    alert(`This service/api is not supported in your Browser`);
                    return;
                  }
                  ngNavigatorShareService.share({
                    title: this.metaTitle,
                    text: this.metaDescription,
                    url: this.metaUrl
                  })
                //   .then( (response) => {
                //   })
                //   .catch( (error) => {
                //   });                
            });     
        }
    }

    // copyLink(event: any) {
    //     if (!this._isPlatformServer) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //         var tooltip = event.currentTarget.parentElement.querySelector(
    //             ".pb-tool-tip-action"
    //         );
    //         tooltip.classList.add("pb-show-tooltip");
    //         setTimeout(function () {
    //             tooltip.classList.remove("pb-show-tooltip");
    //         }, 2000);
    //         navigator.clipboard.writeText(window.location.href);
    //     }
    // }

    ngOnDestroy(): void {
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
    }
}
export interface BreadcrumbData {
    items: {
        id: number;
        href?: string;
        text: string;
        uid: string;
    }[];
}
