import { Component, OnInit, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
//import { JssStylingService } from '../../jss-styling.service';
import { Subscription } from 'rxjs';
import { JssContextService } from '../../jss-context.service';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  //jssStylingService: JssStylingService;
  private contextSubscription: Subscription;
  dynamicClass: string;
  isEditing = false;

  constructor(
   // jssStylingService: JssStylingService,
    private jssContext: JssContextService,
  ) {
   // this.jssStylingService = jssStylingService;
    

  }

  ngOnInit() {
    // remove this after implementation is done
    //this.dynamicClass = this.jssStylingService.getComponentClass(this.rendering);
    this.contextSubscription = this.jssContext.state.subscribe((newState) => {
      this.isEditing = newState.sitecore && newState.sitecore.context.pageEditing;
    });
  }


  ngOnDestroy(): void {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }
  }
}
