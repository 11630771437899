import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { JssStylingService } from '../../jss-styling.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  @Output() deleteEvent = new EventEmitter<void>();
  notificationDate: Date = new Date();
  isVisible : boolean = true;
  dynamicClass: string;
  constructor(public jssStylingService: JssStylingService) { }

  ngOnInit() {
    // remove this after implementation is done
    this.notificationDate = (this.rendering?.fields?.Date as any)?.value;
    this.dynamicClass = this.jssStylingService.getComponentClass(this.rendering);
  }

  hideNotif(){
    this.isVisible = false;
  }
}
