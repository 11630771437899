import { Injectable} from '@angular/core';
import { environment } from '../../environments/environment';
// import { JssDataFetcherService } from '../jss-data-fetcher.service';
import { HttpResponse } from '@sitecore-jss/sitecore-jss';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { UuidService } from './uuid.service';
import { CustomDeviceDetectorService } from './custom-device-detector.service';

@Injectable()
export class SearchService{
  host: string
  subscription: Subscription;
  uuid : string;
  user_agent:string;
  device_type:string;
  
  constructor(private http: HttpClient, private uuidService : UuidService, private deviceDetector: CustomDeviceDetectorService) {
    this.uuid = this.uuidService.getDomainId();
    this.user_agent = this.deviceDetector.deviceDetectorService.userAgent;
    this.device_type = this.deviceDetector.deviceDetectorService.deviceType;
  }

  search<T>(query: unknown, language: string): Promise<HttpResponse<T>> {

    if(!environment?.searchApiKey || !environment?.searchApiHost || !environment?.searchDomainId){
      //To Do Delete this after all environment (ssr.instance.ts) files are complete.
      return null;
    };

    let result: Observable<T>;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment?.searchApiKey
    });

    const body = {
      "widget": {
          "items": [
              {
                  "rfk_id": "rfkid_7",
                  "entity": "content",
                  "sources": [environment?.searchApiSource],
                  "search": query
              }
          ]
      },
      "context": {
          "locale": {
              "country": language == "el" ? "gr" : "us",
              "language": language == "el" ? "el" : "en"
          },
          "user": {
            "uuid": this.uuid
          }
      }
    };
    result = this.http.post<any>(`${environment?.searchApiHost}${environment?.searchDomainId}` , body, { headers: headers });
    return lastValueFrom(result).then((responseData) => ({
      data: responseData as T,
      status: 200,
      statusText: 'OK'
    })).catch((error: HttpErrorResponse) => {
      if (error instanceof Error) {
        throw error;
      }

      return {
        data: error.error as T,
        status: error.status,
        statusText: error.statusText
      };
    });
  }


  clickEventPublish<T>(language: string, currentPage:string, targetPageId:string, keyphrase:string ): Promise<HttpResponse<T>> {

    if(!environment?.searchPublishEventApiHost || !environment?.searchApiHost){
      //To Do Delete this after all environment (ssr.instance.ts) files are complete.
      return null;
    };

    let result: Observable<T>;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment?.searchApiKey
    });

    const body = {
      "name": "widget",
      "action": "click",
      "action_cause": "entity",
      "uuid": this.uuid,
      "client_time_ms":  Math.trunc(performance.now()),
      "value": {
        "context": {
          "browser": {
            "user_agent": this.user_agent,
          },
          "page": {
            "locale_country" : language == "el" ? "gr" : "us",
            "locale_language" : language == "el" ? "el" : "en",
            "uri" : currentPage
          }
        },
        "request": {
           "keyword": keyphrase,
        },
        "index": 0,
        "entities": [
          {
            "id": targetPageId,
            "entity_type": "content",
            "source_id":environment?.searchApiSource
          }
        ],
        "rfk_id": "rfkid_7"
      }
    };

    result = this.http.post<any>(`${environment?.searchPublishEventApiHost}` , body, { headers: headers });

    return lastValueFrom(result).then((responseData) => ({
      data: responseData as T,
      status: 200,
      statusText: 'OK'
    })).catch((error: HttpErrorResponse) => {
      if (error instanceof Error) {
        throw error;
      }

      return {
        data: error.error as T,
        status: error.status,
        statusText: error.statusText
      };
    });
  }

  viewEventPublish(language: string, currentPage:string, targetPageId:string ) {
    if(!environment?.searchPublishEventApiHost || !environment?.searchApiHost){
      //To Do Delete this after all environment (ssr.instance.ts) files are complete.
      return;
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment?.searchApiKey
    });

    const body = {
      "name": "entity_page",
      "action": "view",
      "uuid": this.uuid,
      "client_time_ms": Math.trunc(performance.now()),
      "value": {
        "context": {
          "browser": {
            "user_agent": this.user_agent
          },
          "page": {
            "locale_country" : language == "el" ? "gr" : "us",
            "locale_language" : language == "el" ? "el" : "en",
            "uri" : currentPage
          }
        },
        "entities": [
          {
            "id": targetPageId,
            "entity_type": "content",
            "source_id":environment?.searchApiSource
          }
        ]
      }
    };
    console.log("POST")
    // this.http.post<any>(`${environment?.searchPublishEventApiHost}` , body, { headers: headers }).subscribe(
    //   response => {
    //     console.log('Request Successful', response);
    //   },
    //   error => {
    //     console.error('Request failed', error);
    //   };
    this.http.post<any>(`${environment?.searchPublishEventApiHost}`, body, { headers: headers }).subscribe({});
  }  

}
