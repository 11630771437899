<div id="pb-search-modal" class="pb-fixed pb-h-full pb-w-full pb-inset-0 pb-overflow-auto pb-z-[1200] pb-hidden">
  <div class="pb-flex pb-items-center pb-mb-4 pb-justify-end">
    <button type="button" aria-label="close-search" (click)="closeModal()" class="pb-flex pb-flex-row pb-items-center pb-pr-4 pb-pt-4 ">
      <!-- <span class="pb-text-sm-m md:pb-text-sm">{{ 'close' | translate }}</span> -->
      <i class="pb-icon pb-cursor-pointer pb-icon-check-x-dash pb-text-i-32 xl:pb-text-i-48 pb-icon-color-default hover:pb-scale-110"></i>
    </button>
  </div>

  <form class="pb-container">
    <div class="pb-input-group pb-relative">
        <input
            type="search"
            [id]="'main_search'"
            [name]="'main_search'"
            class="pb-form-input pb-form--large"
            [placeholder]="rendering?.fields?.SearchInputPlaceholder?.value"
            [(ngModel)]="keyphrase"
            (ngModelChange)="suggestion()"
            (keydown)="onKeyboardChange($event)"
            (keydown.arrowDown)="handleArrowDown($event)"
            (keydown.tab)="handleArrowDown($event)"
            (keydown.arrowUp)="handleArrowUp($event)"
            (focus)="searchIsFocused = true" (blur)="searchIsFocused = false"
        />
        <a tabindex="-1" aria-label="search-button" href="#search" (click)="$event.preventDefault(); search()">
          <i class="pb-icon pb-icon-search !pb-top-[20px] hover:pb-scale-110"></i>
        </a>
        <ul *ngIf="suggestions.length > 0 && searchIsFocused" class="pb-absolute pb-right-0 pb-left-0 pb-top-full pb-px-6 pb-py-5 pb-space-y-3 pb-shadow-xl pb-text-lg pb-search-recommendations">
          <li *ngFor="let suggestion of suggestions; let i = index " class="hover:pb-cursor-pointer" [class.pb-font-bold]="i === activeSuggestionIndex" (mousedown)="keyphrase = suggestion.text; search();">
            {{suggestion.text}}
          </li>
        </ul>
    </div>
  </form>

  <div *ngIf="searchResults.length > 0" class="pb-my-9 pb-container">
    <div class="pb-text-h-sm pb-font-bold pb-mb-6">{{'looking-for'|translate}}</div>
    <div class="pb-grid pb-grid-cols-1 sm:pb-grid-cols-2 lg:pb-grid-cols-4 pb-gap-5 pb-justify-between pb-mt-9">
      <a href="{{result?.url}}" *ngFor="let result of searchResults" class="pb-search-result-cards pb-p-5 pb-flex pb-flex-col pb-cursor-pointer hover:pb-scale-105 pb-transition-all pb-duration-200">
        <img *ngIf="result?.image_url && result?.image_url != '[object Object]'" class="pb-h-14 pb-w-full pb-object-cover pb-mb-5" src="{{result?.image_url}}" alt="{{result?.heading1}}">
        <h3 class="pb-font-bold pb-mb-1">{{result?.name}}</h3>
        <p class="pb-text-sm pb-text-color-subtle pb-line-clamp-3">{{result?.description}}</p>
        <a href="{{result?.url}}" class="pb-cursor-pointer pb-flex pb-gap-2 pb-flex-grow pb-items-end pb-mt-4" (click)="publishEvent(result?.id)"><span class="pb-underline">{{'more' | translate}}</span><i class="pb-icon pb-icon-arrow-right"></i></a>
      </a>
    </div>
    <button *ngIf="showMoreEnabled" type="button" (click)="search(8, searchResults.length)" class="pb-mx-auto pb-btn pb-btn-secondary pb-mt-6">{{'show-more'|translate}}<i class="pb-icon pb-icon-arrow-down"></i></button>
  </div>

  <div *ngIf="noResultsFound" class="pb-my-9 pb-container">
    <h3 class="pb-text-h-sm-m lg:pb-text-h-sm pb-font-bold pb-text-color-brand pb-mb-5 pb-whitespace-pre-wrap pb-text-center">{{rendering?.fields?.NoResultsFoundText?.value}}</h3>
    <img *ngIf="rendering?.fields?.NoResultsFoundIllustration?.value?.src" class="pb-h-80 pb-w-80 pb-mx-auto" src="{{rendering?.fields?.NoResultsFoundIllustration?.value?.src}}" alt="{{rendering?.fields?.NoResultsFoundIllustration?.value?.alt}}">
  </div>

  <app-faqs [rendering]="rendering?.fields?.FAQsModule[0]"></app-faqs>
  <app-quick-link [rendering]="rendering?.fields?.QuickLinkModule[0]"></app-quick-link>
</div>

