// scroll.service.ts
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AnimateService {
  private renderer: Renderer2;
  _isPlatformServer: boolean = false;

  constructor(rendererFactory: RendererFactory2, @Inject(PLATFORM_ID) public readonly platformId: string,) {
    this.renderer = rendererFactory.createRenderer(null, null);
    
  }
  
  init() {
    this._isPlatformServer = isPlatformServer(this.platformId);
    if(!this._isPlatformServer){
      this.initScrollEvent();
      this.recursiveExec(this.checkForAnimateElements, this.onAnimateElementsSuccess);
      this.observeDOMChanges();
    }
  }

  private observeDOMChanges() {
    if (!this._isPlatformServer) {
      const observer = new MutationObserver(() => {
        this.checkForAnimation();
      });
  
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }
  }
  

  checkForAnimateElements(): {success: boolean, elements: any} {
    const elements = document.querySelectorAll('.pb--animate');
    const success = elements.length > 0;

    if (success) {
      return {
        success,
        elements
      }
    }
    else {
      return { success: false, elements: []};
    }
  }

  onAnimateElementsSuccess(elements: any ) {
    elements.forEach((element: Element) => {
      if (this.isElementInViewport(element)) {
        this.renderer.addClass(element, 'pb--OnFocus');
      }
    });
  }

  recursiveExec(conditionCallback: any, successCallback: any) {

    setTimeout(() => {

      const conditionRes = conditionCallback();
      if (conditionRes.success) {
        successCallback.call(this, conditionRes.elements);
      }
      else {
        this.recursiveExec(conditionCallback, successCallback);
      }
    }, 500);
  }

  private initScrollEvent() {
    if(!this._isPlatformServer){
        fromEvent(window, 'scroll').pipe(
          throttleTime(200)
        ).subscribe(() => {
          this.checkForAnimation();
        });
    }
  }

  checkForAnimation() {
    if(!this._isPlatformServer){
        const elements = document.querySelectorAll('.pb--animate');
        elements.forEach((element: Element) => {
          if (this.isElementInViewport(element)) {
            this.renderer.addClass(element, 'pb--OnFocus');
          }
        });
    }
  }

  //Before
  private isElementInViewport(element: Element): boolean {
    if(!this._isPlatformServer){
        const rect = element.getBoundingClientRect();
        const offset = 120;
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - offset;
        return (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    }
    return null;
  }

  
  
}
