import { Injectable, OnDestroy, } from '@angular/core';
import { environment } from '../../environments/environment';
import { JssDataFetcherService } from '../jss-data-fetcher.service';
import { HttpResponse } from '@sitecore-jss/sitecore-jss';
import { Subscription } from 'rxjs';
import { JssContextService } from "../jss-context.service";

@Injectable()
export class ApiService implements OnDestroy {
  host: string
  subscription: Subscription;

  contextSubscription: Subscription;
  language: string;

  constructor(
    protected dataFetcher: JssDataFetcherService,
    public jssContext: JssContextService,
  ) {
    this.host = environment.sitecoreApiHost;
   
    this.contextSubscription = this.jssContext.state.subscribe((newState: { language: string; }) => {
      this.language = newState.language;
    });
    this.subscription = this.dataFetcher.showLoader$.subscribe(showLoader => {
      if (showLoader || showLoader === false){
        //TODO: Add Loader
        let loader = document.querySelector<HTMLElement>(".pb-loader .window");
        if (loader && showLoader) {
          loader.style.left = '0px';
          document.querySelector('body').classList.add('freeze');
        }
        else if (loader && showLoader === false) {
            loader.style.left = '';
            document.querySelector('body').classList.remove('freeze');
        }
      }
    })
  }

  fetch<T>(url: string, data: any, withLoader: boolean = false): Promise<HttpResponse<T>> {
    
    let _url = this.host + url;
    _url =  _url + "?sc_apikey=" + environment.sitecoreApiKey;
    _url =  _url + "&sc_lang=" + this.language;
    _url = _url + "&sc_site=" + environment.jssAppName;
    let result= this.dataFetcher.fetch<T>(_url, data, withLoader);
    return result;
  }

  fetchLayoutService<T>(url: string, data: any, withLoader: boolean = false): Promise<HttpResponse<T>> {
    
    let _url = this.host + url;

    let result= this.dataFetcher.fetch<T>(_url, data, withLoader);
    return result;
  }
  
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
