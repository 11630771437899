import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { LayoutServiceContextData } from "@sitecore-jss/sitecore-jss-angular";
import { Subscription } from "rxjs";
import { ApiService } from "../../services/api.service";
import { environment } from "../../../environments/environment";

@Component({
    selector: "app-not-found",
    templateUrl: "./not-found.component.html",
})
export class NotFoundComponent implements OnInit, OnDestroy {
    @Input() errorContextData: LayoutServiceContextData;
    isEditing = false;
    private contextSubscription: Subscription;
    language: string;
    public sitecoreApiHost = environment.sitecoreApiHost;
    public sitecoreApiKey = environment.sitecoreApiKey;
    public action: string;
    notfoundPagename: any = environment.notFound404;
    route: any;

    constructor(
        //private router: Router,
        protected apiService: ApiService
    ) {}

    ngOnInit(): void {
        //this.router.navigate(['/404-page']);
        this.language = this.errorContextData?.context?.language;
        this.action = `/sitecore/api/layout/render/sxa-jss?item=%2F${this.notfoundPagename}%2F&sc_lang=${this.language}&sc_apikey=${this.sitecoreApiKey}&sc_site=${environment.jssAppName}`;
        this.apiService.fetchLayoutService<any[]>(this.action, "").then((resp) => {
            if (resp.status === 200) {
                this.route = (resp.data as any)["sitecore"].route;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
    }
}
