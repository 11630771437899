<label
    *ngIf="labelText"
    [htmlFor]="id"
    [ngClass]="isDisabled ? 'pb-form-label-top--disabled' : ''"
>
    {{ labelText }} <!-- <span *ngIf="isRequired">*</span> -->
</label>
<div class="pb-input-group">
    <input
        #textInput
        type="text"
        [id]="id"
        [name]="name"
        [placeholder]="placeholder"
        [required]="isRequired && error"
        [disabled]="isDisabled"
        [value]="value"
        [maxLength]="maxLength"
        (input)="onValueChange($event.target.value)"
        (keydown)="onKeyboardChange($event)"
        [ngClass]="{
            'pb-form--large': isLarge,
            'pb-error-showcase': error
        }"
        class="pb-form-input"
    />
    <span *ngIf="iconName">
        <i
            [ngClass]="isDisabled ? 'pb-disabled' : iconName"
            class="pb-icon"
        ></i>
    </span>
    <p *ngIf="helperText" [ngClass]="{'pb-error-description': error}" [innerHtml]="helperText"></p>
</div>
