<label
    *ngIf="labelText"
    [htmlFor]="id"
    [ngClass]="isDisabled ? 'pb-form-label-top--disabled' : 'pb-form-label-top'"
>
    {{ labelText }}
    <!-- <span *ngIf="isRequired">*</span> -->
</label>
<div class="pb-input-group">
    <textarea
        [id]="id"
        [name]="name"
        [attr.maxlength]="maxLength"
        rows="{{ rows }}"
        [placeholder]="placeholder"
        [required]="isRequired && error"
        [disabled]="isDisabled"
        [ngClass]="{
            'pb-error-showcase': error
        }"
        [value]="value"
        (input)="onValueChange($event.target.value)"
        (keydown)="onKeyboardChange($event)"
        class="pb-form-textarea"
    ></textarea>
</div>
<div class="pb-form-textarea-info" [ngClass]="{'pb-text-red-10': error}">
    <p *ngIf="helperText">{{ helperText }}</p>
    <div class="pb-flex">
        (
        <h3 class="current_count pb-self-center">{{ value?.length }}</h3>
        <span class="maximum_count">/{{ maxLength }})</span>
    </div>
</div>
