import { Component, OnInit, AfterViewInit, Input, ViewEncapsulation, PLATFORM_ID, Inject, OnDestroy, Renderer2 } from "@angular/core";
import { ComponentRendering } from "@sitecore-jss/sitecore-jss-angular";
import { ILinkItemDataModel } from "../../models/link-item.datamodel";
import { ImageService } from "../../services/image.service";
import { DOCUMENT, isPlatformServer } from "@angular/common";
import { ThemeService } from "../../services/theme.service";
import Swiper from "swiper";
import { Pagination } from 'swiper/modules';
import { JssContextService } from '../../jss-context.service';
import { Subscription } from 'rxjs';

@Component({
    selector: "app-hero-internal",
    templateUrl: "./hero-internal.component.html",
    styleUrls: ["./hero-internal.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class HeroInternalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() rendering: ComponentRendering;
    @Input() data: any;
    public dataSector: string;
    public energySave: Subscription;
    energySaveMode : boolean;
    public fields: any;
    link: ILinkItemDataModel;
    theme: any;
    public image: { src: string; alt: string };
    _isPlatformServer: boolean = false;
    swiper: Swiper;
    isProduct: boolean = true;
    sliderItemsFields: [] = [];
    sliderItemsImages: any[] = [];
    public contextSubscription: Subscription;
    language:string;

    constructor(private imageService: ImageService, public jssContext: JssContextService,private themeService: ThemeService, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2, 
        @Inject(PLATFORM_ID) public readonly platformId: string,) {platformId;}

    ngOnInit() {
        this._isPlatformServer = isPlatformServer(this.platformId);
        this.contextSubscription = this.jssContext.state.subscribe((newState) => {
            this.language = newState.language
          });
        if (this.rendering) {
            this.isProduct = false;
            this.data = this.rendering?.fields;
        }
        this.fields = this.data as any;

        switch ((this.data as any)?.Color?.value) {
            case 'paper-white':
                this.theme = "default";
                break;
            case 'yellow':
                this.theme = "brand";
                break;
            case 'green':
                this.theme = "green";
                break;
            default:
                this.theme = "brand";
                break;
        }

        this.dataSector = document.querySelector("html").getAttribute("data-sector");
        this.energySave = this.themeService.isEnegySaveModeEnabled().subscribe(energySave => {
            this.energySaveMode = energySave;
            if (this.data?.Image?.value?.src) {
                this.image = this.imageService.getImage(this.fields);
            }
            if((this.data?.Items as [])?.length == 0){
                this.sliderItemsImages = [];
                this.data?.Items?.forEach((item: any) => {
                    if(item?.fields?.Image?.value?.src) {
                        this.sliderItemsImages.push(this.imageService.getImage(item.fields)) 
                    }
                });
            }
            
        });

        
       
        if(this.data?.HeroButton?.value?.href){
            let link = this.data?.HeroButton as any;
            this.link = {
                href: link?.value?.href,
                target: link?.value?.target,
                text: link?.value?.text,
            };
        }
        else if (this.data?.Link && !this.isProduct) {
            let link = this.data?.Link as any;
            this.link = {
                href: link?.value?.href,
                target: link?.value?.target,
                text: link?.value?.text,
            };
        }
        if((this.data?.Items as [])?.length == 0) {
            if (this.data?.Image?.value?.src) {
                this.image = this.imageService.getImage(this.fields);
            }
        } else {
            if (this.data?.Image?.value?.src) {
                this.image = this.imageService.getImage(this.fields);
            }
            this.sliderItemsImages = [];
            this.data?.Items?.forEach((item: any) => {
                if(item?.fields?.Image?.value?.src) {
                   this.sliderItemsImages.push(this.imageService.getImage(item.fields)) 
                }
            });
        }

        if(this.image?.src && !this.document.querySelector(`link[href="${this.image?.src}"]`)) {
            // this.illustrationDesktop= this.fields?.IllustrationDesktop?.value?.src;
            const imageLink = this.renderer.createElement('link');
            this.renderer.setAttribute(imageLink, 'rel', 'preload');
            this.renderer.setAttribute(imageLink, 'href', this.image?.src);
            this.renderer.setAttribute(imageLink, 'as', 'image');
            this.renderer.appendChild(this.document.head, imageLink);
        }

    }

    ngAfterViewInit() {
        if (!this._isPlatformServer && (this.data?.Items as [])?.length > 0){
            this.swiper = new Swiper(".pb-swiper-hero", {
                spaceBetween: 8,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                modules:[Pagination]
            });
        }

        if(this.dataSector=="personal" && !this._isPlatformServer){
            const element = document.querySelector('.pb-card-hero-campaign') as HTMLElement;
            if(element){
                var roundedHeight = Math.round(element.offsetHeight) + 1; // Round and add 1px
                roundedHeight = roundedHeight % 2 == 0 ? roundedHeight : roundedHeight+1;
                element.style.height = roundedHeight+'px';
            }
        }
    }

    ngOnDestroy(): void {
        if (!this._isPlatformServer) {
            if(this.energySave){
                this.energySave.unsubscribe();
            }
            if (this.contextSubscription) {
                this.contextSubscription.unsubscribe();
            }
            if(this.image?.src && this.document.querySelector(`link[href="${this.image?.src}"]`)) {
                this.document.querySelector(`link[href="${this.image?.src}"]`).remove();
            }
        }
    }
}
