import { Component, OnInit, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { AssetPipe } from "../../pipes/asset.pipe";


@Component({
  selector: 'app-hero-campaign-holdings',
  templateUrl: './hero-campaign-holdings.component.html',
  styleUrls: ['./hero-campaign-holdings.component.scss']
})
export class HeroCampaignHoldingsComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  public image:{src:string, alt:string};
  loadedImage: HTMLImageElement;

  constructor() { }

  ngOnInit() {
    this.image = {
      src: new AssetPipe().transform('images/default-image.png'),
      alt: (this.rendering.fields?.Image as any)?.value?.alt
    }
    
    this.loadedImage = new Image();
    this.loadedImage.src = (this.rendering.fields?.Image as any)?.value?.src;
    this.loadedImage.onload = () => {
        this.image.src = this.loadedImage.src;
    };
    
  }
}
