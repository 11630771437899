<div>
  <ng-container>
    <sc-placeholder name="jss-pb-header" [rendering]="route" ></sc-placeholder>
  </ng-container>
  <main>
    <ng-container>
      <app-visitor-identification></app-visitor-identification>
      <sc-placeholder name="jss-pb-main" [rendering]="route"></sc-placeholder>
    </ng-container>
  </main>
  <ng-container>
    <sc-placeholder name="jss-pb-footer" [rendering]="route" ></sc-placeholder>
  </ng-container>
</div>