import { Injectable, RendererFactory2, Inject } from '@angular/core';
import { DynamicScriptLoaderService } from './dynamic-script-loader.service';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EventBasedMarketingService {

    constructor(
    public rendererFactory: RendererFactory2,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  addHeaderScript(rendering: ComponentRendering, route: any): void {

        let maxymiser = (rendering.fields?.EventBasedMarketingConfiguration as any);
        if(maxymiser?.length){
            maxymiser = maxymiser[0];
            let headerScript = (maxymiser.fields?.MaxymiserHeadJscript as any)?.value;
            let applyToSite = (maxymiser.fields?.ApplyToSite as any)?.value;
            if((route.fields.EmitMaxymiserScript as any)?.value == true || applyToSite ){
                //Header Script
                const headerString = headerScript;
                const srcRegex = /src="([^"]+)"/g;
                const matches = headerString.match(srcRegex);
                const srcValuesHeader : [] = matches ? matches.map((match: string) => match.match(/src="([^"]+)"/)[1]) : [];
                if(srcValuesHeader?.length > 0){
                  srcValuesHeader.forEach((element: string) => {
                    // Check if the script already exists before adding it
                    if (!this.document.querySelector(`script[src="${element}"]`)) {
                        this.dynamicScriptLoader.loadScript(element);
                    }
                  });
                }
            }
        }
    }
    
  addRouteHeaderScript(route: any): void {
    let maxymiser = (route.fields?.EventBasedMarketingConfiguration as any);
    if(maxymiser?.length){
        maxymiser = maxymiser[0];
        let headerScript = (maxymiser.fields?.MaxymiserHeadJscript as any)?.value;
        let applyToSite = (maxymiser.fields?.ApplyToSite as any)?.value;
        if((route.fields.EmitMaxymiserScript as any)?.value == true || applyToSite ){
            //Header Script
            const headerString = headerScript;
            const srcRegex = /src="([^"]+)"/g;
            const matches = headerString.match(srcRegex);
            const srcValuesHeader : [] = matches ? matches.map((match: string) => match.match(/src="([^"]+)"/)[1]) : [];
            if(srcValuesHeader?.length > 0){
              srcValuesHeader.forEach((element: string) => {
                // Check if the script already exists before adding it
                if (!this.document.querySelector(`script[src="${element}"]`)) {
                    this.dynamicScriptLoader.loadScriptToHead(element);
                }
              });
            }
        }else{
          const headerString = headerScript;
          const srcRegex = /src="([^"]+)"/g;
          const matches = headerString.match(srcRegex);
          matches.forEach((match : any) =>{
            let scripts = this.document.querySelectorAll(`script[${match}]`);
            if(scripts?.length > 0){
              scripts.forEach((script: any) => {
                script.remove();
              });
            }
          });
          let scripts = this.document.querySelectorAll('script[type="text/javascript"][src*="oracleinfinity"]');
          if(scripts?.length > 0){
            scripts.forEach((script) => {
              script.remove();
            });
          }
        }
    }
}

  addFooterScript(rendering: ComponentRendering, route: any): void {
    let maxymiser = (rendering.fields?.EventBasedMarketingConfiguration as any);
    if(maxymiser?.length){
      maxymiser = maxymiser[0];
      //Footer Script
      let applyToSite = (maxymiser.fields?.ApplyToSite as any)?.value;
      let footerScript = (maxymiser.fields?.MaxymiserFooterJscript as any)?.value;
      let footerScriptSecond = (maxymiser.fields?.MaxymiserFooterJscriptSeconds as any)?.value;
      if((route.fields.EmitMaxymiserScript as any)?.value == true || applyToSite ){
          // Check if the script content already exists before adding it
        if (!this.scriptSrcOrContentExists(null, footerScript)) {
            this.dynamicScriptLoader.loadScriptInline(footerScript, 'footer');
        }
        if (route.fields.EmitTimeOutSeconds?.value) {
          // Check if the script content already exists before adding it
          if (!this.scriptSrcOrContentExists(null, footerScriptSecond)) {
            footerScriptSecond = footerScriptSecond + "setTimeout(function() { TriggerORAClick(" + route.fields.EmitTimeOutSeconds?.value + "); }, 10000);";
              this.dynamicScriptLoader.loadScriptInline(footerScriptSecond, 'footer');
            }
        }
      }else{
        this.scriptRemove(footerScript);
        this.scriptRemove(footerScriptSecond);
      }
    }
  }

  scriptRemove(scriptContent: any){
    if (scriptContent) {
      const scripts = this.document.querySelectorAll('script') as any;
      for (let script of scripts) {
          if (script.textContent.includes(scriptContent)) {
              script.remove();
          }
      }
  }
  }

  scriptSrcOrContentExists(scriptSrc : any, scriptContent: any) {
    // Check for external scripts by src attribute
    if (scriptSrc && this.document.querySelector(`script[src="${scriptSrc}"]`)) {
        return true;
    }
    // Check for inline scripts by content
    if (scriptContent) {
        const scripts = this.document.querySelectorAll('script') as any;
        for (let script of scripts) {
            if (script.textContent.includes(scriptContent)) {
                return true;
            }
        }
    }
    return false;
  }

}