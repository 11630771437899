import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { JssContextService } from '../../jss-context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-card-promo-item',
  templateUrl: './card-promo-item.component.html',
  styleUrls: ['./card-promo-item.component.scss']
})
export class CardPromoItemComponent implements OnInit, OnDestroy{
  @Input() rendering: ComponentRendering;
  @Input() data: any;
  @Input() theme: any;
  @Input() filter: any;
  filteredTag: any;
  public contextSubscription: Subscription;
  language:string;
  constructor(public jssContext: JssContextService) { }

  ngOnInit() {
    this.contextSubscription = this.jssContext.state.subscribe((newState) => {
      this.language = newState.language
    });
    if (this.filter != null && this.filter != undefined && this.filter != "") {
      this.filteredTag = this.data?.TagItems.filter((item:any) => item.ID == this.filter);
    }
    else{
      this.filteredTag = "all";
    }
    
  }
  ngOnDestroy(): void {
    if (this.contextSubscription) {
        this.contextSubscription.unsubscribe();
    }
  }
}
