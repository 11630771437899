import { Component, forwardRef, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: '[app-form-file]',
  templateUrl: './form-file.component.html',
  styleUrls: ['./form-file.component.scss'],
  encapsulation : ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFileComponent),
    multi: true
  }]
})
export class FormFileComponent implements ControlValueAccessor {
  // public files: NgxFileDropEntry[] = [];
  public fileArray: any = [];
  @Input() labelText: string;
  @Input() isRequired: string;
  @Input() fileSize: string;
  @Input() filesNumber: number;
  @Input() error: boolean;
  @Input() helperText: string;
  value: any;
  @Output() valueChange = new EventEmitter<any>();

  @Input() allowedFileExtensions: string[] = [];
  showSizeError : boolean;
  showFileError : boolean;
  showFilesNumberError : boolean;
  // Mapping of file extensions to MIME types
  private fileExtensionToMimeTypeMap: FileExtensionMap = {
    '.tiff': 'image/tiff',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.pdf': 'application/pdf',
    // Add more mappings as needed
  };

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public dropped(files: NgxFileDropEntry[]) {
    // this.files = [];
    // this.fileArray = [];
    this.showFileError = false;
    this.showSizeError = false;
    this.showFilesNumberError = false;
    if(this.filesNumber && files.length + this.fileArray.length > this.filesNumber){
      this.showFilesNumberError = true;
    }else{
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            if (this.isFileAllowed(file)) {
              if(file.size < +this.fileSize){
                this.fileToBase64(file).subscribe(base64 => {
                  const fileObj = {
                    Name: droppedFile.relativePath,
                    Data: base64
                  };
                  this.fileArray.push(fileObj);
      
                  // Emit value change and call onChange callback after processing each file
                  this.onChange(this.fileArray);
                  this.valueChange.emit(this.fileArray);
                });
              } else{
                this.showSizeError = true;
              }
            } else {
              this.showFileError = true;
            }
  
            
          });
        } 
      }
    }
    
  }

  public fileOver(event: any) {
    if(event){
      // console.log(event);
    }
  }

  public fileLeave(event: any) {
    if(event){
      // console.log(event);
    }
  }

  removeFile(file: any){
    this.fileArray = this.fileArray.filter((x: any) => x.Name != file.Name);
    this.onChange(this.fileArray);
    this.valueChange.emit(this.fileArray);
  }

  fileToBase64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }

  private isFileAllowed(file: File): boolean {
    // Get the file extension from the file name
    const fileExtension = this.getFileExtension(file.name);

    // Check if the file extension corresponds to any of the allowed extensions
    if (!fileExtension || !this.allowedFileExtensions.includes(fileExtension)) {
      return false;
    }

    // Get the corresponding MIME type from the map
    const allowedMimeType = this.fileExtensionToMimeTypeMap[fileExtension];

    // Check if the file's MIME type matches the allowed MIME type
    return file.type === allowedMimeType;
  }

  private getFileExtension(fileName: string): string {
    return "."+fileName.split('.').pop()?.toLowerCase() || '';
  }

  getFriendlyFileSize():string{
    var kb = +this.fileSize / 1024;
    if (kb > 1024){
      var mb = kb / 1024;
      if (mb > 1024){
        var gb = mb / 1024;
        return Math.trunc(gb)+"GB"
      }else{
        return Math.trunc(mb)+"MB"
      }
    }else{
      return Math.trunc(kb)+"KB"
    }
  }

  getFileExtensions(): string[] {
    return Object.keys(this.fileExtensionToMimeTypeMap).map(key => key.replace('.', ''));
  }
}

interface FileExtensionMap {
  [key: string]: string;
}
