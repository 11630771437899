import { Injectable, } from '@angular/core';
import { IBannerImageDataModel } from "../models/banner-image.datamodel";
import { IBannerImageListDataModel } from "../models/banner-image-list.datamodel";
import { Subscription } from "rxjs";
import { ThemeService } from "../services/theme.service";

@Injectable()
export class ModelService {
  energySaveSubscription: Subscription;
    constructor(
        public themeService: ThemeService) {

    }
    public getBannerImage(fields: any) : IBannerImageDataModel {

        var energySave = this.getTheme();

        let data = {
            title : fields?.Title?.value,
            subtitle: fields?.Subtitle?.value, 
            content: fields?.Content?.value,       
            image: {
                src: energySave ? fields?.EnergySavingImage?.value?.src : fields?.Image?.value?.src,
                alt: energySave ? fields?.EnergySavingImage?.value?.alt : fields?.Image?.value?.alt
            },
            imagemobile: {
                src: energySave ? fields?.EnergySavingImageMobile?.value?.src : fields?.ImageMobile?.value?.src,
                alt: energySave ? fields?.EnergySavingImageMobile?.value?.alt : fields?.ImageMobile?.value?.alt
            },
            link: {
                href: fields?.Link?.value?.href,
                target: '',
                text: fields?.Link?.value?.text
            } ,
            secondarylink: {
                href: fields?.SecondaryLink?.value?.href,
                target: '',
                text: fields?.SecondaryLink?.value?.text
            },
            icon: fields?.Icon?.value?.name,
            secondicon: fields?.SecondaryIcon ?.value?.name,
            backgroundColor: fields?.BackgroundColor?.value,
            mask: fields?.Mask?.value,
            position: fields?.Position?.value,
        }
        return data;
    }

    public getBannerImageList(fields: any) : IBannerImageListDataModel {

        var energySave = this.getTheme();

        let data = {
            title : fields?.Title?.value,
            subtitle: fields?.Subtitle?.value, 
            contentList: fields?.ContentList?.value,       
            imageContain: fields?.ImageContain?.value,
            image: {
                src: energySave ? fields?.EnergySavingImage?.value?.src : fields?.Image?.value?.src,
                alt: energySave ? fields?.EnergySavingImage?.value?.alt : fields?.Image?.value?.alt
            },
            imagemobile: {
                src: energySave ? fields?.EnergySavingImageMobile?.value?.src : fields?.ImageMobile?.value?.src,
                alt: energySave ? fields?.EnergySavingImageMobile?.value?.alt : fields?.ImageMobile?.value?.alt
            },
            link: {
                href: fields?.Link?.value?.href,
                target: '',
                text: fields?.Link?.value?.text
            },
            anchorTo: fields?.AnchorTo?.value,
            position: fields?.Position?.value,
            calculator: fields?.Calculator
        }
        return data;
    }

    getTheme(){
        let enegyMode;
        this.energySaveSubscription = this.themeService.isEnegySaveModeEnabled().subscribe((energySave) => {
            enegyMode = energySave;
        });

        return enegyMode;
    }
}