import { Component, Input, Output, EventEmitter, forwardRef, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMask } from "angular-imask";

@Component({
    selector: "[app-form-text-input]",
    templateUrl: "./form-text-input.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormTextInputComponent),
            multi: true,
        },
    ],
})
export class FormTextInputComponent implements ControlValueAccessor, AfterViewInit {
    @Input() name: string;
    @Input() id: string;
    @Input() labelText: string;
    @Input() placeholder: string = "";
    @Input() helperText: string = "";
    @Input() isRequired: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() isLarge: boolean = false;
    @Input() error: boolean = false;
    @Input() iconName: string;
    @Input() mask: any;
    @Input() maxLength: any = 100;
    @Output() valueChange = new EventEmitter<string>();
    @ViewChild("textInput") textInput: ElementRef<HTMLInputElement>;
    value: string = "";
    public maskedInstance: any;
    constructor() {}

    ngAfterViewInit(): void {
        if (this.mask) {
            this.maskedInstance = IMask(this.textInput.nativeElement, this.mask);
        }
    }

    // ControlValueAccessor methods
    onChange: any = () => {};
    onTouched: any = () => {};

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onValueChange(value: string) {
        // if(this.mask && this.mask?.max) {
        //   value = +value < this.mask?.max ? value : this.mask.max.toString();
        // }
        if (this.mask) {
            this.maskedInstance.masked.resolve(value);
            value = this.maskedInstance.masked.value;
            this.maskedInstance.updateValue();
        }
        this.value = value;
        this.onChange(value);
        this.valueChange.emit(value);
    }

    onKeyboardChange(event: any) {
        if (event.key === "Enter" || event.keyCode === 13) {
            event.target.blur();
        }
    }

    reset() {
        this.onValueChange("");

        if (this.mask) {
            this.maskedInstance.value = "";
            // this.maskedInstance.destroy();
            // this.maskedInstance = IMask(this.textInput.nativeElement, this.mask);
        }
        // this.value = '';

        // this.onChange(this.value);
        // this.valueChange.emit(this.value);
        // if (this.maskedInstance) {
        //   this.maskedInstance.reset(); // Clear the mask's internal value
        //   this.maskedInstance.value = this.value;
        // this.maskedInstance._onChange();
        // }
    }
}
