import { isPlatformServer } from '@angular/common';
import { Component, OnInit, Input, EventEmitter, Output, PLATFORM_ID, Inject, AfterViewInit, ElementRef, ViewChild, SimpleChanges, OnDestroy } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: '[app-form-range]',
  templateUrl: './form-range.component.html'
})
export class FormRangeComponent implements OnInit, AfterViewInit, OnDestroy {
  _isPlatformServer: boolean = false;
  @Input() labelText :string;
  @Input() valueDescription :string="";
  @Input() name :string;
  @Input() calculatorType :string;
  @Input() color :string ="";
  @Input() minValue :number ;
  @Input() maxValue :number;
  @Input() step :number;
  @Input() defaultValue :number = 0;
  @Input() isDisabled: boolean = false;
  @Input() helperText: string;
  @Input() richText: any;
  @Input() enableInputChange: boolean = false;
  @Input() language: string = "el";

  @Output() valueChange = new EventEmitter<string>();
  @ViewChild('rangeInput') rangeInput: ElementRef<HTMLInputElement>;
  value: number = 0;
  enableInput:boolean = false;
  public energySave: Subscription;
  energySaveMode : boolean;

  constructor(@Inject(PLATFORM_ID) public readonly platformId: string, private themeService: ThemeService) { }
  
  ngOnInit() {
    this._isPlatformServer = isPlatformServer(this.platformId);
    this.step = this.step ? this.step : this.minValue;
    this.value = this.defaultValue>0 ? this.defaultValue: this.minValue;
    var _this = this;
    this.energySave = this.themeService.isEnegySaveModeEnabled().subscribe(energySave => {
      this.energySaveMode = energySave;
      if(this.rangeInput){
        _this.adjustRange();
      }
    });
  }

  onValueChange(value: any) {
    value = +value;
    this.adjustRange();
    this.value = value;
    return this.valueChange.emit(value);
  }

  ngAfterViewInit(): void {
    this.adjustRange();
  }

  adjustRange() {
    if (!this._isPlatformServer) {
      const el = this.rangeInput.nativeElement;
      const normalizedValue = ((this.value - this.minValue) / (this.maxValue - this.minValue)) * 100;
      if(this.energySaveMode){
        el.style.background = `linear-gradient(to right, #557475 0%, #557475 ${normalizedValue}%, #fff ${normalizedValue}%, white 100%)`;
      }else{
        if (el.classList.contains("pb-form-range-brand")) {
          el.style.background = `linear-gradient(to right, #002F30 0%, #002F30 ${normalizedValue}%, #fff ${normalizedValue}%, white 100%)`;
        }else if(el.classList.contains("pb-form-range-esg")){
          el.style.background = `linear-gradient(to right, #DDD94B 0%, #DDD94B ${normalizedValue}%, #fff ${normalizedValue}%, white 100%)`;
        } else {
          el.style.background = `linear-gradient(to right, #FFD900 0%, #FFD900 ${normalizedValue}%, #fff ${normalizedValue}%, white 100%)`;
        }
      }
    }
  }
  updateValue(){
    this.value = this.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValue){
      this.value=this.defaultValue;
      if(this.rangeInput)this.adjustRange();
    }
  }

  ngOnDestroy(): void {
    if (!this._isPlatformServer && this.energySave) {
        this.energySave?.unsubscribe();
    }
  }


}
