<section class="pb-container pb-space-y-13">
    <div class="pb-space-y-8" *ngFor="let section of rendering.fields?.result?.Sections">
        <a hybridHref [href]="section?.Link" [hreflang]="section?.Locale" class="pb-text-d-xl-m lg:pb-text-d-xl pb-text-color-brand pb-font-serif">{{ section?.Title }}</a>

        <div class="pb-grid pb-grid-cols-1 lg:pb-grid-cols-2 pb-gap-8">
            <div *ngFor="let item of section?.Items" class="pb-space-y-6">
                <a hybridHref [href]="item?.Link" [hreflang]="item?.Locale" class="pb-text-h-md-m lg:pb-text-h-md pb-font-bold">{{ item?.Title }}</a>
                <div *ngFor="let item2 of item?.Items" class="pb-spacep-y-4">
                  <a hybridHref [href]="item2?.Link" [hreflang]="item2?.Locale" class="pb-text-lg-m lg:pb-text-lg pb-font-bold">{{ item2?.Title }}</a>
                  <div class="pb-flex pb-flex-col pb-gap-4">
                    <div *ngFor="let item3 of item2?.Items" class="pb-space-y-4">
                      <a hybridHref [href]="item3?.Link" [hreflang]="item3?.Locale" class="pb-text-lg-m lg:pb-text-lg">{{ item3?.Title }}</a>
                      <div *ngFor="let item4 of item3?.Items" class="pb-flex pb-flex-col pb-gap-4">
                        <a hybridHref [href]="item4?.Link" [hreflang]="item4?.Locale" class="pb-text-md-m lg:pb-text-md">{{ item4?.Title }}</a>
                        <div *ngFor="let item5 of item4?.Items" class="pb-flex pb-flex-col pb-gap-4">
                          <a hybridHref [href]="item5?.Link" [hreflang]="item5?.Locale" class="pb-text-sm-m lg:pb-text-sm">{{ item5?.Title }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</section>
