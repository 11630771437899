<div [ngClass]="dynamicClass">
  <section class="sectionClass">
    <div class="contentWrapperClass">
      <article>
        <h1 data-title="true" class="articleTitleClass">
          <span class="articleStyleClass">
            {{rendering.fields?.Title?.value}}
          </span>
        </h1>
        <div class="articleBodyClass">
          <div class="articleBodyWrapperClass">
            <div *scRichText="rendering.fields?.ArticleBody"></div>
          </div>
        </div>
      </article>
    </div>
  </section>
</div>