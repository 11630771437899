<ng-container *ngIf="filteredCards.length > 1 else noSliderHero">
  <div #HeroLandingSwiper class="swiper pb-swiper-slider">
    <div class="swiper-wrapper" [ngClass]="{'pb-pb-6':energySaveMode}">
      <ng-container *ngFor="let slide of filteredCards">
        <div [ngClass]="slide?.fields?.PaginationColor?.value==='White' ? 'pb-pagination-inverse-color' : '' " class="swiper-slide !pb-h-auto">
          <ng-container *ngIf="slide.fields?.Illustration?.value else HeroLandingBigImage">
            <app-hero-landing-illustration [energySave]="energySaveMode" [data]="slide"></app-hero-landing-illustration>
          </ng-container>
          <ng-template #HeroLandingBigImage>
              <app-hero-landing-big-image  [energySave]="energySaveMode" [data]="slide"></app-hero-landing-big-image>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <div class="swiper-pagination pb-swiper-pagination-left md:pb-bottom-[61px] !pb-text-center md:!pb-text-start"></div>
  </div>
</ng-container>

<ng-template #noSliderHero>
  <ng-container *ngIf="filteredCards[0].fields?.Illustration?.value else HeroLandingBigImage">
    <app-hero-landing-illustration [energySave]="energySaveMode" [data]="filteredCards[0]"></app-hero-landing-illustration>
  </ng-container>
  <ng-template #HeroLandingBigImage>
      <app-hero-landing-big-image  [energySave]="energySaveMode" [data]="filteredCards[0]"></app-hero-landing-big-image>
  </ng-template>
</ng-template>