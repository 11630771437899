<div class="" [ngClass]="{'pb-calculator-mini': calculatorType=='mini', 'pb-calculator-midi': calculatorType=='midi', 'pb-calculator-maxi': calculatorType=='maxi'}">
  <label [for]="name">{{labelText}}</label>
  <div>
      <div class="pb-flex pb-items-center pb-gap-5">
        <ng-container *ngIf="enableInput">
          <div class="pb-font-bold pb-text-h-lg-m lg:pb-text-h-lg pb-w-4/5">
            <input type="text" [value]="value" [(ngModel)]="value" (ngModelChange)="onValueChange($event)" class="pb-w-4/5"/> 
            {{valueDescription}}
          </div>
          <!-- <i class="pb-icon pb-icon-pen-edit pb-text-i-32 pb-font-bold pb-cursor-pointer" (click)="enableInput= !enableInput; updateValue();"></i> -->
          <!-- <i class="pb-icon pb-icon-pen-edit pb-text-i-32 pb-font-bold pb-cursor-pointer"></i> -->
        </ng-container>
        <ng-container *ngIf="!enableInput">
          <span class="pb-font-bold pb-text-h-lg-m lg:pb-text-h-lg">{{value.toLocaleString(language)}} {{valueDescription}}</span>
          <!-- <i *ngIf="enableInputChange" class="pb-icon pb-icon-pen-edit pb-text-i-32 pb-font-bold pb-cursor-pointer" (click)="enableInput= !enableInput;"></i> -->
          <!-- <i *ngIf="enableInputChange" class="pb-icon pb-icon-pen-edit pb-text-i-32 pb-font-bold pb-cursor-pointer" ></i> -->
        </ng-container>
      </div>
      <input type="range" 
        #rangeInput
        class="pb-form-range pb-mt-2"
        [id]="name"
        [name]="name" 
        [ngClass]="color=='brand' ? 'pb-form-range-brand' : color=='esg' ? 'pb-form-range-esg': 'pb-form-range-yellow'"
        [(ngModel)]="value"
        [disabled]="isDisabled"
        (ngModelChange)="onValueChange($event)"
        [min]="minValue" 
        [max]="maxValue" 
        [step]="step"
      />
      <p *ngIf="helperText" class="pb-mt-2">{{helperText}}</p>
      <ng-container *ngIf="richText">
        <p class="pb-mt-2" [innerHTML]="richText"></p>
      </ng-container>
  </div>
</div>