<section class="pb-m-breadcrumb pb-container pb-mt-9" [ngClass]="dynamicClass">
  <div class="pb-flex pb-justify-between pb-gap-x-2">
    <ul class="pb-breadcrumb lg:pb-whitespace-nowrap pb-w-full lg:pb-w-auto pb-max-w-full lg:pb-max-w-[90%] pb-wrap">
      <ng-container *ngFor="let item of data.items; let last = last">
        <li class="pb-text-sm-m hover:pb-underline hover:pb-underline-offset-4 md:pb-text-sm pb-hidden lg:pb-block">
          <a hybridHref hreflang="{{language}}" *ngIf="!last" href="{{item?.href}}">{{item?.text}}</a>
        </li>

        <li *ngIf="!last">
          <span class="pb-px-2 g:pb-px-4 pb-hidden lg:pb-block pb-leading-none">///</span>
        </li>

        <li *ngIf="last" class="pb-text-sm-m md:pb-text-sm">
          <a hybridHref hreflang="{{language}}" href="{{item?.href}}" class="pb-pointer-events-none lg:pb-truncate ..." >{{ item?.text.split(' ').length > 5 ? (item?.text.split(' ').slice(0, 5).join(' ') + '...') : item?.text }}</a>
        </li>
        
      </ng-container>
    </ul>
    <!-- <div class="pb-relative">
        <div class="pb-flex">
            <button aria-label="copy link" id="pb-breadcrumb-copy-btn" class="pb-breadcrumb-action pb-p-3 pb-hidden lg:pb-block" (click)="copyLink($event)">
                <i class="pb-icon pb-icon-link pb-icon-color-default pb-text-i-24"></i>
                <span class="pb-tool-tip pb-tool-tip-left pb-tool-tip-action pb-absolute pb-top-[-65px] pb-right-0">{{'copied' | translate}}</span>
            </button>              
        </div>
    </div> -->
    <div class="pb-flex">
        <!-- <button aria-label="print" class="pb-breadcrumb-action pb-p-3" onclick="window.print();">
            <i class="pb-icon pb-icon-download pb-icon-color-default pb-text-i-24"></i>
        </button> -->
        <button
            type="button"
            (click)="navigatorShare()" 
            aria-label="share" 
            id="pb-breadcrumb-share-btn" 
            class="pb-breadcrumb-action pb-breadcrumb-action-share hover:pb-scale-110"
        >
            <i class="pb-icon pb-icon-share pb-icon-color-default pb-text-i-32"></i>
        </button>
    </div>
  </div>
</section> 