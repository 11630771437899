<section id="pb-ebanking-modal-id" class="pb-ebanking-modal pb-hidden">
  <div class="pb-ebanking-content pb-flex pb-flex-col">
    <button type="button" aria-label="close-ebanking-modal" class="pb-pr-4 pb-pt-4 pb-ml-auto pb-w-fit pb-cursor-pointer pb-icon-color-default hover:pb-scale-110" (click)="closeEbankingModal()">
      <i class="pb-icon pb-icon-check-x-dash pb-text-i-48"></i>
    </button>
    <div class="pb-flex md:pb-pl-8 lg:pb-pl-12 pb-container md:pb-pr-0">
      <div class="pb-w-full md:pb-w-2/3 pb-flex pb-flex-col">
        <div class="pb-text-d-xl-m md:pb-text-d-xl pb-text-color-brand pb-mb-2 pb-font-serif" data-title="true">{{rendering?.fields?.Title?.value}}</div>
        <div class="pb-text-sm-m md:pb-text-sm pb-mb-4">{{rendering?.fields?.Subtitle?.value}}</div>
        <div class="pb-flex pb-flex-col md:pb-flex-row pb-gap-3 pb-mb-4">
          <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.CorporateLink?.value?.href}}" attr.aria-label="{{rendering?.fields?.CorporateLink?.value?.text}}" target="_blank" class="pb-btn pb-btn-primary pb-text-nowrap e-banking-mobile-button">
            <i class="pb-icon pb-icon-protection"></i>
            {{rendering?.fields?.CorporateLink?.value?.text}}
          </a>
          <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.IndividualLink?.value?.href}}" attr.aria-label="{{rendering?.fields?.IndividualLink?.value?.text}}" target="_blank" class="pb-btn pb-btn-primary pb-text-nowrap e-banking-mobile-button">
            <i class="pb-icon pb-icon-protection"></i>
            {{rendering?.fields?.IndividualLink?.value?.text}}
          </a>
        </div>
        <div class="pb-mb-4 pb-text-center md:pb-text-start">{{rendering?.fields?.Text?.value}} 
          <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.TextHelperLink?.value?.href}}" attr.aria-label="{{rendering?.fields?.TextHelperLink?.value?.text}}" target="_blank" class="pb-underline pb-block md:pb-inline-block">
          {{rendering?.fields?.TextHelperLink?.value?.text}}
          </a>
        </div>
        <hr class="pb-mb-6"/>
        <div class="pb-text-d-lg-m md:pb-text-d-lg pb-text-color-brand pb-mb-2 pb-font-serif">{{rendering?.fields?.SecondTitle?.value}}</div>
        <div class="pb-text-sm-m md:pb-text-sm pb-mb-4">{{rendering?.fields?.SecondSubtitle?.value}}</div>
        <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.CreateAccountLink?.value?.href}}" attr.aria-label="{{rendering?.fields?.CreateAccountLink?.value?.text}}" target="_blank" class="pb-btn pb-btn-secondary pb-text-nowrap md:pb-w-fit pb-mb-7 md:pb-mb-0">
          {{rendering?.fields?.CreateAccountLink?.value?.text}}
          <i class="pb-icon pb-icon-arrow-right"></i>
        </a>
      </div>
      <img *ngIf="dataSector!='private'" offset="100" [defaultImage]="'images/default-image.png' | asset" [lazyLoad]="image.src" [alt]="image.alt" class="pb-m-auto pb-hidden md:!pb-block pb-max-w-[167px]"/>
    </div>
    <div class="pb-ebanking-modal-footer pb-mt-auto pb-h-fit pb-py-5 md:pb-pl-8 lg:pb-pl-12 pb-flex pb-gap-4 pb-items-center pb-container md:pb-pr-0">
      <i class="pb-icon pb-icon-alert-triangle pb-text-i-32"></i>
      <div>
        {{rendering?.fields?.FooterText?.value}} 
        <a hybridHref hreflang="{{language}}" href="{{rendering?.fields?.FooterTextHelperLink?.value?.href}}" attr.aria-label="{{rendering?.fields?.FooterTextHelperLink?.value?.text}}" target="_blank" class="pb-underline">
        {{rendering?.fields?.FooterTextHelperLink?.value?.text}}
        </a>
      </div>
    </div>
  </div>
</section>
