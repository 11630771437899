<div *ngIf="dataSector!='private'" [ngClass]="{'pb-c-hero-campaign': dataSector =='personal'}" class="pb-c-hero pb-c-hero-big-image pb-relative pb-flex pb-flex-col-reverse lg:pb-flex-col pb-h-full sm:pb-h-[700px] lg:pb-h-[840px] 4xl:pb-h-[1200px]">
  <img *ngIf="!isMobile" src="{{imageDesktop}}" alt="{{fields?.Image?.value?.alt}}" class="pb-w-full pb-h-full pb-object-cover pb-object-top pb-min-h-60">
  <img *ngIf="isMobile" src="{{imageMobile}}" alt="{{fields?.ImageMobile?.value?.alt}}" class="pb-w-full pb-h-full pb-object-cover pb-object-top pb-min-h-60">
  
  <div [ngClass]="energySave ? 'pb-relative': 'lg:pb-absolute' " class="pb-flex pb-w-full lg:pb-top-0 lg:pb-items-end lg:pb-h-full">
    <div class="lg:pb-container pb-h-full lg:pb-flex lg:pb-flex-col lg:pb-justify-center pb-w-full">
      <div [ngClass]="{'pb-hero-overflow-personal pb-w-full lg:pb-w-1/2 lg:pb-max-w-[626px] !pb-pb-6': dataSector =='personal'}">
        <div *ngIf="data?.fields?.Title" [ngClass]="bubbleClass" class="pb-relative pb-px-4 pb-pb-5 pb-flex pb-flex-col pb-gap-5 lg:pb-pl-6 lg:pb-pr-12">  
            <div [ngClass]="{'!pb-text-white':dataSector=='holdings'}">
              <ng-container *ngIf="data?.fields?.HeaderTag?.value==='Heading1'; else heading2">
                <h1 class="pb-text-d-xl-m pb-font-serif pb-line-clamp-3 lg:pb-text-d-2xl pb-whitespace-pre-wrap" [ngClass]="{'pb-text-color-brand' : dataSector != 'holdings'}" data-title="true" *scText="data?.fields?.Title"></h1>
              </ng-container>
              <ng-template #heading2>
                <h2 class="pb-text-d-xl-m pb-font-serif pb-line-clamp-3 lg:pb-text-d-2xl pb-whitespace-pre-wrap" [ngClass]="{'pb-text-color-brand' : dataSector != 'holdings'}" data-title="true" *scText="data?.fields?.Title"></h2>
              </ng-template>
              
              <div *ngIf="data?.fields?.Subtitle?.value" class="pb-hidden pb-pt-4 pb-text-h-xs-m md:pb-block lg:pb-text-h-xs" >{{data?.fields?.Subtitle?.value}}</div>
            </div>
            <div class="pb-flex lg:pb-flex-row pb-flex-col pb-gap-4 lg:pb-gap-5 pb-z-10">
              <ng-container *ngIf="dataSector!='holdings'">

                <a *ngIf="data?.fields?.Link?.value?.href" hybridHref hreflang="{{language}}" [href]="data?.fields?.Link?.value?.href" [attr.aria-label]="data?.fields?.Link?.value?.title" [target]="data?.fields?.Link?.value?.target" [ngClass]="energySave ? 'pb-w-full' : 'pb-w-full sm:pb-w-fit' " class="pb-btn pb-btn-primary">
                  {{data?.fields?.Link?.value?.text}}
                  <i class="pb-icon pb-icon-arrow-right pb-text-i-24"></i>
                </a>
                <a *ngIf="data.fields?.SecondaryLink?.value?.href" hybridHref hreflang="{{language}}" [href]="data.fields?.SecondaryLink?.value?.href" [attr.aria-label]="data.fields?.SecondaryLink?.value?.title" [target]="data?.fields?.SecondaryLink?.value?.target" [ngClass]="energySave ? 'pb-w-full' : 'pb-w-full sm:pb-w-fit' " class="pb-btn pb-btn-secondary">
                  {{data?.fields?.SecondaryLink?.value?.text}}
                  <i class="pb-icon pb-icon-arrow-right pb-text-i-24"></i>
                </a>
              </ng-container>
              <ng-container *ngIf="dataSector=='holdings'">
                <a *ngIf="data?.fields?.Link?.value?.href" hybridHref hreflang="{{language}}" [href]="data?.fields?.Link?.value?.href" [attr.aria-label]="data?.fields?.Link?.value?.title" [target]="data?.fields?.Link?.value?.target" [ngClass]="energySave ? 'pb-w-full' : 'pb-w-full sm:pb-w-fit' " class="pb-btn pb-btn-primary-brand">
                  {{data?.fields?.Link?.value?.text}}
                  <i class="pb-icon pb-icon-arrow-right pb-text-i-24"></i>
                </a>
              </ng-container>
            </div>
        </div>

      </div>
    </div>
  </div>
</div>
<div *ngIf="dataSector == 'private'" class="pb-c-hero pb-w-full pb-flex pb-flex-col pb-container lg:pb-flex-row pb-h-full lg:pb-h-[840px]"> 
  <div class="pb-w-full lg:pb-w-4/12 pb-block lg:pb-flex lg:pb-pt-[171px]">
    <div class="pb-p-4 pb-pt-[140px] pb-pb-6 lg:pb-p-0 pb-relative pb-flex pb-flex-col pb-justify-center pb-gap-4">
      <!-- <h1 class="pb-text-d-xl-m lg:pb-text-d-2xl pb-font-serif pb-text-color-brand" *scText="data?.Title"></h1> -->
      <ng-container *ngIf="data?.fields?.HeaderTag?.value==='Heading1'; else heading2">
        <h1 class="pb-text-d-xl-m pb-text-color-brand pb-font-serif pb-line-clamp-3 lg:pb-text-d-2xl lg:pb-w-[514px] pb-whitespace-pre-wrap" *scText="data?.fields?.Title"></h1>
      </ng-container>
      <ng-template #heading2>
        <h2 class="pb-text-d-xl-m pb-text-color-brand pb-font-serif pb-line-clamp-3 lg:pb-text-d-2xl lg:pb-w-[514px] pb-whitespace-pre-wrap" *scText="data?.fields?.Title"></h2>
      </ng-template>
      <div *ngIf="data?.fields?.Subtitle?.value" class="pb-hidden pb-pt-4 pb-text-h-xs-m md:pb-block lg:pb-text-h-xs" >{{data?.fields?.Subtitle?.value}}</div>

      <div class="pb-flex sm:pb-flex-row pb-flex-col pb-gap-4 sm:pb-gap-5 pb-z-10">
        <a *ngIf="data?.fields?.Link?.value?.href" hybridHref hreflang="{{language}}" [href]="data?.fields?.Link?.value?.href" [attr.aria-label]="data?.fields?.Link?.value?.title" [target]="data?.fields?.Link?.value?.target" [ngClass]="energySave ? 'pb-w-full' : 'pb-w-full sm:pb-w-fit' " class="pb-btn pb-btn-primary">
          {{data?.fields?.Link?.value?.text}}
          <i class="pb-icon pb-icon-arrow-right pb-text-i-24"></i>
        </a>
        <a *ngIf="data?.SecondaryLink?.value?.href" hybridHref hreflang="{{language}}" [href]="data?.SecondaryLink?.value?.href" [attr.aria-label]="data?.SecondaryLink?.value?.title" [target]="data?.fields?.SecondaryLink?.value?.target" [ngClass]="energySave ? 'pb-w-full' : 'pb-w-full sm:pb-w-fit' " class="pb-btn pb-btn-secondary">
          {{data?.fields?.SecondaryLink?.value?.text}}
          <i class="pb-icon pb-icon-arrow-right pb-text-i-24"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="pb-w-full lg:pb-w-8/12">
    <img *ngIf="!isMobile" src="{{imageDesktop}}" alt="{{fields?.Image?.value?.alt}}" class="pb-w-full pb-h-full pb-object-cover pb-object-center lg:pb-max-h-[840px] lg:pb-max-w-[840px] 4xl:pb-max-w-[1200px] 4xl:pb-max-h-[1200px] pb-m-0 lg:pb-ml-auto" >     
    <img *ngIf="isMobile" src="{{imageMobile}}" alt="{{fields?.ImageMobile?.value?.alt}}" class="pb-w-full pb-h-full pb-object-cover pb-object-center lg:pb-max-h-[840px] lg:pb-max-w-[840px] 4xl:pb-max-w-[1200px] 4xl:pb-max-h-[1200px] pb-m-0 lg:pb-ml-auto" >     
  </div>
</div>