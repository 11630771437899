import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { JssStylingService } from '../../jss-styling.service';

@Component({
  selector: 'app-article-content',
  templateUrl: './article-content.component.html',
  styleUrls: ['./article-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArticleContentComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  articleDate: Date = new Date();
  timeToRead: string;
  dynamicClass: string;

  constructor(public jssStylingService: JssStylingService,) {}

  ngOnInit() {
    // remove this after implementation is done
    this.dynamicClass = this.jssStylingService.getComponentClass(this.rendering);
    this.articleDate = (this.rendering?.fields?.ArticleReleaseDate as any)?.value && (this.rendering?.fields?.ArticleReleaseDate as any)?.value != "0001-01-01T00:00:00Z"? (this.rendering?.fields?.ArticleReleaseDate as any)?.value : '';
    if(this.articleDate){
      this.timeToRead = (this.rendering?.fields?.ReadingTime as any)?.value ? " | " + (this.rendering?.fields?.ReadingTime as any)?.value + "'" : '';
    }
    else{
      this.timeToRead ='';
    }
  }
  
}
