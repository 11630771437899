<label
    *ngIf="labelTextTop"
    [htmlFor]="id"
    [ngClass]="isDisabled ? 'pb-form-label-top--disabled' : ''"
>
    {{ labelTextTop }}
    <!-- <span *ngIf="isRequired">*</span> -->
</label>
<div [ngClass]="{'pb-flex pb-flex-nowrap pb-gap-3 pb-items-center' : labelTextBottom}">
    <input
        type="checkbox"
        [id]="id"
        [name]="name"
        [required]="isRequired "
        [disabled]="isDisabled"
        [ngClass]="error ? 'pb-error-showcase' : ''"
        [ngClass]="{'pb-error-showcase' : error}"
        class="pb-form-checkbox"
        [value]="value"
        [checked]="isChecked"
        (input)="onValueChange($event.target.checked)"
    />
    <label [for]="id" *ngIf="labelTextBottom">{{ labelTextBottom }}</label>
</div>
<p [ngClass]="error ? 'pb-text-[#CC0000]' : ''" *ngIf="error">{{ errorMessage }}</p>
