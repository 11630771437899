<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Internal Server Error</title>
    <style>
      @font-face {
        font-family: "Piraeus Open Sans";
        src: url('https://stsitecorebkpprodwe001.blob.core.windows.net/fonts/PiraeusOpenSans-Regular.woff2') format('woff2'),
          url('https://stsitecorebkpprodwe001.blob.core.windows.net/fonts/PiraeusOpenSans-Regular.woff') format('woff'),
          url('https://stsitecorebkpprodwe001.blob.core.windows.net/fonts/PiraeusOpenSans-Regular.ttf') format('ttf');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: "Piraeus Open Sans";
        src: url('https://stsitecorebkpprodwe001.blob.core.windows.net/fonts/PiraeusOpenSans-Bold.woff2') format('woff2'),
          url('https://stsitecorebkpprodwe001.blob.core.windows.net/fonts/PiraeusOpenSans-Bold.woff') format('woff'),
          url('https://stsitecorebkpprodwe001.blob.core.windows.net/fonts/PiraeusOpenSans-Bold.ttf') format('ttf');
        font-weight: bold;
        font-style: normal;
      }
      @font-face {
        font-family: "Piraeus Open Serif";
        src: url('https://stsitecorebkpprodwe001.blob.core.windows.net/fonts/PiraeusOpenSerif-Regular.woff2') format('woff2'),
          url('https://stsitecorebkpprodwe001.blob.core.windows.net/fonts/PiraeusOpenSerif-Regular.woff') format('woff'),
          url('https://stsitecorebkpprodwe001.blob.core.windows.net/fonts/PiraeusOpenSerif-Regular.ttf') format('ttf');
        font-weight: normal;
        font-style: normal;
      }

      body {
        /* color: #1E1E1E; */
        font-family:"Piraeus Open Sans";
      }
      .pb-container {
        max-width: 100%;
        padding: 1rem;
        padding-bottom: 0rem;
      }
      .header {
        background-color: #FFD900;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-top: 40px;
        
      }
      .pb-logo-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
      }
      a {
        cursor: pointer;
      }
      .pb-logo {
        padding: 24px 16px;
      }
      .pb-ebanking-wrapper {
        display: flex;
        align-items: center;
        padding: 10.8px;
      }
      .pb-ebanking-wrapper > a {
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        border-width: 1px;
        padding: 1rem 1.5rem;
        font-size: 1rem;
        line-height: 140%;
        font-weight: 700;
        text-decoration-line: none;
        background-color: #002F30;
        color: white;
        border-color: #002F30;
      }
      .pb-ebanking-wrapper > i {
        width: 24px;
        height: 24px;
      }
      .main-content {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 64px;
        padding-left: 16px;
        padding-right: 16px;
      }
      .text-block {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
      }
      h2 {
        font-family: "Piraeus Open Serif";
        font-size: 34px;
        line-height: 37.4px;
        letter-spacing: -1.02px;
        color: #002F30;
        margin-bottom: 24px;
      }
      h3 {
        font-size: 26px;
        line-height: 31.2px;
        font-weight: 700;
        margin-bottom: 12px;
      }
      p {
        font-size: 18px;
        line-height: 25.2px;
      }
      .pb-header-desktop {
          display: flex;
          margin-bottom: 64px;
        }
      
      @media screen and (min-width:640px) {
        .pb-container {
          padding: 1.5rem;
        }
      }
      
      @media screen and (min-width:1024px) {
        .header {
          margin-top: 72px;
        }
        .pb-container {
          padding: 0rem 4.5rem;
        }
        .main-content {
          flex-direction: row;
          row-gap: 0px;
          column-gap: 10px;
        }
        .image-wrapper {
          width: 34%;
        }
        .text-block {
          width: 66%;
        }
      }
      
      @media screen and (min-width:1280px) {
        .pb-container {
          max-width:1444px;
          padding: 0rem 5rem;
        }
      }
    </style>
</head>
<body>
  <div>
   
    <div class="pb-container pb-header-desktop">
    <div class="header">
        <div class="pb-logo-wrapper">
          <a href="/en/idiwtes" aria-label="logo">
            <div class="pb-logo">
              <img alt="Piraeus Bank Logo" src="https://stsitecorebkpprodwe001.blob.core.windows.net/images/piraeusLogo.svg">
            </div>
          </a>
        </div>
        <div class="pb-ebanking-wrapper">
          <a href="https://www.ebanking.piraeusbank.gr"><i><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path d="M11.8594 4.01562C13.9556 4.01562 15.6562 5.71631 15.6562 7.8125V10.3438H8.0625V7.8125C8.0625 5.71631 9.76318 4.01562 11.8594 4.01562ZM6.79688 7.8125V10.3438H6.16406C4.41592 10.3438 3 11.7597 3 13.5078V19.8359C3 21.5841 4.41592 23 6.16406 23H17.5547C19.3028 23 20.7188 21.5841 20.7188 19.8359V13.5078C20.7188 11.7597 19.3028 10.3438 17.5547 10.3438H16.9219V7.8125C16.9219 5.01626 14.6556 2.75 11.8594 2.75C9.06313 2.75 6.79688 5.01626 6.79688 7.8125ZM6.16406 11.6094H17.5547C18.6028 11.6094 19.4531 12.4597 19.4531 13.5078V19.8359C19.4531 20.884 18.6028 21.7344 17.5547 21.7344H6.16406C5.11597 21.7344 4.26562 20.884 4.26562 19.8359V13.5078C4.26562 12.4597 5.11597 11.6094 6.16406 11.6094ZM12.4922 15.0898C12.4922 14.7418 12.2074 14.457 11.8594 14.457C11.5113 14.457 11.2266 14.7418 11.2266 15.0898V18.2539C11.2266 18.602 11.5113 18.8867 11.8594 18.8867C12.2074 18.8867 12.4922 18.602 12.4922 18.2539V15.0898Z" fill="white"/>
  </svg></i>e-banking</a>
        </div>
      </div>
    </div>
    <div class="main-content pb-container">
      <img class="image-wrapper" src="https://stsitecorebkpprodwe001.blob.core.windows.net/images/503_image.svg">
      
      <div class="text-block">
        <h2>500 Σφάλμα Διακομιστή</h2>
        <h3>Ζητούμε συγγνώμη για την ταλαιπωρία</h3>
        <p>
        Aντιμετωπίζουμε προσωρινό τεχνικό πρόβλημα με τον διακομιστή μας. Η ομάδα μας εργάζεται σκληρά για να επιλύσει το πρόβλημα το συντομότερο δυνατόν.<br/><br/>
        Εάν χρειάζεστε άμεση βοήθεια, μπορείτε να επικοινωνήσετε με την υπηρεσία εξυπηρέτησης πελατών στο 210 32 88 000 ή να επισκεφτείτε το πλησιέστερο κατάστημα μας.<br/><br/>
        Σας ευχαριστούμε για την κατανόηση και τη συνεργασία σας.
        </p>
      </div>
    </div>
  </div>
</body>
</html>