
<div *ngIf="data"
    [ngClass]="{
        'pb-bg-[#FDE8E2] pb-border-[#CC0000]': data?.Color === 'Red',
        'pb-bg-[#FEF4D7] pb-border-[#F9BC00]': data?.Color === 'Yellow',
        'pb-border-[#F9BC00]': data?.Color === 'White' || data?.Color === ''
    }"
    class=" pb-relative pb-flex pb-flex-row pb-gap-7 pb-items-center pb-border-2 pb-p-7"
>
    <i (click)="closeAlert()"
        [ngClass]="{
            'pb-text-[#CC0000]': data?.Color === 'Red',
            'pb-text-[#F9BC00]': data?.Color === 'Yellow'
        }"
        class="pb-icon pb-icon-close hover:pb-cursor-pointer pb-text-i-32 pb-w-fit pb-absolute pb-top-[20px] pb-right-5"
    ></i>
    <i
        [ngClass]="{
            'pb-text-[#CC0000]': data?.Color === 'Red',
            'pb-text-[#F9BC00]': data?.Color === 'Yellow'
        }"
        class="pb-icon pb-icon-alert-triangle pb-text-i-48"
    ></i>
    <p class="!pb-text-lg-m md:!pb-text-lg pb-w-3/4" [innerHTML]="data?.Content"></p>
</div>
