<div>
  <ng-container *ngIf="state === LayoutState.Layout">
    <sc-placeholder name="jss-pb-header" [rendering]="route" (loaded)="onPlaceholderLoaded($event)"></sc-placeholder>
  </ng-container>
  <main>
    <ng-container *ngIf="state === LayoutState.Layout">
      <app-visitor-identification></app-visitor-identification>
      <sc-placeholder name="jss-pb-main" [rendering]="route" (loaded)="onPlaceholderLoaded($event)">
        <div *scPlaceholderLoading style="height: 100vh;"></div>
      </sc-placeholder>
    </ng-container>
    
    <app-not-found *ngIf="state === LayoutState.NotFound" [errorContextData]="errorContextData"></app-not-found>
    <app-server-error *ngIf="state === LayoutState.Error"></app-server-error>
  </main>
  <ng-container *ngIf="state === LayoutState.Layout">
    <sc-placeholder [ngClass]="{ 'pb-hidden': !mainLoaded }" name="jss-pb-footer" [rendering]="route" (loaded)="onPlaceholderLoaded($event)"></sc-placeholder>
  </ng-container>
</div>