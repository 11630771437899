import { Component, Input, Inject, PLATFORM_ID, OnDestroy, Renderer2, OnInit } from "@angular/core";
import { ComponentRendering } from "@sitecore-jss/sitecore-jss-angular";
import { CustomDeviceDetectorService } from "../../services/custom-device-detector.service";
import { DOCUMENT, isPlatformServer } from "@angular/common";
import { AssetPipe } from "../../pipes/asset.pipe";
import { JssContextService } from '../../jss-context.service';
import { Subscription } from 'rxjs';
import { ThemeService } from "../../services/theme.service";


@Component({
    selector: "app-hero-landing-illustration",
    templateUrl: "./hero-landing-illustration.component.html",
})
export class HeroLandingIllustrationComponent implements OnInit, OnDestroy{
    @Input() rendering: ComponentRendering;
    @Input() data: any;
    @Input() energySave: boolean = false;
    public isMobile: boolean = false;
    public fields: any;
    loadedImage: HTMLImageElement;
    public illustration: string;
    public illustrationMobile: string;
    _isPlatformServer: boolean = false;
    public dataSector:string;
    public contextSubscription: Subscription;
    language:string;
    private energySaveSubscription: Subscription;

    constructor(
        public jssContext: JssContextService, 
        private deviceDetector: CustomDeviceDetectorService, 
        @Inject(PLATFORM_ID) public readonly platformId: string, 
        @Inject(DOCUMENT) private document: Document,
        private themeService: ThemeService,
        private renderer: Renderer2 ) {
        platformId;
    }

    ngOnInit() {
        this.fields = this.data?.fields as any;
        this.isMobile = this.deviceDetector.isMobile() ;
        this._isPlatformServer = isPlatformServer(this.platformId);
        this.contextSubscription = this.jssContext.state.subscribe((newState) => {
            this.language = newState.language
        });
        this.illustration = new AssetPipe().transform('images/default-image.png');
        // this.illustrationMobile = new AssetPipe().transform('images/default-image.png');
        this.energySaveSubscription = this.themeService.isEnegySaveModeEnabled().subscribe(energySave => {
            this.energySave = energySave;
            if(!this.deviceDetector.isMobile()){
                this.illustration = energySave ? this.fields?.IllustrationDesktopEnergy?.value?.src  : this.fields?.IllustrationDesktop?.value?.src;
            }else{
                this.illustration = energySave ? this.fields?.IllustrationEnergyMobile?.value?.src : this.fields?.IllustrationMobile?.value?.src ; 
            }
        });

        if(this.illustration && !this.document.querySelector(`link[href="${this.illustration}"]`)) {
            const imageLink = this.renderer.createElement('link');
            this.renderer.setAttribute(imageLink, 'rel', 'preload');
            this.renderer.setAttribute(imageLink, 'href', this.illustration);
            this.renderer.setAttribute(imageLink, 'as', 'image');
            this.renderer.appendChild(this.document.head, imageLink);
        }
    }

    ngOnDestroy(): void {
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
        if (this.energySaveSubscription){
            this.energySaveSubscription.unsubscribe();
        }
        if(this.illustration && this.document.querySelector(`link[href="${this.illustration}"]`)) {
            this.document.querySelector(`link[href="${this.illustration}"]`).remove()
        }
        
      }
}
