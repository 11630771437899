import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';

@Injectable()
export class UuidService{
    constructor(private cookieService: CookieService) {}

    getDomainId(): string {
        let uuid = this.cookieService.get('uuid');
        if (uuid) {
          return uuid;
        }
        return this.generateUUID(environment.searchDomainId);
    }

    private generateUUID(domainId: string): string {
        const uuidx = 'xx-xx-4x-1p-';
        let uuid = uuidx.replace(/[x]/g, () => this.digit2string((Math.random() * 36) | 0));
        for (let i = 0; i < 5; i++) {
        uuid += ('0000' + ((Math.random() * 1679615) | 0).toString(36)).slice(-4);
        }
        let value = domainId;
        value += '-' + uuid + '-' + Date.now();
        this.setCookie('uuid', value);
        return value;
    }

    private digit2string(digit: number): string {
        return digit.toString(36);
    }

    private setCookie(name: string, value: string, days: number = 365): void {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        // const expires = `expires=${date.toUTCString()}`;
        // document.cookie = `${name}=${value};${expires};path=/`;
        this.cookieService.set(name,value,date);
    }
}