import { Component, OnInit, Input, ViewEncapsulation, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { ImageService } from '../../services/image.service';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-hero-article',
  templateUrl: './hero-article.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HeroArticleComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  _isPlatformServer: boolean = false;
  public imageOnly: boolean = false;
  public isMobile: boolean = false;
  public energySaveMode: boolean = false;
  public dataSector:string;
  public energySave: Subscription;
  public fields:any;
  public img:{src:string, alt:string};
  loadedImage: HTMLImageElement;
  public hideHeroArticle: boolean;
  public theme:string;
  
  constructor(private imageService: ImageService, @Inject(PLATFORM_ID) public readonly platformId: string,private themeService: ThemeService, @Inject(DOCUMENT) public document: Document, private renderer: Renderer2, ) {
    platformId;
}

  ngOnInit() {
    // remove this after implementation is done
    this._isPlatformServer = isPlatformServer(this.platformId);
    this.fields = this.rendering?.fields as any;
      
    this.energySave = this.themeService.isEnegySaveModeEnabled().subscribe(energySave => {
      this.energySaveMode = energySave;   
      if (this.fields?.Image?.value?.src){
        this.imageOnly = true;
        this.img = this.imageService.getImage(this.fields);
        if(this.img?.src){
          if(!this.document.querySelector(`link[href="${this.img.src}"]`)) {
            const imageLink = this.renderer.createElement('link');
            this.renderer.setAttribute(imageLink, 'rel', 'preload');
            this.renderer.setAttribute(imageLink, 'href', this.img.src);
            this.renderer.setAttribute(imageLink, 'as', 'image');
            this.renderer.appendChild(this.document.head, imageLink);
          }
        }
        // this.loadedImage = new Image();
        // this.loadedImage.src = img.src;
        // this.loadedImage.onload = () => {
        //     this.image = img;
        // };
      }       
      this.hideHeroArticle = this.imageOnly || (this.rendering.fields?.Title as any)?.value!='' ? false : true;

      this.dataSector = this.document.querySelector("html").getAttribute("data-sector");
      if(this.dataSector == "holdings"){
        switch ((this.rendering?.fields as any)?.Color?.value) {
          case 'green-70':
              this.theme = "green";
              break;
          case 'yellow-60':
              this.theme = "yellow";
              break;
          case 'warm-grey-60':
              this.theme = "warm-grey";
              break;
          default:
              this.theme = "yellow";
              break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (!this._isPlatformServer) {
        if(this.energySave){
            this.energySave.unsubscribe();
        }
    }
}
}
