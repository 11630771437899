import { Component, OnInit, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html'
})
export class SitemapComponent implements OnInit {
  @Input() rendering: ComponentRendering;

  constructor() { }

  ngOnInit() {
    // console.log('sitemap component initialized with component data', this.rendering);
  }
}
