import { isPlatformServer, isPlatformBrowser } from "@angular/common";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ElementRef,
  SimpleChanges,
  forwardRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: "[app-form-drop-down]",
  templateUrl: "./form-drop-down.component.html",
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormDropDownComponent),
    multi: true
  }]
})
export class FormDropDownComponent implements AfterViewInit, ControlValueAccessor {
  @Input() name: string;
  @Input() id: string;
  @Input() labelText: string;
  @Input() helperText: string;
  @Input() placeholderLabel: string ;
  @Input() placeholderValue: string ;
  @Input() error: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isLarge: boolean = false;
  @Input() hasPlaceholder: boolean = false;
  @Input() sorting: boolean = true;
  @Input() enableSearch: boolean = false;
  @Input() options: any[];
  @Output() valueChange = new EventEmitter<string>();

  _isPlatformServer: boolean = false;
  _isPlatformBrowser: boolean = false;
  choicesInstance: any;
  value: string;

  constructor(
    @Inject(PLATFORM_ID) public readonly platformId: string,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  onValueChange(event: any) {
    if (event.target.id === this.id){
      this.value = event.target.value;
      this.writeValue(this.value);
      this.onChange(this.value);
      this.onTouched();
      this.error = false;
      this.valueChange.emit(this.value);
    }
  }

  ngOnInit(): void {
    this._isPlatformServer = isPlatformServer(this.platformId);
    this._isPlatformBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit(): void {
    if (!this._isPlatformServer && this._isPlatformBrowser) {
      this.value = this.hasPlaceholder ? this.placeholderValue ? this.placeholderValue : "" : this.options[0].value;
      let selectElement = document.getElementById(this.id);
      import("choices.js").then((Choices) => {
        const choices = Choices.default;
        this.choicesInstance = new choices(selectElement, {
          silent: true,
          searchEnabled: this.enableSearch,
          placeholder: true,
          placeholderValue: null,
          addItems: true,
          removeItems: false,
          itemSelectText: "",
          shouldSort: this.sorting,
          allowHTML: true,
          labelId: this.id,
        });

        if(this.isLarge){this.choicesInstance.containerOuter.element.classList.add("pb-form--large");}

        this.renderer.listen(selectElement, "change", (event) => {
          this.onValueChange(event);
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this._isPlatformServer && this._isPlatformBrowser) {
      if (changes.options) {
        this.updateChoices();
      }
      const choicesInner = this.el.nativeElement.querySelector(".choices__inner");
      if (choicesInner) {
        if(this.error){
          choicesInner.classList.add("pb-error-showcase");
        } else {
          choicesInner.classList.remove("pb-error-showcase");
        }
      }
    }
  }

  updateChoices() {
    if (!this._isPlatformServer && this._isPlatformBrowser) {
      if (this.choicesInstance && this.options) {
        const optionsWithPlaceholder = this.hasPlaceholder
          ? [{ value: '', text: this.placeholderLabel ,placeholder : true, selected : true, disabled : true}, ...this.options]
          : this.options;
        this.choicesInstance.setChoices(
          optionsWithPlaceholder,
          "value",
          "text",
          true
        );
      }
    }
  }

  reset(id : any){
    if (this.choicesInstance) {
      this.choicesInstance.removeActiveItems(id);
      this.updateChoices();
      this.value = '';
      this.onChange(this.value);
      this.valueChange.emit(this.value);
    }
  }

  // ControlValueAccessor methods
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
    // if (this.choicesInstance) {
    //   this.choicesInstance.setChoiceByValue(value);
    // }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
