import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: '[app-form-text-area]',
  templateUrl: './form-text-area.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormTextAreaComponent),
    multi: true
  }]
})
export class FormTextAreaComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() id: string;
  @Input() rows: number = 3;
  @Input() maxLength: number;
  @Input() labelText: string;
  @Input() placeholder: string = "";
  @Input() helperText: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() error: boolean = false;
  @Output() valueChange = new EventEmitter<string>();
  value: string = "";

  constructor() {}

  // ControlValueAccessor methods
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onValueChange(value: string) {
    this.value = value;
    this.onChange(value);
    this.valueChange.emit(value);
  }

  onKeyboardChange(event: any) {
    if (event.key === "Enter" || event.keyCode === 13) {
        event.target.blur();
    }
}

  reset() {
    this.value = '';
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
