// dynamic-script-loader.service.ts
import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {
  private renderer: Renderer2;

  constructor(
    public rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadScript(scriptUrl: string, attrName?: string, attrValue?:string, attrName2?: string, attrValue2?:string, preconnect?:boolean): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/JavaScript';
    script.src = scriptUrl as string;
    // debugger;
    if(preconnect){
      const link = this.renderer.createElement('link');
      link.href = scriptUrl as string;
      this.renderer.setAttribute(link, 'rel', 'preconnect');
      this.renderer.appendChild(this.document.head, link);
    }
    if(attrName && attrValue){
      this.renderer.setAttribute(script, attrName, attrValue);
    }
    if(attrName2 && attrValue2){
      this.renderer.setAttribute(script, attrName2, attrValue2);
    }
    this.renderer.setAttribute(script, "defer", "true");
    this.renderer.appendChild(this.document.body, script);
  }
  
  loadScriptToHead(scriptUrl: string, attrName?: string, attrValue?:string, attrName2?: string, attrValue2?:string, preconnect?:boolean): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/JavaScript';
    script.src = scriptUrl as string;
    // debugger;
    if(preconnect){
      const link = this.renderer.createElement('link');
      link.href = scriptUrl as string;
      this.renderer.setAttribute(link, 'rel', 'preconnect');
      this.renderer.appendChild(this.document.head, link);
    }
    if(attrName && attrValue){
      this.renderer.setAttribute(script, attrName, attrValue);
    }
    if(attrName2 && attrValue2){
      this.renderer.setAttribute(script, attrName2, attrValue2);
    }
    this.renderer.setAttribute(script, "defer", "true");
    this.renderer.appendChild(this.document.head, script);
  }

  loadScriptInline(scriptContent: string, targetSelector: string): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/JavaScript';
    script.textContent = scriptContent;

    const targetElement = this.document.querySelector(targetSelector);
    if (targetElement) {
      this.renderer.appendChild(targetElement, script);
    }
  }
}