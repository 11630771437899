<!-- <ng-container *ngIf="!(data?.Items?.length > 0); else heroSlider;"> -->
    <ng-container [ngTemplateOutlet]="HeroInternal" [ngTemplateOutletContext]="{theme: theme, data: data, link:link, image:image }"></ng-container>

<ng-template #HeroInternal let-theme="theme" let-data="data" let-link="link" let-image="image">
    <ng-container *ngIf="dataSector != 'personal' && dataSector != 'private' && dataSector != 'holdings' && !energySaveMode">
        <div class="pb-c-hero pb-c-hero-mask pb-w-full pb-flex pb-flex-col pb-hero-theme--{{ theme }}" [ngClass]="[(data?.Position) && data?.Position?.value == 'left' ? 'lg:pb-flex-row-reverse' : 'lg:pb-flex-row']"> 
            <div class="pb-w-full lg:pb-w-5/12 4xl:pb-w-[47%] pb-block lg:pb-flex lg:pb-pt-[171px]" [ngClass]="(data?.Position) && data?.Position?.value == 'left' ? 'pb-container-right' : 'pb-container-left'">
                <div class="pb-card-hero-mask pb-p-4 pb-pt-[140px] pb-pb-5 lg:pb-p-0 pb-relative pb-flex pb-flex-col pb-justify-center pb-gap-4 pb-hero-mask-theme--{{ theme }} ">
                    <h1 class="pb-text-d-xl-m xl:pb-text-d-2xl pb-font-serif pb-text-color-brand pb-whitespace-pre-wrap" data-title="true">{{data?.Title?.value}}</h1>
                    <div *ngIf="data?.Subtitle?.value" class="pb-text-lg pb-hidden md:pb-block">{{data?.Subtitle?.value}}</div>
                    <div *ngIf="link?.href && link?.text" class="pb-flex pb-flex-col lg:pb-flex-row pb-gap-5">
                        <a  hybridHref hreflang="{{language}}" href="{{link?.href}}" target="{{link?.target}}" class="pb-btn pb-btn-primary">{{link?.text}} <i class="pb-icon pb-icon-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="pb-w-full pb-c-hero-internal lg:pb-w-auto" [ngClass]="[(data?.Position) && data?.Position?.value == 'left' ? 'pb-hero-mask-left-wrapper': 'pb-hero-mask-right-wrapper']">
                <img *ngIf="image?.src" src="{{ image?.src }}" alt="{{ data?.Image?.value?.alt }}" class=" pb-w-full pb-h-full pb-object-cover pb-object-center" [ngClass]="(data?.Position) && data?.Position?.value == 'left' ? 'pb-hero-mask-left' : 'pb-hero-mask-right'">
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="dataSector == 'private' && !energySaveMode">
        <div class="pb-c-hero pb-c-hero-mask pb-w-full pb-flex pb-flex-col pb-hero-theme--{{ theme }} pb-container" [ngClass]="[(data?.Position) && data?.Position?.value == 'left' ? 'lg:pb-flex-row-reverse' : 'lg:pb-flex-row']"> 
            <div class="pb-w-full lg:pb-w-5/12 pb-block lg:pb-flex lg:pb-pt-[171px]">
                <div class="pb-card-hero-mask pb-p-4 pb-pt-[140px] pb-pb-5 lg:pb-p-0 pb-relative pb-flex pb-flex-col pb-justify-center pb-gap-4 pb-hero-mask-theme--{{ theme }} ">
                    <h1 class="pb-text-d-xl-m lg:pb-text-d-2xl pb-font-serif pb-text-color-brand pb-whitespace-pre-wrap" data-title="true">{{data?.Title?.value}}</h1>
                    <div *ngIf="data?.Subtitle?.value" class="pb-text-lg pb-hidden md:pb-block">{{data?.Subtitle?.value}}</div>
                    <div *ngIf="link?.href && link?.text" class="pb-flex pb-flex-col lg:pb-flex-row pb-gap-5">
                        <a  hybridHref hreflang="{{language}}" href="{{link?.href}}" target="{{link?.target}}" class="pb-btn pb-btn-primary">{{link?.text}} <i class="pb-icon pb-icon-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="pb-w-full pb-c-hero-internal lg:pb-w-7/12">
                <img *ngIf="image?.src" src="{{ image?.src }}" alt="{{ data?.Image?.value?.alt }}" 
                    class=" pb-w-full pb-h-full pb-object-cover pb-object-center" 
                    [ngClass]="(data?.Position) && data?.Position?.value == 'left'? 'lg:pb-h-[700px] lg:pb-w-[700px] pb-m-0 lg:pb-mr-auto' : 'lg:pb-h-[700px] lg:pb-w-[700px]  pb-m-0 lg:pb-ml-auto'">
            </div>
        </div>
    </ng-container>
      
    <ng-container *ngIf="dataSector == 'personal' && !energySaveMode">
        <div class="pb-c-hero pb-c-hero-campaign pb-relative pb-flex pb-flex-col-reverse lg:pb-flex-col">
            <img *ngIf="image?.src" src="{{image?.src}}" alt="{{image?.alt}}" class="pb-w-full pb-h-full pb-max-h-[260px] pb-object-cover pb-object-top sm:pb-max-h-[736px] 4xl:pb-max-h-[940px] pb-aspect-[3/2] lg:pb-aspect-[2/1]">
            <div class="pb-flex pb-h-full pb-w-full lg:pb-absolute lg:pb-top-0 lg:pb-items-end">
                <div class="pb-container-left !pb-my-0 pb-w-full">
                    <div class="pb-relative pb-w-full pb-hero-overflow-personal lg:pb-max-w-[626px]">
                        <div class="pb-card-hero pb-card-hero-campaign pb-relative pb-pt-15 pb-p-4 pb-pb-5 pb-flex pb-flex-col pb-gap-4 pb-w-full lg:pb-p-7 lg:pb-gap-5 ">
                            <h1 class="pb-text-d-xl-m pb-text-color-brand pb-font-serif pb-line-clamp-3 lg:pb-text-d-xl pb-z-10 pb-whitespace-pre-wrap" data-title="true">{{data?.Title?.value}}</h1>
                            <div class="pb-text-lg pb-hidden md:pb-block pb-z-10">{{data?.Subtitle?.value}}</div>
                            <a *ngIf="link?.href && link?.text" hybridHref hreflang="{{language}}" href="{{link?.href}}" target="{{link?.target}}" attr.aria-label="{{link?.text}}" class="pb-btn pb-btn-primary pb-w-full md:pb-w-fit pb-z-10">{{link?.text}} <i class="pb-icon pb-icon-arrow-right pb-text-i-24"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="dataSector == 'holdings' && !energySaveMode">
        <div class="pb-c-hero pb-c-hero-mask pb-w-full pb-flex pb-flex-col pb-hero-theme--{{ theme }}" [ngClass]="[(data?.Position) && data?.Position?.value == 'left' ? 'lg:pb-flex-row-reverse' : 'lg:pb-flex-row']"> 
            <div class="pb-w-full lg:pb-w-5/12 pb-block lg:pb-flex lg:pb-pt-[171px]" [ngClass]="(data?.Position) && data?.Position?.value == 'left' ? 'pb-container-right' : 'pb-container-left'">
                <div class="pb-card-hero-mask pb-p-4 pb-pt-[140px] pb-pb-5 lg:pb-p-0 pb-relative pb-flex pb-flex-col pb-justify-center pb-gap-4 pb-hero-mask-theme--{{ theme }} ">
                    <h1 class="pb-text-d-xl-m lg:pb-text-d-2xl pb-font-serif pb-text-holdings pb-whitespace-pre-wrap" data-title="true">{{data?.Title?.value}}</h1>
                    <div *ngIf="data?.Subtitle?.value" class="pb-text-lg pb-hidden md:pb-block pb-text-holdings">{{data?.Subtitle?.value}}</div>
                    <div *ngIf="link?.href && link?.text" class="pb-flex pb-flex-col lg:pb-flex-row pb-gap-5">
                        <a  hybridHref hreflang="{{language}}" href="{{link?.href}}" target="{{link?.target}}" class="pb-btn pb-btn-primary-brand">{{link?.text}} <i class="pb-icon pb-icon-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="pb-w-full pb-c-hero-internal lg:pb-w-auto" [ngClass]="[(data?.Position) && data?.Position?.value == 'left' ? 'pb-hero-mask-left-wrapper': 'pb-hero-mask-right-wrapper']">
                <img *ngIf="image?.src" src="{{ image?.src }}" alt="{{ data?.Image?.value?.alt }}" class=" pb-w-full pb-h-full pb-object-cover pb-object-center" [ngClass]="(data?.Position) && data?.Position?.value == 'left' ? 'pb-hero-mask-left' : 'pb-hero-mask-right'">
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="energySaveMode">
        <div class="pb-c-hero pb-c-hero-mask pb-w-full pb-flex pb-flex-col pb-hero-theme--{{ theme }}" [ngClass]="[(data?.Position) && data?.Position?.value == 'left' ? 'lg:pb-flex-row-reverse' : 'lg:pb-flex-row']"> 
            <div class="pb-w-full lg:pb-w-5/12 pb-block lg:pb-flex lg:pb-pt-[171px]" [ngClass]="(data?.Position) && data?.Position?.value == 'left' ? 'pb-container-right' : 'pb-container-left'">
                <div class="pb-card-hero-mask pb-p-4 pb-pt-15 lg:pb-pt-0 pb-pb-5 lg:pb-p-0 pb-relative pb-flex pb-flex-col pb-justify-center pb-gap-4 pb-hero-mask-theme--{{ theme }} ">
                    <h1 class="pb-text-d-xl-m lg:pb-text-d-2xl pb-font-serif pb-text-color-brand pb-whitespace-pre-wrap" data-title="true">{{data?.Title?.value}}</h1>
                    <div *ngIf="data?.Subtitle?.value" class="pb-text-lg pb-hidden md:pb-block">{{data?.Subtitle?.value}}</div>
                    <div *ngIf="link?.href && link?.text" class="pb-flex pb-flex-col lg:pb-flex-row pb-gap-5">
                        <a  hybridHref hreflang="{{language}}" href="{{link?.href}}" target="{{link?.target}}" class="pb-btn pb-btn-primary">{{link?.text}} <i class="pb-icon pb-icon-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="pb-w-full pb-c-hero-internal lg:pb-w-7/12 pb-hidden lg:pb-block" [ngClass]="(data?.Position) && data?.Position?.value == 'left' ? 'pb-container-left' : 'pb-container-right'">
                <div class="lg:pb-h-[700px] lg:pb-w-[700px]" [ngClass]="(data?.Position) && data?.Position?.value == 'left' ? 'pb-mr-auto' : 'pb-ml-auto'">
                    <img *ngIf="image?.src" src="{{ image?.src }}" alt="{{ data?.Image?.value?.alt }}" 
                        class=" pb-w-full pb-h-full pb-object-cover pb-object-center" 
                        [ngClass]="(data?.Position) && data?.Position?.value == 'left'? 'lg:pb-h-[700px] lg:pb-w-[700px] pb-m-0 lg:pb-mr-auto' : 'lg:pb-h-[700px] lg:pb-w-[700px]  pb-m-0 lg:pb-ml-auto'">
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>