import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class CustomDeviceDetectorService {

  constructor(public deviceDetectorService: DeviceDetectorService) {
  }


  public isiOSDevice() {
    return this.deviceDetectorService.os === 'iOS';
  }

  public isGoogleDevice() {
    return this.deviceDetectorService.os === 'Android';
  }

  public isHuaweiDevice() {
    let regExp = new RegExp('/Huawei/g');
    return regExp.test(this.deviceDetectorService.device);
  }

  public isDesktopOriOS(){
    return this.isiOSDevice() || this.deviceDetectorService.isDesktop()
  }

  public isDesktopOriAndroid(){
    return this.isGoogleDevice() || this.deviceDetectorService.isDesktop()
  }

  public isDesktopOriHuawei(){
    return this.isHuaweiDevice() || this.deviceDetectorService.isDesktop()
  }

  public isMobile(){
    return this.deviceDetectorService.isMobile()
  }

  public isTablet(){
    return this.deviceDetectorService.isTablet()
  }

}
