import { Component, OnInit, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { ILinkItemDataModel } from '../../models/link-item.datamodel';
import { ImageService } from '../../services/image.service';
import { ThemeService } from '../../services/theme.service';
import { AssetPipe } from "../../pipes/asset.pipe";
import { JssContextService } from '../../jss-context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hero-campaign',
  templateUrl: './hero-campaign.component.html'
})
export class HeroCampaignComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  @Input() data: any;
  public fields:any;
  link: ILinkItemDataModel;
  public energySubscription: Subscription;
  public image:{src:string, alt:string};
  loadedImage: HTMLImageElement;
  energySaveMode:boolean;
  public contextSubscription: Subscription;
  language:string;

  constructor(private imageService: ImageService, public jssContext: JssContextService, private themeService: ThemeService) { }

  ngOnInit() {
    this.contextSubscription = this.jssContext.state.subscribe((newState) => {
      this.language = newState.language
    });
    this.fields = this.rendering.fields as any;
    if(this.rendering.fields?.Link){
      let link = this.rendering.fields?.Link as any;
      this.link={
        href: link?.value?.href,
        target: link?.value?.target,
        text: link?.value?.text
      }
    }

    this.energySubscription = this.themeService.isEnegySaveModeEnabled().subscribe((energySave) => {
      this.energySaveMode = energySave;
      if (this.fields?.Image?.value?.src){
        this.image = {
          src: new AssetPipe().transform('images/default-image.png'),
          alt: ""
        }
        const img = this.imageService.getImage(this.fields);
        this.loadedImage = new Image();
        this.loadedImage.src = img.src;
        this.loadedImage.onload = () => {
            this.image = img;
        };
      }
    });
  }

  ngOnDestroy(): void {
    if (this.energySubscription){
      this.energySubscription.unsubscribe();
    }
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

  }
  
}
