import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class SSRDeviceDetectorService extends DeviceDetectorService {
  constructor(@Inject(PLATFORM_ID) platformId: any, @Inject('JSS_SERVER_VIEWBAG') private serverViewBag: any) {
    super(platformId);
    if (isPlatformServer(platformId) && this.serverViewBag.httpContext) {
      super.setDeviceInfo((this.serverViewBag.httpContext.request.userAgent as string) || '');
    }
  }
}