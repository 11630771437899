import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _energyModeSubject: BehaviorSubject<boolean>;
  energyMode : boolean;

  constructor(private cookieService: CookieService) {
    const savedData = this.cookieService.get("sc_energysaving");
    this._energyModeSubject = new BehaviorSubject<boolean>(savedData === 'true');
    this.energyMode = this._energyModeSubject.value;
  }

  isEnegySaveModeEnabled(): Observable<boolean> {
    return this._energyModeSubject.asObservable();
  }

  getEnergySaveModeValue(): boolean {
    return this._energyModeSubject.value;
  }
  
  toggleDarkMode(newValue: string) {
    if(this.cookieService.check("sc_energysaving")){
      this.cookieService.delete("sc_energysaving");
    }
    this.cookieService.set("sc_energysaving", newValue);
    this._energyModeSubject.next(newValue == "true" ? true : false);
  }
}
