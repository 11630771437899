import { Component, OnInit, Input, PLATFORM_ID, Inject, ViewEncapsulation, HostListener, Renderer2, ViewChild, ElementRef, ChangeDetectionStrategy } from "@angular/core";
import { ComponentRendering } from "@sitecore-jss/sitecore-jss-angular";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { ThemeService } from "../../services/theme.service";
import { CustomDeviceDetectorService } from "../../services/custom-device-detector.service";
import { ActivatedRoute, Router } from "@angular/router";
import { JssContextService } from "../../jss-context.service";
import { Subscription } from "rxjs";
import { ApiService } from "../../services/api.service";
import { PbJssLayoutService } from "../../layout/pb-jss-layout.service";
import { SsrCookieService as CookieService } from "ngx-cookie-service-ssr";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';
import { DynamicScriptLoaderService } from "../../services/dynamic-script-loader.service";

@Component({
    selector: "app-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
    @ViewChild("Header", { static: false }) header!: ElementRef;
    @ViewChild("Alerts", { static: false }) alerts!: ElementRef;
    @Input() rendering: ComponentRendering;
    private contextSubscription: Subscription;
    alert: any;
    isEditing = false;
    language: string;
    ItemID: string;
    currentRoute: string;
    public dataSector: string;

    energySave: boolean;
    _isPlatformServer: boolean = false;
    _isPlatformBrowser: boolean = false;
    route: any;
    menuItems: any[];
    emergencyTitle: string;
    emergencyDescription: string;
    removeActive = true;
    choicesInstance: any;
    mobileMenuActive: boolean = false;
    rightSidelvl1Items: any[] = [];
    centerlvl1Items: any[] = [];
    private lastScrollTop: number = 0;
    private scrollUpDistance: number = 0;
    public isMobile: boolean = false;
    promoItemsInMobile: any[];
    public isMainPage: boolean = false;

    currentLanguage: LanguageModel;
    otherLanguage: LanguageModel;
    en: LanguageModel = {
        Text: "EN",
        TextMobile: "ENGLISH",
        Prefix: "en",
        DisplayPrefix: "en",
    };
    el: LanguageModel = {
        Text: "EL",
        TextMobile: "ΕΛΛΗΝΙΚΑ",
        Prefix: "el",
        DisplayPrefix: "el",
    };
    params: any;

    constructor(
        @Inject(PLATFORM_ID) public readonly platformId: string,
        @Inject(DOCUMENT) private document: Document,
        private deviceDetector: CustomDeviceDetectorService,
        public translate: TranslateService,
        private renderer: Renderer2,
        public router: Router,
        private themeService: ThemeService,
        public jssContext: JssContextService,
        public activatedRoute: ActivatedRoute,
        protected apiService: ApiService,
        private pbJssLayout: PbJssLayoutService,
        private cookieService: CookieService,
        public dynamicScriptLoader: DynamicScriptLoaderService
    ) {
        platformId;
    }

    ngOnInit() {
        let analytics = this.rendering.fields["Analytics"] as any;
        if (analytics?.length == 1) {
            let url1 = analytics[0]?.fields?.FileOne?.value?.src;
            if (url1) {
                this.addScriptToHead(url1);
            }
            let url2 = analytics[0]?.fields?.FileTwo?.value?.src;
            if (url2) {
                this.addScriptToHead(url2);
            }
        }

        // remove this after implementation is done
        this.isMobile = this.deviceDetector.isMobile() || this.deviceDetector.isTablet();
        this._isPlatformServer = isPlatformServer(this.platformId);
        if ((this.rendering.fields?.result as any)?.Alert) {
            this.alert = (this.rendering.fields?.result as any)?.Alert;
        }
        this.contextSubscription = this.jssContext.state.subscribe((newState) => {
            this.isEditing = newState.sitecore && newState.sitecore?.context.pageEditing;
            this.language = newState.language;
            this.currentRoute = newState.serverRoute;
            this.ItemID = newState.sitecore?.route?.itemId;
            this.route = newState.sitecore?.route;
            this.toggleLanguage(null, this.language);
            this._isPlatformServer = isPlatformServer(this.platformId);
            this._isPlatformBrowser = isPlatformBrowser(this.platformId);
        });

        this.isMainPage = "/"+this.language+this.currentRoute == (this.rendering?.fields as any)?.LogoUrl?.value?.href;

        this.menuItems = (this.rendering?.fields?.result as any).MenuJsonData?.level1?.items.find((section: any) => section.active)?.level2?.items;
        this.menuItems = this.menuItems?.length > 0 ? this.menuItems : (this.rendering?.fields?.result as any).MenuJsonData?.level1?.items[0]?.level2?.items;
        var sectionEmergencyTitle = (this.rendering?.fields?.result as any).MenuJsonData?.level1?.items.find((section: any) => section.active)?.emergencyTitle;
        this.emergencyTitle = sectionEmergencyTitle ? sectionEmergencyTitle : (this.rendering?.fields?.EmergencyTitle as any)?.value;
        var sectionEmergencyDescription = (this.rendering?.fields?.result as any).MenuJsonData?.level1?.items.find((section: any) => section.active)?.emergencyDescription;
        this.emergencyDescription = sectionEmergencyDescription ? sectionEmergencyDescription : (this.rendering?.fields?.EmergencyDescription as any)?.value;
        this.energySave = this.cookieService.get("sc_energysaving") == "true" ? true : false;
        this.rightSidelvl1Items = (this.rendering?.fields?.result as any)?.MenuJsonData?.level1?.items.filter((item: any) => item.rightSide === true);
        this.centerlvl1Items = (this.rendering?.fields?.result as any)?.MenuJsonData?.level1?.items.filter((item: any) => item.rightSide === false);
        this.activatedRoute.queryParams.subscribe((params) => {
            this.params = params["sc_params"];
        });
    }
    addScriptToHead(scriptUrl: string): void {
        const existingScript = this.document.querySelector(`script[src="${scriptUrl}"]`);
        if (!existingScript) {
            // If the script does not exist, create and append a new script element
            const script = this.renderer.createElement("script");
            script.type = "text/javascript";
            script.src = scriptUrl as string;
            this.renderer.setAttribute(script, 'async', 'true'); // Add the defer attribute
            this.renderer.appendChild(this.document.body, script);
        }
    }
    ngAfterViewInit(): void {
        this._isPlatformServer = isPlatformServer(this.platformId);
        this._isPlatformBrowser = isPlatformBrowser(this.platformId);
        if (!this._isPlatformServer && this._isPlatformBrowser) {
            this.dataSector = document.querySelector("html").getAttribute("data-sector");
            if ((this.rendering.fields?.result as any)?.Alert) {
                this.header.nativeElement.style.height = this.alerts.nativeElement.offsetHeight + "px";
            }
            document.querySelector<HTMLElement>("body").classList.remove("pb-overflow-y-hidden");
            const selectEls = document.querySelectorAll(".select-showcase");
            if (selectEls.length == 0) return;

            import("choices.js").then((Choices) => {
                const choices = Choices.default;
                selectEls.forEach((el) => {
                    this.choicesInstance = new choices(el, {
                        silent: true,
                        searchEnabled: false,
                        addItems: true,
                        removeItems: false,
                        itemSelectText: "",
                        shouldSortItems: false,
                        shouldSort: false,
                        allowHTML: true,
                        labelId:"lvl1-menu-dropdown"
                    });
                    this.renderer.listen(el, "change", (event) => {
                        var selectedSection = (this.rendering?.fields?.result as any).MenuJsonData?.level1?.items.find((section: any) => section.Href == event?.detail?.value);
                        if (selectedSection?.ID == "00000000-0000-0000-0000-000000000000") {
                            window.open(event?.detail?.value, "_blank");
                        } else {
                            if(event?.detail.value.includes('http')){
                                window.open(event?.detail?.value, "_blank");
                            }else{
                                this.router.navigateByUrl(event?.detail?.value);
                            }
                        }
                    });
                    const dropdownItems = this.choicesInstance.containerOuter.element.querySelectorAll('.choices__item');
                    dropdownItems.forEach((item:any) => {
                        item.setAttribute('role', 'option');
                    });
                    this.choicesInstance.containerOuter.element.classList.add("pb-menu-dropdown");
                    this.choicesInstance.containerOuter.element.setAttribute("aria-label", "lvl1-menu-dropdown");
                    // this.choicesInstance.containerOuter.element.setAttribute("role", "option");
                });
            });
        }
    }
    CloseAlert(){
        this.alert = null;
        this.header.nativeElement.style.height = '';
        
    }

    @HostListener("window:scroll", ["$event"]) // for window scroll events
    onScroll(event: any) {
        if (!this._isPlatformServer) {
            const header = event.target.querySelector(".pb-header");
            // const header = event.target.querySelector(".pb-header-lvl-1");
            // const headerlvl2 = event.target.querySelector(".pb-header-lvl-2-desktop");
            // const headerlvl2mobile = event.target.querySelector(".pb-header-lvl-2-mobile");
            const st = window.scrollY || document.documentElement.scrollTop;
            if (st > this.lastScrollTop) {
                // Scrolled down
                header?.classList.add("lg:-pb-translate-y-[40%]");
                header?.classList.add("-pb-translate-y-8");
                // header?.classList.add("-pb-translate-y-full");
                // headerlvl2?.classList.add("-pb-translate-y-full");
                // headerlvl2mobile?.classList.add("-pb-translate-y-3/4");
                this.scrollUpDistance = 0;
                
            } else if (st < this.lastScrollTop) {
                this.scrollUpDistance += this.lastScrollTop - st;
                if (this.scrollUpDistance > 200 || st == 0) {
                    // Scrolled up                  
                    header?.classList.remove("lg:-pb-translate-y-[40%]");
                    header?.classList.remove("-pb-translate-y-8");
                    // header?.classList.remove("-pb-translate-y-full");
                    // headerlvl2?.classList.remove("-pb-translate-y-full");
                    // headerlvl2mobile?.classList.remove("-pb-translate-y-3/4");
                }
            }
            this.lastScrollTop = st <= 0 ? 0 : st;
        }
    }

    initEnergyMode() {
        if (!this._isPlatformServer) {
            if (this.energySave) {
                document.documentElement.setAttribute("data-energy-save", "true");
                this.jssContext.toggleEnergySaving("true");
                this.themeService.toggleDarkMode("true");
            } else {
                document.documentElement.setAttribute("data-energy-save", "false");
                this.jssContext.toggleEnergySaving("false");
                this.themeService.toggleDarkMode("false");
            }
        }
    }

    enableLevel3(event: any) {
        if (!this._isPlatformServer) {
            //get the correct target this event can triggers from <a> tag which is child;
            var eventTarget = event.target.tagName == "A" ? event.target.parentElement : event.target;
            if (eventTarget?.nextElementSibling?.classList?.contains("active")) return;
            this.removeActive = !eventTarget?.nextElementSibling?.classList?.contains("active");
            const elements = document.querySelectorAll(".pb-header-lvl-3");
            var noTrans = false;
            // Remove 'active' class from each element
            elements.forEach((element) => {
                // element.classList.add('deactive');
                if (element.classList.contains("active")) {
                    noTrans = true;
                    element.classList.add("no-transition");
                    element.classList.remove("active");
                    setTimeout(() => {
                        element.classList.remove("no-transition");
                    }, 100);
                }
                // setTimeout(function () {
                //     element.classList.remove('deactive');
                // }, 500);
            });
            if (noTrans) {
                eventTarget?.nextElementSibling?.classList.add("no-transition");
                eventTarget?.nextElementSibling?.classList.add("active");
                setTimeout(() => {
                    eventTarget?.nextElementSibling?.classList.remove("no-transition");
                }, 100);
            } else {
                eventTarget?.nextElementSibling?.classList.add("active");
            }
        }
    }

    disableLevel3(event: any) {
        if (!this._isPlatformServer) {
            setTimeout(() => {
                if (this.removeActive) {
                    event.target.classList.remove("active");
                }
                this.removeActive = true;
            }, 0.1);
            const elements = document.querySelectorAll(".pb-header-lvl-3");
            elements.forEach((element) => {
                if (element.classList.contains("active")) {
                    element.classList.remove("active");
                }
            });
        }
    }

    freezeDoc(value: boolean) {
        if (!this._isPlatformServer) {
            if (value) {
                document.querySelector<HTMLElement>("body").classList.add("pb-overflow-y-hidden");
                document.querySelector(".pb-header-lvl-1").classList.remove("pb-hidden");
            } else {
                document.querySelector<HTMLElement>("body").classList.remove("pb-overflow-y-hidden");
            }
        }
    }

    onLanguageChange(e: any, selection: LanguageModel) {
        e.preventDefault();
        if (this.isEditing) return;
        if (this.currentLanguage == selection) return;

        let filters: {
            Language: string;
            ItemID: string;
            LastSegment: string;
        };
        filters = {
            Language: selection.Prefix,
            ItemID: this.ItemID,
            LastSegment: window.location.href.substring(window.location.href.lastIndexOf("/") + 1),
        };

        if (filters.LastSegment.includes("sc_params")) {
            filters.LastSegment = "";
        }

        this.apiService.fetch<any[]>("/piraeusapi/getPagePath", filters).then((resp) => {
            if (resp.status === 200) {
                this.toggleLanguage(selection,"");
                let route : string = resp["data"].toString();
                route = route != '/' ? ('/' + selection.DisplayPrefix + route) : (this.rendering.fields as any)?.LogoUrl?.value?.href;
                let currentParams = this.pbJssLayout.decodeCustomParams(this.params);
                if (currentParams) {
                    this.router.navigate([route], { queryParams: this.pbJssLayout.customParamsToQueryStringObj(currentParams) });
                } else {
                    this.router.navigateByUrl(route);
                }
            }
        });
    }

    toggleLanguage(selection: LanguageModel, prefix: string) {
        prefix = prefix ? prefix : selection?.Prefix;
        if (prefix == this.en.Prefix) {
            this.currentLanguage = this.en;
            this.translate.use('en');
            this.otherLanguage = this.el;
        } else {
            this.currentLanguage = this.el;
            this.translate.use('el');
            this.otherLanguage = this.en;
        }
    }

    openMobileAccordion(event: any, menuItem: any) {
        if (!this._isPlatformServer) {
            const accordionItem = event.currentTarget.parentElement as HTMLElement;
            if (accordionItem.classList.contains("pb-c-accordion--expanded")) {
                accordionItem.classList.remove("pb-c-accordion--expanded");
                this.promoItemsInMobile = [];
            } else {
                accordionItem.classList.add("pb-c-accordion--expanded");
                this.promoItemsInMobile = menuItem.promoItems;
            }
        }
    }

    get noneIsActive(): boolean {
        return !(this.rendering?.fields?.result as any)?.MenuJsonData?.level1?.items.some((item: any) => item.isMobile);
    }

    openSearchModal(event: Event): void {
        event.preventDefault();
        if (!this._isPlatformServer) {
            document.querySelector<HTMLElement>("body").classList.add("pb-overflow-y-hidden");
            // this.fadeIn(document.querySelector("#pb-search-modal") as HTMLElement);
            document.querySelector("#pb-search-modal").classList.remove("pb-moveup");
            document.querySelector("#pb-search-modal").classList.add("pb-movedown");
        }
    }

    openEbankingModal(event: Event): void {
        event.preventDefault();
        if (!this._isPlatformServer && (this.rendering?.fields as any)?.EbankingModal.length == 1) {
            document.querySelector<HTMLElement>("body").classList.add("pb-overflow-y-hidden");
            this.fadeIn(document.querySelector("#pb-ebanking-modal-id") as HTMLElement);
            document.querySelector("#pb-ebanking-modal-id").classList.remove("pb-scaleDown");
            document.querySelector("#pb-ebanking-modal-id").classList.add("pb-scaleUp");
        }
    }

    openEnergyModal(event: Event): void {
        event.preventDefault();
        if (!this._isPlatformServer) {
            document.querySelector<HTMLElement>("body").classList.add("pb-overflow-y-hidden");
            this.fadeIn(document.querySelector("#energySaveModal") as HTMLElement);
            document.querySelector("#energySaveModal").classList.remove("pb-scaleDown");
            document.querySelector("#energySaveModal").classList.add("pb-scaleUp");
        }
    }

    closeEnergyModal(): void {
        if (!this._isPlatformServer) {
            if (this.el) {
                this.fadeOut(document.querySelector("#energySaveModal"));
                document.querySelector("#energySaveModal").classList.remove("pb-scaleUp");
                document.querySelector("#energySaveModal").classList.add("pb-scaleDown");
                if (!this.isMobile) {
                    document.querySelector<HTMLElement>("body").classList.remove("pb-overflow-y-hidden");
                }
            }
        }
    }

    fadeIn(element: HTMLElement) {
        if (!this._isPlatformServer) {
            let duration = 500;
            element.style.display = "block";
            element.style.opacity = "0";
            let opacity = 0;

            const interval = 16;
            const increment = interval / duration;

            function tick() {
                opacity += increment;
                element.style.opacity = String(opacity);

                if (opacity >= 1) {
                    clearInterval(timer);
                }
            }

            const timer = setInterval(tick, interval);
        }
    }

    fadeOut(element: HTMLElement) {
        if (!this._isPlatformServer) {
            let opacity = 1;
            let duration = 500;
            const interval = 16;
            const decrement = interval / duration;

            function tick() {
                opacity -= decrement;
                element.style.opacity = String(opacity);

                if (opacity <= 0) {
                    element.style.display = "none";
                    clearInterval(timer);
                }
            }

            const timer = setInterval(tick, interval);
        }
    }

    transferFocus(index : number){
        if(document.getElementById("pb-menu-item-"+index)){
            document.getElementById("pb-menu-item-"+index)?.focus();
        }else{
            document.getElementById("openSearchButton")?.focus();
        }
    }

    ngOnDestroy(): void {
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
    }
    
    trackBySection(item:any): string{
        return item.href;
    }
}


export interface LanguageModel {
    Text: string;
    TextMobile: string;
    Prefix: string;
    DisplayPrefix: string;
}
