import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "../../../src/environments/environment";

@Pipe({
    name: 'asset'
})
export class AssetPipe implements PipeTransform{
    public transform(value:string): any {
        return environment.assetsUrl + value;
    }
}