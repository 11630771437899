import { Component, Input, PLATFORM_ID, Inject, OnDestroy, AfterViewInit, OnInit, Renderer2 } from "@angular/core";
import { ComponentRendering } from "@sitecore-jss/sitecore-jss-angular";
import { DOCUMENT } from "@angular/common";
import { AssetPipe } from "../../pipes/asset.pipe";
import { JssContextService } from '../../jss-context.service';
import { Subscription } from 'rxjs';
import { ThemeService } from "../../services/theme.service";
import { CustomDeviceDetectorService } from "../../services/custom-device-detector.service";

@Component({
    selector: "app-hero-landing-big-image",
    templateUrl: "./hero-landing-big-image.component.html",
})
export class HeroLandingBigImageComponent implements OnDestroy, AfterViewInit, OnInit{
    @Input() rendering: ComponentRendering;
    @Input() data: any;
    @Input() energySave: boolean = false;
    public fields: any;
    public isMobile: boolean = false;
    image: { src: string; alt: string } = { src: "", alt: "" };
    loadedImage: HTMLImageElement;
    _isPlatformServer: boolean = false;
    public dataSector: string;
    public bubbleClass: any = "";
    public contextSubscription: Subscription;
    language:string;
    public imageDesktop: string;
    public imageMobile: string;
    energySaveSubscription: Subscription;

    constructor(
        public jssContext: JssContextService,
        private deviceDetector: CustomDeviceDetectorService,
        @Inject(PLATFORM_ID) public readonly platformId: string,
        @Inject(DOCUMENT) private document: Document,
        private themeService: ThemeService,
        private renderer: Renderer2, ) {
            platformId;
    }

    ngOnInit() {
        this.fields = this.data?.fields as any;
        this.dataSector = this.document.querySelector("html").getAttribute("data-sector"); 
        this.isMobile = this.deviceDetector.isMobile() ;
        this.imageDesktop = new AssetPipe().transform('images/default-image.png');
        this.imageMobile = new AssetPipe().transform('images/default-image.png');
        this.energySaveSubscription = this.themeService.isEnegySaveModeEnabled().subscribe(energySave => {
            this.energySave = energySave;
            this.imageDesktop = energySave ? this.fields?.EnergySavingImage?.value?.src  : this.fields?.Image?.value?.src;
            this.imageMobile = energySave ? this.fields?.EnergySavingImageMobile?.value?.src : this.fields?.ImageMobile?.value?.src ; 

            if ((this.energySave && this.dataSector != 'personal') || this.dataSector === "private") {
                this.bubbleClass = "pb-pt-15 pb-w-full sm:pb-w-3/5 md:pb-w-1/2 lg:pb-max-w-[626px]";
            } else if (this.dataSector === "personal") {
                this.bubbleClass = "pb-card-hero pb-card-hero-campaign pb-pt-15 lg:pb-py-6";
            } else {
                this.bubbleClass = "pb-card-hero pb-pt-15 lg:pb-py-6 pb-w-full lg:pb-w-1/2 lg:pb-max-w-[626px]";
            }
        });

        this.contextSubscription = this.jssContext.state.subscribe((newState) => {
            this.language = newState.language;
        });

        if(this.imageDesktop && !this.document.querySelector(`link[href="${this.imageDesktop}"]`) && !this.isMobile ) {
            const imageLink = this.renderer.createElement('link');
            this.renderer.setAttribute(imageLink, 'rel', 'preload');
            this.renderer.setAttribute(imageLink, 'href', this.imageDesktop);
            this.renderer.setAttribute(imageLink, 'as', 'image');
            this.renderer.appendChild(this.document.head, imageLink);
        }
        if(this.imageMobile && !this.document.querySelector(`link[href="${this.imageMobile}"]`) && this.isMobile ) {
            const imageLinkMobile = this.renderer.createElement('link');
            this.renderer.setAttribute(imageLinkMobile, 'rel', 'preload');
            this.renderer.setAttribute(imageLinkMobile, 'href', this.imageMobile);
            this.renderer.setAttribute(imageLinkMobile, 'as', 'image');
            this.renderer.appendChild(this.document.head, imageLinkMobile);
        }

        
    }

    ngAfterViewInit(){
        if(this.dataSector=="personal" && !this._isPlatformServer){
            const element = this.document.querySelector('.pb-card-hero-campaign') as HTMLElement;
            if(element){
                var roundedHeight = Math.round(element.offsetHeight) + 1; // Round and add 1px
                roundedHeight = roundedHeight % 2 == 0 ? roundedHeight : roundedHeight+1;
                element.style.height = roundedHeight+'px';
            }
        }
    }

    ngOnDestroy(): void {
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
        if (this.energySaveSubscription){
            this.energySaveSubscription.unsubscribe();
        }
        if(this.imageDesktop && this.document.querySelector(`link[href="${this.imageDesktop}"]`)) {
            this.document.querySelector(`link[href="${this.imageDesktop}"]`).remove()
        }
        if(this.imageMobile && this.document.querySelector(`link[href="${this.imageMobile}"]`) ) {
            this.document.querySelector(`link[href="${this.imageMobile}"]`).remove();
        }
      }
}
