import { isPlatformServer } from '@angular/common';
import { Component, OnInit, Input, ElementRef, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { ThemeService } from '../../services/theme.service';
import { ImageService } from '../../services/image.service';
import { JssContextService } from '../../jss-context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ebanking',
  templateUrl: './ebanking.component.html',
  styleUrls: ['./ebanking.component.scss']
})
export class EbankingComponent implements OnInit , OnDestroy{
  @Input() rendering: ComponentRendering;
  _isPlatformServer: boolean = false;
  energySaveSubscription: Subscription;
  image: { src: string; alt: string } = { src: "", alt: "" };
  energySaveMode:boolean;
  public dataSector: string;
  public contextSubscription: Subscription;
  language:string;


  constructor(private el: ElementRef, @Inject(PLATFORM_ID) public readonly platformId: string, public jssContext: JssContextService, private themeService: ThemeService,private imageService: ImageService) { }

  ngOnInit() {
    // remove this after implementation is done
    this._isPlatformServer = isPlatformServer(this.platformId);

    this.contextSubscription = this.jssContext.state.subscribe((newState) => {
      this.language = newState.language
    });

    if (!this._isPlatformServer) {
      const htmlElement = document.querySelector("html");
      this.dataSector = htmlElement.getAttribute("data-sector");
      
      this.energySaveSubscription = this.themeService.isEnegySaveModeEnabled().subscribe(energySave => {
        this.energySaveMode = energySave;
        if ((this.rendering?.fields as any)?.Image?.value?.src) {
          this.image = this.imageService.getImage(this.rendering?.fields);
      }
      });
    }
  }


  closeEbankingModal(): void {
    if (!this._isPlatformServer) {
      if (this.el) {
        this.fadeOut(this.el.nativeElement.querySelector("#pb-ebanking-modal-id"));
        this.el.nativeElement.querySelector("#pb-ebanking-modal-id").classList.remove("pb-scaleUp");
        // this.el.nativeElement.querySelector("#pb-ebanking-modal-id").classList.add("pb-scaleDown");
        document.querySelector<HTMLElement>("body").classList.remove("pb-overflow-y-hidden");
      }
    }
  }

  fadeOut(element: HTMLElement) {
    if (!this._isPlatformServer) {
        let opacity = 1;
        let duration = 500;
        const interval = 16;
        const decrement = interval / duration;

        function tick() {
            opacity -= decrement;
            element.style.opacity = String(opacity);

            if (opacity <= 0) {
                element.style.display = "none";
                clearInterval(timer);
            }
        }

        const timer = setInterval(tick, interval);
    }
  }

  ngOnDestroy(): void {
    if (!this._isPlatformServer) {
        if(this.energySaveSubscription){
            this.energySaveSubscription.unsubscribe();
        }
        if (this.contextSubscription) {
          this.contextSubscription.unsubscribe();
      }
    }
    }
}
