import { Component, OnInit, Input, AfterViewInit, PLATFORM_ID, Inject, OnDestroy, ViewChild, ElementRef, } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { IAccordionItemDataModel } from '../../models/accordion-item.datamodel';
import { ILinkItemDataModel } from '../../models/link-item.datamodel';
import { EffectFade, Thumbs } from 'swiper/modules';
import Swiper from 'swiper';
import { isPlatformServer } from '@angular/common';
import { JssStylingService } from '../../jss-styling.service';
import { JssContextService } from '../../jss-context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})

export class FAQsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('FaqTabs', { static: false }) swiperTabs!: ElementRef;
  @ViewChild('FaqPane', { static: false }) swiperPane!: ElementRef;
  @Input() rendering: ComponentRendering;
  _isPlatformServer: boolean = false;
  cards: IAccordionItemDataModel[] = [];
  categoryCards: {categoryName:string, cards:IAccordionItemDataModel[]}[] = [];
  ready: boolean;
  link: ILinkItemDataModel;
  public simpleAccordion :boolean;
  swiper: Swiper;
  swiper2: Swiper;
  dynamicClass: string;
  public contextSubscription: Subscription;
  language:string;

  constructor( public jssStylingService: JssStylingService, public jssContext: JssContextService, @Inject(PLATFORM_ID) public readonly platformId: string) {}

  ngOnInit() {
    this.contextSubscription = this.jssContext.state.subscribe((newState) => {
      this.language = newState.language
    });
    if (this.rendering){
      this._isPlatformServer = isPlatformServer(this.platformId);
      this.dynamicClass = this.jssStylingService.getComponentClass(this.rendering);
      let fields = this.rendering.fields as any;
      this.simpleAccordion = fields.Cards.some((item:any) => item.fields.Answer !== undefined);
      // remove this after implementation is done
      const categoryItem = fields.Cards.filter((item:any) => item.fields.Cards !== undefined);
      const simpleItem = fields.Cards.filter((item:any) => item.fields.Answer !== undefined);
      
      if(fields.Cards?.length > 0  && simpleItem.length > 0 && this.simpleAccordion){ 
        simpleItem.forEach((item:any) => {
          let card:IAccordionItemDataModel = {
            Answer: item?.fields?.Answer?.value,
            Question: item?.fields?.Question?.value
          } 
          this.cards.push(card);
        });
      }else if(!this.simpleAccordion && categoryItem.length>0){
        categoryItem.forEach((category:any) => {
          let itemCards:IAccordionItemDataModel[] = []
          category.fields?.Cards.forEach((item:any) => {
            let card:IAccordionItemDataModel = {
              Answer: item?.fields?.Answer?.value,
              Question: item?.fields?.Question?.value
            } 
            itemCards.push(card);
          });
          this.categoryCards.push({categoryName:category?.fields?.Name?.value, cards:itemCards})
        });
      }
      this.link={
        href: (this.rendering.fields?.Link as any)['value']?.href,
        target: (this.rendering.fields?.Link as any)['value']?.target,
        text: (this.rendering.fields?.Link as any)['value']?.text
      }
    }
  }

  ngOnDestroy(): void {
    if (this.contextSubscription) {
        this.contextSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() { 
    if (!this._isPlatformServer){
      if(this.swiperTabs){
        this.swiper = new Swiper(this.swiperTabs.nativeElement, {
          slidesPerView: "auto",
          freeMode: true,
          spaceBetween: 24,
          grabCursor: true,
          watchSlidesProgress: true,
          slideToClickedSlide: true
        });
      }
      if(this.swiperPane){
        this.swiper2 = new Swiper(this.swiperPane.nativeElement, {
          slidesPerView: 1,
          centeredSlides: false,
          allowTouchMove:false,
          effect: "fade",
          autoHeight:true,
          fadeEffect: {
            crossFade: true,
          },
          thumbs: {
            swiper: this.swiper,
            slideThumbActiveClass: "pb-tab--active",
          },
          modules: [Thumbs, EffectFade]
        });
      }
    }
  }

  slideTo(index: any) {
    this.swiper.slideTo(index);
  }

}
