import { isPlatformServer } from '@angular/common';
import { Component, OnInit, Input, PLATFORM_ID, Inject, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SearchService } from '../../services/search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    @Input() rendering: ComponentRendering;
    @Input() language: string;
    _isPlatformServer: boolean = false;
    keyphrase: string;
    suggestions : any []= [];
    searchResults : any []= [];
    uuid : string;
    activeSuggestionIndex: number = -1;
    searchIsFocused : boolean;
    showMoreEnabled : boolean = false;
    noResultsFound : boolean = false;


    constructor(private el: ElementRef, @Inject(PLATFORM_ID) public readonly platformId: string, public searchService: SearchService, private router: Router,private cdr : ChangeDetectorRef) { }

    ngOnInit() {
        // remove this after implementation is done
        this._isPlatformServer = isPlatformServer(this.platformId);
    }

    closeModal(): void {
        if (!this._isPlatformServer) {
            if (this.el) {
                // this.fadeOut(this.el.nativeElement.querySelector("#pb-search-modal"));
                this.el.nativeElement.querySelector("#pb-search-modal").classList.remove("pb-movedown");
                this.el.nativeElement.querySelector("#pb-search-modal").classList.add("pb-moveup");
                document.querySelector<HTMLElement>("body").classList.remove("pb-overflow-y-hidden");
            }
        }
    }

    fadeOut(element: HTMLElement) {
        if (!this._isPlatformServer) {
            let opacity = 1;
            let duration = 500;
            const interval = 16;
            const decrement = interval / duration;

            function tick() {
                opacity -= decrement;
                element.style.opacity = String(opacity);

                if (opacity <= 0) {
                    element.style.display = "none";
                    clearInterval(timer);
                }
            }

            const timer = setInterval(tick, interval);
        }
    }

    handleArrowDown(event : KeyboardEvent){

        if (!this._isPlatformServer && this.suggestions.length > 0) {
            if (event.key === 'ArrowDown' || event.key === 'Tab') {
                event.preventDefault();
                // Move selection down
                if(this.activeSuggestionIndex == this.suggestions.length - 1){
                    this.activeSuggestionIndex = 0;
                }else{
                    this.activeSuggestionIndex = Math.min(this.activeSuggestionIndex + 1, this.suggestions.length - 1);
                }
                this.keyphrase = this.suggestions[this.activeSuggestionIndex].text;
            }   
        }   
    }

    handleArrowUp(event : KeyboardEvent){

        if (!this._isPlatformServer && this.suggestions.length > 0) {
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                // Move selection up
                if(this.activeSuggestionIndex == -1){
                    this.activeSuggestionIndex = this.suggestions.length - 1;
                }else{
                    this.activeSuggestionIndex = Math.max(this.activeSuggestionIndex - 1, 0);
                }
                this.keyphrase = this.suggestions[this.activeSuggestionIndex].text;
            }
        }
    }

    onKeyboardChange(event: any) {
        if (event.key === "Enter" || event.keyCode === 13) {
            event.target.blur();
            this.search();
        }
    }

    suggestion(){

        if(this.keyphrase.length<3){
            this.suggestions = [];
            return;
        }

        this.activeSuggestionIndex = -1;

        var searchRequest = {
            "query": {
                "keyphrase": this.keyphrase
            },
            "suggestion": [
                {
                    "name": "title_autocomplete"
                }
            ]                            
        };


        this.searchService.search(searchRequest, this.language).then(resp => {
            if (resp.status === 200) {
                if((resp.data as any)?.widgets[0]?.suggestion?.title_autocomplete?.length > 0){
                    this.suggestions = (resp.data as any)?.widgets[0]?.suggestion?.title_autocomplete?.map((suggestion : any) => suggestion);
                }else{
                    this.typoSuggestion();
                }
            }
        });
    }

    search(limit:number=8,offset:number=0){
        var searchRequest = {
            "content": {},
            "personalization": {
                "fields": [
                    "heading1",
                    "name"
                ],
                "algorithm": "affinity"
            },
            "query": {
                "keyphrase": this.keyphrase
            },
            "limit":limit,
            "offset":offset
        };

        this.searchService.search(searchRequest, this.language).then(resp => {
            if (resp.status === 200) {
              this.noResultsFound = false;
              if((resp.data as any)?.widgets[0]?.content?.length > 0){
                if((resp.data as any)?.widgets[0]?.content?.length < 8){
                  this.showMoreEnabled = false;
                }
                this.suggestions = [];
                if(offset==0){
                  this.searchResults = (resp.data as any)?.widgets[0]?.content?.map((results : any) => results);
                  this.showMoreEnabled = (resp.data as any)?.widgets[0]?.content?.length < 8 ? false : true;
                }else{
                    this.searchResults = this.searchResults.concat((resp.data as any)?.widgets[0]?.content?.map((results : any) => results));
                }
              }else{
                this.searchResults = [];
                this.showMoreEnabled = false;
                this.noResultsFound = true;
              }
            }
          }).finally(() => {
            this.cdr.detectChanges();

          });
    }

    typoSuggestion(){

        // Greek content might not return results in suggestion title_autocomplete due to emphasis on greek letters.
        // Considering that we need a typo suggestion.
        var searchRequest = {
            "query": {
                "keyphrase": this.keyphrase
            },
            "suggestion": [
                {
                    "name": "keyword_typo"
                }
            ]                            
        };


        this.searchService.search(searchRequest, this.language).then(resp => {
            if (resp.status === 200) {
                // console.log(resp.data);
                if((resp.data as any)?.widgets[0]?.suggestion?.keyword_typo?.length > 0){
                    this.suggestions = (resp.data as any)?.widgets[0]?.suggestion?.keyword_typo?.map((suggestion : any) => suggestion);
                }
            }
        });
    }

    publishEvent(targetPageId:string){

        this.searchService.clickEventPublish(this.language, this.router.url, targetPageId, this.keyphrase).then(resp => {
            if (resp.status === 200) {
                // console.log(resp.data);
            }
        });
    }
}
