<div>
    <label
        *ngIf="labelTextTop"
        [htmlFor]="id"
        [ngClass]="isDisabled ? 'pb-form-label-top--disabled' : ''"
    >
        {{ labelTextTop }}
        <!-- <span *ngIf="isRequired">*</span> -->
    </label>
    <div>
        <input
            [id]="id"
            [name]="name"
            [required]="isRequired"
            [disabled]="isDisabled"
            [value]="value"
            type="radio"
            [ngClass]="{ 'pb-error-showcase': error }"
            class="pb-form-radio"
            (change)="onChange($event)"
            
        />
        <label [for]="id" *ngIf="labelTextRight">{{ labelTextRight }}</label>
    </div>
    <p *ngIf="error">{{ helperText }}</p>
</div>
