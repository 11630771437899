<a (click)="data?.Link?.Href ? null : $event.preventDefault()" hybridHref hreflang="{{language}}" href="{{ data?.Link?.Href || '#' }}"
  [attr.aria-label]="data?.Link?.Alt">
  <div class="pb-card pb-card-promo !pb-h-full pb-theme--{{ theme }}">
    <img *ngIf="data?.Image?.Href" offset="100" [defaultImage]="'images/default-image.png' | asset"
      [lazyLoad]="data?.Image?.Href" [alt]="data?.Image?.Alt" class="pb-w-full pb-h-full pb-object-cover">
    <div class="pb-p-4 pb-border pb-card-promo-border pb-relative pb-grow pb-flex pb-flex-col pb-justify-between">
      <div class="pb-grow">

        <h4 *ngIf="data?.TagItems.length > 0" class="pb-pb-4 pb-text-sm-m lg:pb-text-sm pb-font-bold">
          <div *ngIf="filteredTag == 'all'">
            <ng-container *ngFor="let tag of data?.TagItems; let last = last">
              <span *ngIf="tag?.Name">{{tag?.Name}}</span>
              <span *ngIf="!last"> | </span>
            </ng-container>
          </div>
          <div *ngIf="filteredTag != 'all'">
            <span *ngIf="filteredTag[0]?.Name">{{filteredTag[0]?.Name}}</span>
          </div>
        </h4>

        <h3 *ngIf="data?.Title"
          class="pb-mb-4 pb-text-h-xs-m lg:pb-text-h-xs pb-font-bold pb-line-clamp-3 pb-max-h-[3lh]">{{data?.Title}}
        </h3>
        <div *ngIf="data?.Subtitle" class="pb-text-sm-m lg:pb-text-sm pb-line-clamp-2">{{data?.Subtitle}}</div>
      </div>
      <i class="pb-block pb-mt-5 pb-icon pb-icon-arrow-right pb-text-i-32"></i>
    </div>
  </div>
</a>