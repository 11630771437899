<label *ngIf="labelText" [htmlFor]="id">
    {{ labelText }} <!-- <span *ngIf="isRequired">*</span> -->
</label>
<select
    *ngIf="!_isPlatformServer && _isPlatformBrowser"
    [name]="name"
    [id]="id"
    class="pb-form-select select-showcase"
    [required]="isRequired"
    [disabled]="isDisabled"
>
    <option *ngIf="hasPlaceholder" value="" disabled selected hidden>
        {{placeholderLabel}}
    </option>
    <option *ngFor="let option of options" value="{{ option.value }}">
        {{ option.text }}
    </option>
</select>
<p [ngClass]="error ? 'pb-error-description pb-text-red-10' : ''" *ngIf="helperText">{{ helperText }}</p>
