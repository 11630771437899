import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

export interface MetaSettings {
  title?: string;
}

@Injectable()
export class JssMetaService {
  constructor(private titleService: Title, private meta: Meta) {}

  setTitle(title?: string) {
    this.titleService.setTitle(title);
  }

  setTag(name: string, value: string) {
    this.meta.removeTag('name="' + name + '"');
    this.meta.addTag({ name, content: value }, false);
  }

  addTag(tag: MetaDefinition) {
    this.meta.removeTag('name="' + tag.name + '"');
    this.meta.addTag(tag, false);
  }
  
  update(meta: MetaSettings) {
    this.setTitle(meta.title);
  }
}
