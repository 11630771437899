import { Component, OnInit, Input, ViewEncapsulation,  } from '@angular/core';
import { IAccordionItemDataModel } from '../../models/accordion-item.datamodel';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AccordionItemComponent implements OnInit {
  @Input() data: IAccordionItemDataModel;

  @Input() expanded: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  expand(){
    this.expanded = !this.expanded;
  }

}
