<div class="pb-c-hero pb-c-hero-campaign pb-relative pb-flex pb-flex-col-reverse lg:pb-flex-col">
  <img *ngIf="image?.src" offset="100" [defaultImage]="'images/default-image.png' | asset" [lazyLoad]="image?.src" alt="{{image?.alt}}" class="pb-w-full pb-h-full pb-max-h-[260px] pb-object-cover lg:pb-max-h-[1200px] pb-aspect-[3/2] lg:pb-aspect-[2/1]"  [ngStyle]="{ 'object-position': rendering?.fields?.ImagePosition?.value }">
  <div class="pb-flex pb-h-full pb-w-full lg:pb-absolute lg:pb-top-0 lg:pb-items-end">
      <div class="lg:pb-container pb-w-full">
          <div class="pb-relative pb-w-full pb-hero-overflow-personal lg:pb-w-4/5 lg:pb-max-w-[846px]">
              <div class="pb-card-hero pb-card-hero-campaign pb-relative pb-pt-15 pb-p-4 pb-pb-5 pb-flex pb-flex-col pb-gap-4 pb-w-full lg:pb-p-7 lg:pb-gap-5 2xl:pb-min-h-80" [ngClass]="{'lg:!pb-p-0 after:!pb-content-none':energySaveMode}">
                  <h1 *ngIf="rendering.fields?.Heading.value === 'H1'" data-title="true" class="pb-text-d-xl-m pb-text-color-brand pb-font-serif pb-line-clamp-3 lg:pb-text-d-xl pb-z-10 pb-whitespace-pre-wrap">{{rendering.fields?.Title?.value}}</h1>
                  <h2 *ngIf="rendering.fields?.Heading.value === 'H2' || rendering.fields?.Heading.value === ''" data-title="true" class="pb-text-d-xl-m pb-text-color-brand pb-font-serif pb-line-clamp-3 lg:pb-text-d-xl pb-z-10 pb-whitespace-pre-wrap">{{rendering.fields?.Title?.value}}</h2>
                  <div class="pb-text-lg pb-hidden lg:pb-block pb-z-10" *scText="rendering.fields?.Subtitle"></div>
                  <a *ngIf="link?.href != ''" hybridHref hreflang="{{language}}" href="{{link?.href}}" target="{{link?.target}}" attr.aria-label="{{link?.text}}" class="pb-btn pb-btn-primary pb-w-full lg:pb-w-fit pb-z-10">{{link?.text}} <i class="pb-icon pb-icon-arrow-right pb-text-i-24"></i></a>
              </div>
          </div>
      </div>
  </div>
</div>