import { Injectable } from "@angular/core";
import { ComponentRendering } from "@sitecore-jss/sitecore-jss-angular";
import { CustomDeviceDetectorService } from "./services/custom-device-detector.service";

@Injectable()
export class JssStylingService {
  ComponentRendering : ComponentRendering;
  public isMobile: boolean = false;

  constructor(private deviceDetector: CustomDeviceDetectorService){

  }

  getParam(str:string) : string {
    if (!str) return '';
    return str;
  }

  getSpacing(spacing:string, isNegative: boolean) : string {
    if (!spacing) return '';
    if (isNegative){
      return '-'+ spacing;
    }
    return spacing;
  }

  getInjected(injected: string) : string {
    return injected == "1" ? "injected" : "";
  }

  appendToClass(cssClass: string, extraClasses: string[]) : string {
    if (!cssClass) {
      cssClass = '';
    }
    if (extraClasses.length > 0) {
      return cssClass + " " + extraClasses.join(' ');
    }
    return cssClass;
  }

  isBlank(str: any) {
    return (!str || /^\s*$/.test(str));
  }

  getComponentClass(rendering: ComponentRendering) {
    this.isMobile = this.deviceDetector.isMobile();
    let injected =  this.getInjected(rendering.params?.Injected);
    let spacingTop = this.isMobile ? this.getSpacing(rendering.params?.TopMobile, rendering.params?.TopNegative == "1") : this.getSpacing(rendering.params?.TopDesktop, rendering.params?.TopNegative == "1");
    let spacingBottom = this.isMobile ? this.getSpacing(rendering.params?.BottomMobile, rendering.params?.BottomNegative == "1") : this.getSpacing(rendering.params?.BottomDesktop, rendering.params?.BottomNegative == "1");
    let resultClass = this.appendToClass(rendering.params?.cssClass, [ spacingTop, spacingBottom, injected ]);
    return resultClass;
  }

}
