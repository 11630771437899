import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: '[app-form-checkbox]',
  templateUrl: './form-checkbox.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormCheckboxComponent),
    multi: true
  }]
})
export class FormCheckboxComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() id: string;
  @Input() labelTextTop: string ="";
  @Input() labelTextBottom: string;
  @Input() value: string;
  @Input() isChecked: string;
  @Input() errorMessage: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() error: boolean = false;
  @Output() valueChange = new EventEmitter<any>();
  valueCheck: string = "";

  constructor() {}

  // ControlValueAccessor methods
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.valueCheck = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onValueChange(e: string) {
    let obj = {
      state : e,
      value :this.value
    }
    this.isChecked = e;
    this.valueCheck = e;
    this.onChange(this.valueCheck);
    this.valueChange.emit(obj);
  }
}
