import { Component, OnInit, Input,ViewChild, Inject, PLATFORM_ID, ElementRef, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import Swiper from "swiper";
import { Pagination, Autoplay } from 'swiper/modules';
import { DOCUMENT, isPlatformServer } from "@angular/common";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { CustomDeviceDetectorService } from '../../services/custom-device-detector.service';

@Component({
  selector: 'app-hero-landing-holdings',
  templateUrl: './hero-landing-holdings.component.html',
  styleUrls: ['./hero-landing-holdings.component.scss']
})
export class HeroLandingHoldingsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  swiper: Swiper;
  _isPlatformServer: boolean = false;
  energySaveMode : boolean;
  energySaveSubscription: Subscription;
  @ViewChild('HeroLandingHoldingsSwiper', { static: false }) swiperSlider!: ElementRef;
  public isMobile: boolean = false;

  constructor(
      @Inject(PLATFORM_ID) public readonly platformId: string,
      private themeService: ThemeService,
      private deviceDetector: CustomDeviceDetectorService,
      private renderer: Renderer2, 
      @Inject(DOCUMENT) private document: Document,
  ) {
      platformId;
  }
  
  ngOnInit() {
      this._isPlatformServer = isPlatformServer(this.platformId);
      
      // if (!this._isPlatformServer) {
          this.energySaveSubscription = this.themeService.isEnegySaveModeEnabled().subscribe(energySave => {
              this.energySaveMode = energySave;
          });
          this.isMobile = this.deviceDetector.isMobile();
          if(!this.deviceDetector.isMobile() || !this.deviceDetector.isTablet()){
            (this.rendering.fields as any)?.Slides.forEach((slide:any) => {
              if(!this.document.querySelector(`link[href="${slide?.fields?.Image?.value?.src}"]`)){
                const imageLink = this.renderer.createElement('link');
                this.renderer.setAttribute(imageLink, 'rel', 'preload');
                this.renderer.setAttribute(imageLink, 'href', slide?.fields?.Image?.value?.src);
                this.renderer.setAttribute(imageLink, 'as', 'image');
                this.renderer.appendChild(this.document.head, imageLink);
              }
            });
          }else{
            (this.rendering.fields as any)?.Slides.forEach((slide:any) => {
              if(!this.document.querySelector(`link[href="${slide?.fields?.ImageMobile?.value?.src}"]`)){
                const imageLink = this.renderer.createElement('link');
                this.renderer.setAttribute(imageLink, 'rel', 'preload');
                this.renderer.setAttribute(imageLink, 'href', slide?.fields?.ImageMobile?.value?.src);
                this.renderer.setAttribute(imageLink, 'as', 'image');
                this.renderer.appendChild(this.document.head, imageLink);
              }
            });
          }
      // }
  }

  ngAfterViewInit(): void {
    this.swiper = new Swiper(this.swiperSlider?.nativeElement, {
      slidesPerView: 1,
      freeMode: true,
      centeredSlides: false,
      spaceBetween: 0,
      grabCursor: true,
      loop:true,
      autoplay: parseFloat((this.rendering.fields?.TimeRotation as any)?.value) && parseFloat((this.rendering.fields?.TimeRotation as any)?.value) > 0 ? 
      {
        delay: parseFloat((this.rendering.fields?.TimeRotation as any)?.value)*1000,
      } : false ,
      speed:1200,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      modules:[Pagination, Autoplay]
    });
  }

  ngOnDestroy(): void {
    if(!this.deviceDetector.isMobile() || !this.deviceDetector.isTablet()){
      (this.rendering.fields as any)?.Slides.forEach((slide:any) => {
        if(this.document.querySelector(`link[href="${slide?.fields?.Image?.value?.src}"]`)){
          this.document.querySelector(`link[href="${slide?.fields?.Image?.value?.src}"]`).remove();
        }
      });
    }else{
      (this.rendering.fields as any)?.Slides.forEach((slide:any) => {
        if(this.document.querySelector(`link[href="${slide?.fields?.ImageMobile?.value?.src}"]`)){
          this.document.querySelector(`link[href="${slide?.fields?.ImageMobile?.value?.src}"]`).remove();
        }
      });
    }
  }
}
