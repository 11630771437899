<ng-container *ngIf="(rendering.fields?.ArticleSections?.length == 0 && rendering.fields?.SideNavigation?.value !=true) ; else withSideNavigation">
  <section [ngClass]="dynamicClass" class="pb-container pb-flex pb-flex-col pb-justify-center pb-items-center pb-space-y-13">
    <div class="pb-flex pb-flex-col lg:pb-w-2/3">
      <div class="pb-text-xs-m lg:pb-text-xs pb-font-bold ">{{articleDate| date:'d MMMM yyyy' }} {{timeToRead}}</div>
      <article class="pb-article pb-article-piraeus">
        <div [innerHTML]="rendering.fields?.Content?.value | TrustHtml"></div>
      </article>
    </div>
  </section>
</ng-container>

<ng-template #withSideNavigation>
 
  <section  [ngClass]="dynamicClass" class="pb-container pb-flex pb-justify-start pb-flex-col pb-gap-8 lg:pb-gap-6 lg:pb-flex-row">
    <div class="pb-w-full lg:pb-w-1/4 lg:pb-py-[36px] pb-space-y-6 pb-flex pb-flex-col lg:pb-sticky lg:pb-top-15 lg:pb-h-max">
      <ng-container *ngFor="let section of rendering.fields?.ArticleSections">
        <a class="pb-cursor-pointer pb-text-lg pb-font-sans pb-font-bold" pageScroll [pageScrollOffset]="120" href="#{{section.id}}" attr.aria-label="{{section.fields?.Title?.value}}">{{section.fields?.Title?.value}}</a>
      </ng-container>
    </div>
    <div class="pb-flex pb-flex-col">
      <div class="pb-text-xs-m lg:pb-text-xs pb-font-bold ">{{articleDate| date:'d MMMM yyyy' }}  {{timeToRead}}</div>
      <article class="pb-article pb-article-piraeus pb-article-content-nav">
      
        <!-- {{ article.content | safe }} -->
        <div *ngFor="let section of rendering.fields?.ArticleSections" id="{{section.id}}" [innerHTML]="section.fields?.Content?.value  | TrustHtml"></div>
      </article>
    </div>
  </section>
</ng-template>