/* eslint-disable no-shadow, no-console */
import { Component, OnInit, OnDestroy, PLATFORM_ID, Renderer2, Inject, AfterViewInit  } from '@angular/core';
import { Field, LayoutServiceContextData } from '@sitecore-jss/sitecore-jss-angular';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { JssState } from '../../JssState';
import { PageMetaComponentBase } from '../../page-meta-component-base';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { SearchService } from '../../services/search.service';
import { environment } from '../../../environments/environment';
import { AnimateService } from '../../services/animate.service';
import { DynamicScriptLoaderService } from '../../services/dynamic-script-loader.service';
import { EventBasedMarketingService } from '../../services/event-based-marketing.service';
enum LayoutState {
  Layout,
  NotFound,
  Error
}

interface RouteFields {
  [name: string]: unknown;
  pageTitle: Field<string>;
}


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent extends PageMetaComponentBase implements OnInit, AfterViewInit, OnDestroy {
  state: LayoutState;
  LayoutState = LayoutState;
  subscription: Subscription;
  errorContextData: LayoutServiceContextData;
  _isPlatformServer: boolean = false;
  _isPlatformBrowser: boolean = false;
  mainLoaded:boolean;

  constructor(
    @Inject(PLATFORM_ID) public readonly platformId: string,
    @Inject(DOCUMENT) public document: Document,
    private activatedRoute: ActivatedRoute,
    private ssrCookieService: SsrCookieService,
    public searchService: SearchService,
    private animateService: AnimateService,
    public renderer: Renderer2,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    public eventBasedMarketingService: EventBasedMarketingService

  ) {
    super(renderer, platformId, document);
  }

  ngOnInit() {
    this._isPlatformServer = isPlatformServer(this.platformId);
    this._isPlatformBrowser = isPlatformBrowser(this.platformId);

    // route data is populated by the JssRouteResolver
    this.subscription = this.activatedRoute.data.subscribe((data: { jssState: JssState<RouteFields> }) => {
      if (!data.jssState) {
        this.state = LayoutState.NotFound;
        return;
      }

      if (data.jssState.sitecore && data.jssState.sitecore.route) {
        this.route = data.jssState.sitecore.route;
        this.setPageTheme(this.route.fields);
        this.mainLoaded = false;
        if(environment.searchViewEventsEnabled=="true") {
          if(!this.document.querySelector(`link[href="${environment.searchPublishEventApiHost}"]`)){
            const link = this.renderer.createElement('link');
            link.href = environment.searchPublishEventApiHost;
            this.renderer.setAttribute(link, 'rel', 'preconnect');
            this.renderer.appendChild(this.document.head, link);
          }
          setTimeout(() => {
            this.searchService.viewEventPublish(data.jssState.language, "/"+data.jssState.language+data.jssState.serverRoute, data.jssState.sitecore.route.itemId);
          }, 0);
        }
        if(!this._isPlatformServer && this._isPlatformBrowser) {
          if(environment.cookieDomain){
            let srccookie = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
            if (!this.document.querySelector(`script[src="${srccookie}"]`)) {
              this.dynamicScriptLoader.loadScript(srccookie, "data-domain-script", environment.cookieDomain, "data-document-language", "true", true);
            }
            let srccookieAutoBlock = "https://cdn.cookielaw.org/consent/"+environment.cookieDomain+"/OtAutoBlock.js";
            if (!this.document.querySelector(`script[src="${srccookieAutoBlock}"]`)) {
              this.dynamicScriptLoader.loadScript(srccookieAutoBlock, undefined, undefined,undefined, undefined, true);
            }
            let inlineScript = "function OptanonWrapper() { }";
            if (!this.scriptSrcOrContentExists(null, inlineScript)) {
              this.dynamicScriptLoader.loadScriptInline(inlineScript,"head");
            }
          }
 
          //userway
          let srcUserWay = "https://cdn.userway.org/widget.js";
          if (!this.document.querySelector(`script[src="${srcUserWay}"]`)) {
            this.dynamicScriptLoader.loadScript(srcUserWay, "data-account", "QJ9nmRWNFR", undefined, undefined, true);
          }
        }
        if (data.jssState.sitecore.route.name != "*") {
          super.setState(data.jssState);
          super.ngOnInit();
        }else{
          super.setLanguage(data.jssState.language);
        }
        this.eventBasedMarketingService.addRouteHeaderScript(this.route);  

        if(data.jssState.language == "en")
        {
          this.document.documentElement.setAttribute("lang", "en");
        }
        else 
        {
          this.document.documentElement.setAttribute("lang", "el");
        }
        
        this.setPageTitleMetadata(this.route.fields);
        this.state = LayoutState.Layout;
        
        let energymode = "false";        
        if(this.ssrCookieService.check("sc_energysaving")){
          energymode = this.ssrCookieService.get("sc_energysaving");
        }
       
        if(energymode && energymode =="true") {
          this.setEnergyTheme(energymode);          
        }

      }

      if (data.jssState.routeFetchError) {
        if (data.jssState.routeFetchError.status >= 400 && data.jssState.routeFetchError.status < 500) {
          this.state = LayoutState.NotFound;
        } else {
          this.state = LayoutState.Error;
        }

        this.errorContextData = data.jssState.routeFetchError.data && data.jssState.routeFetchError.data.sitecore;
      }
    });

    
  }
  
  ngAfterViewInit(): void {
    this.animateService.init();

  }

  ngOnDestroy() {
    // important to unsubscribe when the component is destroyed
    this.subscription.unsubscribe();
  }

  setPageTitleMetadata(routeFields: RouteFields) {
    // set page title, if it exists
    if (routeFields && routeFields.pageTitle) {
      this.meta.setTitle(routeFields.pageTitle.value || 'Page');
    }
  }

  scriptSrcOrContentExists(scriptSrc : any, scriptContent: any) {
    if(!this._isPlatformServer && this._isPlatformBrowser) {
      // Check for external scripts by src attribute
      if (scriptSrc && this.document.querySelector(`script[src="${scriptSrc}"]`)) {
          return true;
      }
      // Check for inline scripts by content
      if (scriptContent) {
          const scripts  = this.document.querySelectorAll('script') as any;
          if (scripts) {
          if (scripts.length > 1) {
            for (let script of scripts) {
              if (script.textContent.includes(scriptContent)) {
                  return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  onPlaceholderLoaded(placeholderName: string) {
    // you may optionally hook to the loaded event for a placeholder,
    // which can be useful for analytics and other DOM-based things that need to know when a placeholder's content is available.
    // console.debug(`layout.component.ts: placeholder component fired loaded event for the ${placeholderName} placeholder`);
    if(placeholderName == "jss-pb-main"){this.mainLoaded = true;}
  }

  setPageTheme(routeFields: RouteFields){
    
    if((routeFields.Theme as any).value){
      this.document.documentElement.setAttribute("data-sector", (routeFields.Theme as any).value.toLowerCase());
    }else{
      this.document.documentElement.setAttribute("data-sector", "default");
    }
  }

  setEnergyTheme(energy: string){    
      console.log("energy:",energy);

      console.log("NODE SERVER:", this.document);

      this.document.documentElement.setAttribute("data-energy-save", energy);    
  }

}
