import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[app-form-search]',
  templateUrl: './form-search.component.html',
})
export class FormSearchComponent implements OnInit {
  @Input() name: string;
  @Input() id: string;
  @Input() labelText: string;
  @Input() placeholder: string;
  @Input() isDisabled: boolean = false;
  @Input() isLarge: boolean = false;
  @Output() valueChange = new EventEmitter<string>();
  value: string = "";

  constructor() {}

  onValueChange(value: string) {
      this.valueChange.emit(value);
  }

  ngOnInit() {
   
   }
}
