import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: "[hybridHref]",
})

export class HybridHref {

  constructor(public router: Router) { }

  @HostListener('click', ['$event']) onClick($event:any) {
    if ($event.currentTarget 
        && !(new RegExp("#$").test($event.currentTarget?.href))
        && !(new RegExp("^mailto:").test($event.currentTarget?.href)) 
        && !$event.currentTarget.classList.contains("no-link") 
        && $event.currentTarget?.target != '_blank') {
      $event.preventDefault();

      if($event.currentTarget.hostname != window.location.hostname){
        window.open($event.currentTarget.href, "_blank");
      }
      else{
        this.router.navigateByUrl($event.currentTarget.pathname);
      }
    }
  }
}
